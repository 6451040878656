import React from 'react'
import PropTypes from 'prop-types'
import { List, AutoSizer } from 'react-virtualized'
import * as JsSearch from 'js-search'
import ReactDOM from 'react-dom'
import _ from 'lodash'
// UTILS
import I18n from 'i18n/i18n'
import toastr from 'utils/toast';
// API
// ACTIONS
// COMPONENTS
import HeaderTabs from './HeaderTabs'
import AddNewButton from './AddNewButton'
import AlphabetBar from './AlphabetBar'
import SearchBar from './SearchBar'
import FormLocationPopup from '../my-location/FormLocationPopup'
// CONSTANTS
import { BUSINESS_PROGRAM, NORMAL_CONTACT } from 'constants/common/contactConstants'
import { LONG_HAUL_GRAY_ICON, ICON_EDIT_LOCATIONS } from 'constants/imageConstants'
// ASSETS
const CONTACT_FIELDS = ['id', 'location_name', 'name', 'phone', 'address']

class MyLocations extends React.Component {
  static genAlphabetList() {
    const locale = I18n.language
    let alphabetList = Array.from(Array(26), (e, i) => String.fromCharCode(i + 65))
    if (locale === 'th') {
      alphabetList = Array.from(Array(46), (e, i) => String.fromCharCode(i + 3585))
    }
    return alphabetList
  }

  constructor(props) {
    super(props)
    this.listRef = undefined
    this.searchContact = {}
    this.alphabetList = MyLocations.genAlphabetList()
    this.state = {
      showContacts: false,
      currentTab: BUSINESS_PROGRAM,
      filteredContacts: [],
      allContacts: { business: [], personal: [] },
      lastPosition: { business: -1, personal: -1 },
      selectedChar: { business: 'A', personal: 'A' },
      openSearch: false,
      isSetSuccess: false,
    }
    this.toggleSearch = this.toggleSearch.bind(this)
    this.toggleShowContacts = this.toggleShowContacts.bind(this)
    this.renderContact = this.renderContact.bind(this)
    this.selectContact = this.selectContact.bind(this)
    this.showMessageNoContact = this.showMessageNoContact.bind(this)
    this.handleInputSearch = this.handleInputSearch.bind(this)
    this.search = _.debounce(() => { this.handleInputSearch() }, 500)
    this.handleOnScroll = this.handleOnScroll.bind(this)
    this.switchTabs = this.switchTabs.bind(this)
    this.getRowHeight = this.getRowHeight.bind(this)
    this.setContactForLocation = this.setContactForLocation.bind(this)
    this.firstChar = this.firstChar.bind(this)
    this.scrollToChar = this.scrollToChar.bind(this)
    this.updateContactsData = this.updateContactsData.bind(this)
    this.handleContactsChange = this.handleContactsChange.bind(this)
    this.handleOnRowsRendered = this.handleOnRowsRendered.bind(this)
  }

  getChildContext() {
    return { handleContactsChange: this.handleContactsChange }
  }

  componentDidMount() {
    const { onRef } = this.props
    onRef(this)
  }

  componentWillReceiveProps(nextProps) {
    const {
      focusInputID, isAddMeClicked, setMyLocationId, myLocationId,
    } = this.props
    if ((focusInputID !== nextProps.focusInputID)
      || (isAddMeClicked !== nextProps.isAddMeClicked && nextProps.isAddMeClicked)) {
      this.setState({
        showContacts: false,
      })
      this.setIsSetSuccess(false)
      if (nextProps.focusInputID !== myLocationId) {
        setMyLocationId('')
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      locationContactID, myLocationId, focusInputID,
    } = this.props
    const { showContacts, isSetSuccess } = this.state
    if (_.toInteger(locationContactID) !== _.toInteger(prevProps.locationContactID) && this.listRef) {
      this.listRef.forceUpdateGrid()
    }
    if (myLocationId && focusInputID && myLocationId === focusInputID && !showContacts && !isSetSuccess) {
      this.setShowContacts(true)
    }
  }

  componentWillUnmount() {
    const { onRef } = this.props
    onRef(undefined)
  }

  /**
   * Set value for showContacts state
   * @param {Boolean} showContacts
   */
  setShowContacts(showContacts) {
    this.setState({ showContacts })
  }

  /**
   * Set value for isSetSuccess state
   * @param {Boolean} isSetSuccess
   */
  setIsSetSuccess(isSetSuccess) {
    this.setState({ isSetSuccess })
  }

  getHeightList() {
    const {
      currentCustomer
    } = this.props
    const {
      filteredContacts, currentTab, allContacts, openSearch
    } = this.state
    const isBusiness = _.parseInt(currentCustomer.current_company_id) > 0
    const shouldShowActionBar = _.size(allContacts[currentTab]) > 0 || openSearch
    let height = 180

    if (
      (_.size(filteredContacts) && !isBusiness)
      || (_.size(filteredContacts) === 0 && isBusiness && !shouldShowActionBar)
      || (_.size(filteredContacts) === 0 && !isBusiness && shouldShowActionBar)
    ) {
      height = 225
    } else if (_.size(filteredContacts) === 0 && !isBusiness && !shouldShowActionBar) {
      height = 265
    }
    return height
  }

  getContacts(tabName) {
    const { currentCustomer, contactActions, stepActions } = this.props
    const companyID = tabName === BUSINESS_PROGRAM ? currentCustomer.current_company_id : 0

    stepActions.loading()
    contactActions.search('', companyID, NORMAL_CONTACT, CONTACT_FIELDS, (res) => {
      if (_.isEmpty(res.data.data)) {
        this.setState({
          filteredContacts: [],
          showContacts: true,
        })
        stepActions.loaded()
      } else {
        this.updateContactsData(res.data.data, tabName, stepActions.loaded)
      }
    })
  }

  setSelectedChar(contacts) {
    return _.isEmpty(contacts) ? undefined : this.firstChar(contacts[0])
  }

  getRowHeight({ index }) {
    return this.gridData[index].size
  }

  setContactForLocation(contactID) {
    const {
      handleSelectContact, stepActions, contactActions, optionalData, isAlwaysRenderContact,
    } = this.props
    stepActions.loading()
    Promise.resolve(contactActions.getDetails(contactID, undefined, (res) => {
      if (res.status === 200) {
        const newData = _.assign({}, optionalData, { contact: res.data.object })
        handleSelectContact(newData)
        this.setState({ showContacts: isAlwaysRenderContact })
        stepActions.loaded()
      } else {
        this.handleInvalidContact(contactID)
      }
    })).then(() => {
      this.setIsSetSuccess(true)
    })
  }

  getIndexByFirstChar(obj) {
    const firstChar = this.firstChar(obj)
    if (firstChar === '#') { return _.size(this.alphabetList) + 1 }
    return this.alphabetList.indexOf(firstChar)
  }

  handleInvalidContact(contactID) {
    const { stepActions, contacts, personalContacts } = this.props
    const { currentTab } = this.state
    const currentContacts = currentTab === BUSINESS_PROGRAM ? [...contacts] : [...personalContacts]

    _.remove(currentContacts, { id: contactID })
    this.updateContactsData(currentContacts, currentTab, stepActions.loaded)
    toastr.error(I18n.t('webapp.label.invalid_contact'))
  }

  updateContactsData(contacts, tabName, callback) {
    const { callbackSwitchTabs, contactActions, stepActions } = this.props
    const { allContacts, lastPosition } = this.state
    const action = tabName === BUSINESS_PROGRAM ? contactActions.updateContacts : contactActions.updatePersonalContacts

    if (_.size(contacts)) {
      contacts.sort((a, b) => {
        if (this.getIndexByFirstChar(a) === this.getIndexByFirstChar(b)) {
          return a.location_name.localeCompare(b.location_name)
        }
        return this.getIndexByFirstChar(a) - this.getIndexByFirstChar(b)
      })
      allContacts[tabName] = contacts
      action(contacts)
      this.buildIndex(tabName, contacts)
      const filteredContacts = this.doSearchContact(allContacts, tabName, (this.inputSearchRef || {}).value)

      this.setState({
        filteredContacts, allContacts, showContacts: true,
      }, () => {
        const position = lastPosition[tabName]
        this.listRef.scrollToPosition(position)
        this.listRef.recomputeGridSize()
        callback()
        if (!_.isUndefined(callbackSwitchTabs)) {
          callbackSwitchTabs()
        }
      })
    } else {
      stepActions.loaded()
    }
  }

  handleContactsChange(tabName) {
    const { contacts, personalContacts } = this.props
    const currentContacts = tabName === BUSINESS_PROGRAM ? [...contacts] : [...personalContacts]
    this.updateContactsData(currentContacts, tabName, () => { })
  }

  firstChar(contact) {
    const firstChar = contact.location_name.charAt(0).toUpperCase()
    return this.alphabetList.includes(firstChar) ? firstChar : '#'
  }

  buildIndex(tabName, docs) {
    const tokenizer = {
      tokenize(text) {
        return text
          .split(/\s+/) // Split on spaces
          .filter(str => str)
      }
    }
    const search = new JsSearch.Search('id')
    search.searchIndex = new JsSearch.UnorderedSearchIndex()
    search.indexStrategy = new JsSearch.AllSubstringsIndexStrategy()
    search.tokenizer = tokenizer
    search.addIndex('name')
    search.addIndex('location_name')
    search.addIndex('address')
    search.addDocuments(docs)

    this.searchContact[tabName] = search
  }

  scrollToChar(e) {
    const { selectedChar, currentTab } = this.state
    const option = e.options[e.selectedIndex]
    const index = _.toInteger(option.getAttribute('data-index'))
    if (index < 0) {
      return
    }

    const initialTop = this.listRef.getOffsetForRow({
      alignment: 'start',
      index,
    })
    selectedChar[currentTab] = option.value
    this.setState({ selectedChar }, () => {
      this.listRef.scrollToPosition(initialTop + 1)
    })
  }

  handleOnRowsRendered({ startIndex }) {
    const {
      filteredContacts, selectedChar, currentTab
    } = this.state

    if (startIndex >= _.size(filteredContacts)) {
      return
    }

    const firstCharOfContact = this.firstChar(filteredContacts[startIndex])
    if (selectedChar[currentTab] !== firstCharOfContact) {
      selectedChar[currentTab] = firstCharOfContact
      this.setState({ selectedChar })
    }
  }

  handleOnScroll({ scrollTop }) {
    const { lastPosition, currentTab } = this.state
    lastPosition[currentTab] = scrollTop
    this.setState({ lastPosition })
  }

  showMessageNoContact() {
    const {
      emptyMessage, isContactView, currentCustomer, contactActions, extraInfos, customClassAutoComplete,
    } = this.props
    const { currentTab } = this.state

    return (
      <div
        key="0"
        className={`Popover-contact-list-empty min-h-${this.getHeightList()} Box-Sizing Border-Box flex flex-center my-locations-empty`}
      >
        <div>
          <div>
            <img src={LONG_HAUL_GRAY_ICON} alt="empty-icon" className="dlvr-lh-gray-icon" />
            <p className="small-font pt10 pb15 default-color-light-gray m-auto max-w-80">
              {emptyMessage}
            </p>
          </div>
          {!isContactView && (
            <div className="flex flex-center">
              <AddNewButton
                openSearch={false}
                currentCustomer={currentCustomer}
                extraInfos={extraInfos}
                contactActions={contactActions}
                currentTab={currentTab}
                customClassAutoComplete={customClassAutoComplete}
              />
            </div>
          )}
        </div>
      </div>
    )
  }

  handleInputSearch() {
    const { allContacts, currentTab } = this.state
    const result = this.doSearchContact(allContacts, currentTab, (this.inputSearchRef || {}).value)
    this.setState({ filteredContacts: result }, () => {
      this.listRef.recomputeGridSize()
    })
  }

  doSearchContact(allContacts, currentTab, searchText) {
    return _.size(searchText) > 0 ? this.searchContact[currentTab].search(searchText) : allContacts[currentTab]
  }

  switchTabs(tabName) {
    const { contacts, personalContacts } = this.props
    const { allContacts, lastPosition } = this.state
    const currentContacts = tabName === BUSINESS_PROGRAM ? contacts : personalContacts
    if (this.listRef) {
      const position = lastPosition[tabName]
      this.listRef.scrollToPosition(position)
    }
    if (!_.isEmpty(currentContacts)) {
      allContacts[tabName] = currentContacts
      if (!this.searchContact[tabName]) {
        this.buildIndex(tabName, currentContacts)
      }
      const filteredContacts = this.doSearchContact(allContacts, tabName, (this.inputSearchRef || {}).value)
      this.setState({
        currentTab: tabName, filteredContacts, allContacts, showContacts: true,
      }, () => {
        if (this.listRef) { this.listRef.recomputeGridSize() }
      })
      return
    }

    this.getContacts(tabName)
    this.setState({ currentTab: tabName })
  }

  toggleShowContacts() {
    const { showContacts } = this.state
    const {
      isAlwaysRenderContact, onShowContacts, setIsAddMeClicked, setMyLocationId, onkey,
    } = this.props
    if (onShowContacts && !showContacts) {
      onShowContacts()
    }
    if (showContacts) {
      this.setState({ showContacts: isAlwaysRenderContact, openSearch: false })
      setMyLocationId('')
      return
    }
    setMyLocationId(onkey)
    setIsAddMeClicked(false)
    this.switchTabs(BUSINESS_PROGRAM)
  }

  hideContacts() {
    const { showContacts } = this.state
    const { isAlwaysRenderContact } = this.props
    if (showContacts) {
      this.setState({ showContacts: isAlwaysRenderContact, openSearch: false })
    }
  }

  selectContact(id) {
    const { selectContactID } = this.state
    const { isContactView } = this.props

    if (isContactView) {
      this.setContactForLocation(id)
      return
    }

    if (_.toInteger(selectContactID) === _.toInteger(id)) {
      return
    }

    this.setState({ selectContactID: id }, () => {
      if (this.listRef) {
        this.listRef.recomputeGridSize()
      }
    })
  }

  toggleSearch() {
    const { openSearch } = this.state

    if (openSearch) {
      this.inputSearchRef.value = ''
      this.handleInputSearch()
      this.setState({ openSearch: false })
      return
    }
    this.setState({ openSearch: true })
  }

  editContact(contact) {
    const {
      currentCustomer, stepActions, contactActions, extraInfos, customClassAutoComplete,
    } = this.props
    const { currentTab } = this.state
    const renderNode = document.getElementById('CommonPopup')
    stepActions.loading()

    contactActions.getDetails(contact.id, undefined, (res) => {
      if (res?.data?.error) {
        this.handleInvalidContact(contact.id)
      } else {
        const newContact = res.data.object
        ReactDOM.render(
          <FormLocationPopup
            contact={newContact}
            shouldShow
            currentCustomer={currentCustomer}
            contactActions={contactActions}
            currentTab={currentTab}
            extraInfos={extraInfos}
            handleContactsChange={this.handleContactsChange}
            customClassAutoComplete={customClassAutoComplete}
          />,
          renderNode
        )
        stepActions.loaded()
      }
    })
  }

  renderContact({
    key, index, style
  }) {
    const { filteredContacts, selectContactID } = this.state
    const {
      isContactView, isPickupLocation, locationContactID, actionContactText,
    } = this.props
    const contact = filteredContacts[index]
    const displayName = isContactView ? `${contact.location_name} - ${contact.name}` : contact.location_name
    const displayAddress = isContactView ? contact.phone : contact.address
    const checkSelectContact = contact.id === selectContactID
    const shouldDisableButton = _.toInteger(locationContactID) === _.toInteger(selectContactID)
    return (
      <div
        key={key}
        style={style}
        className={`Popover-contact-list-item ${checkSelectContact ? 'Light-Green-bg' : ''} relative`}
      >
        <div className="Popover-contact-item-info pt5 pr10 pb5 pl10 h100 flex-index Box-Sizing Border-Box w100" onClick={() => this.selectContact(contact.id)}>
          <div className="TitleSubtitle w100">
            <div className={`TitleSubtitle-title TitleSubtitle-title-name Shorted-Text ${checkSelectContact ? 'flex mr15' : ''}`}>
              <div className={`Shorted-Text ${checkSelectContact ? 'flex-index' : ''}`}>
                {displayName}
              </div>
            </div>
            <div className="TitleSubtitle-subtitle TitleSubtitle-title-phone m-h32px o-hidden mt5 small-font-important">
              {displayAddress}
            </div>
          </div>
        </div>
        {checkSelectContact && (
          <div
            className="Popover-contact-item-edit z-index-2"
            onClick={() => this.editContact(contact)}
          >
            <img src={ICON_EDIT_LOCATIONS} alt="edit-icon" />
          </div>
        )}
        {checkSelectContact && (
          <div className="Popover-contact-item-button z-index-2">
            <button
              type="button"
              className={`green Button Button-Default w100 capitalize ${shouldDisableButton && 'Button-Disabled'}`}
              onClick={() => this.setContactForLocation(contact.id)}
            >
              {actionContactText || (isPickupLocation ? I18n.t('webapp.long_haul.set_pickup') : I18n.t('webapp.long_haul.set_destination'))}
            </button>
          </div>
        )}
      </div>
    )
  }

  renderContacts() {
    const {
      currentCustomer, className, searchPlaceholder, isContactView, contactActions, extraInfos,
      customClassAutoComplete,
    } = this.props
    const {
      filteredContacts, currentTab, selectedChar, allContacts, selectContactID, openSearch
    } = this.state

    const gridData = []
    filteredContacts.forEach((contact) => {
      let size = 0
      const sizelengthOfText = isContactView ? contact.phone.length : contact.address.length
      if (isContactView) {
        size = contact.id === selectContactID ? 100 : 50
      } else {
        const sizeSmallText = sizelengthOfText > 44 ? 70 : 50
        const sizeSmallTextActive = sizelengthOfText > 44 ? 115 : 100
        size = contact.id === selectContactID ? sizeSmallTextActive : sizeSmallText
      }
      gridData.push({ size })
    })
    this.gridData = gridData
    const isBusiness = _.parseInt(currentCustomer.current_company_id) > 0
    const shouldShowActionBar = _.size(allContacts[currentTab]) > 0 || openSearch

    return (
      <div className={className}>
        <div className="Popover-contact-list-arrow" />
        <div className="Popover-contact-list-shadow">
          <HeaderTabs
            isBusiness={isBusiness}
            switchTabAction={this.switchTabs}
            currentTab={currentTab}
          />
          {
            shouldShowActionBar && (
              <div className={`Selectbox-Contact-Actions flex ${isBusiness ? 'No-Border-Radius-Important' : ''}`}>
                {!isContactView && (
                  <AddNewButton
                    openSearch={openSearch}
                    currentCustomer={currentCustomer}
                    extraInfos={extraInfos}
                    contactActions={contactActions}
                    currentTab={currentTab}
                    customClassAutoComplete={customClassAutoComplete}
                  />
                )}
                <div className="flex flex-index flex-end-justify ml5 Selectbox-Contact-Actions__Filter">
                  {!isContactView && (
                    <AlphabetBar
                      alphabetList={this.alphabetList}
                      selectedChar={selectedChar[currentTab]}
                      filteredContacts={filteredContacts}
                      openSearch={openSearch}
                      scrollToChar={this.scrollToChar}
                      firstChar={this.firstChar}
                    />
                  )}
                  <SearchBar
                    searchPlaceholder={searchPlaceholder}
                    searchAction={this.search}
                    openSearch={openSearch}
                    toggleSearch={this.toggleSearch}
                    inputSearchRef={(el) => { this.inputSearchRef = el }}
                    isContactView={isContactView}
                  />
                </div>
              </div>
            )
          }
          <div className={`Popover-contact-list min-h-${this.getHeightList()}`}>
            <AutoSizer disableHeight>
              {({ width }) => (
                <List
                  width={width}
                  height={this.getHeightList()}
                  rowCount={_.size(filteredContacts)}
                  rowHeight={this.getRowHeight}
                  rowRenderer={this.renderContact}
                  noRowsRenderer={this.showMessageNoContact}
                  ref={(el) => { this.listRef = el }}
                  onRowsRendered={this.handleOnRowsRendered}
                  onScroll={this.handleOnScroll}
                />
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { showContacts } = this.state
    const {
      buttonText, buttonClass, renderIcon, isAlwaysRenderContact,
    } = this.props
    const defaultButtonClass = `white-text white-border with-icon Button show-selectbox-contact w100 ${showContacts ? 'Dark-Green-bg' : ''} ${isAlwaysRenderContact ? 'Dark-Green-bg-hover White-text-hover cur-default-important' : ''}`

    return (
      <div className="flex-index ml5 mr5">
        <button
          type="button"
          onClick={this.toggleShowContacts}
          className={buttonClass || defaultButtonClass}
        >
          {!_.isUndefined(renderIcon) && _.size(renderIcon().props.children) ? renderIcon() : null}
          {buttonText}
        </button>
        {showContacts && this.renderContacts()}
      </div>
    )
  }
}

MyLocations.propTypes = {
  emptyMessage: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  stepActions: PropTypes.shape({}).isRequired,
  contactActions: PropTypes.shape({}).isRequired,
  currentCustomer: PropTypes.shape({}).isRequired,
  extraInfos: PropTypes.shape({}).isRequired,
  callbackSwitchTabs: PropTypes.func,
  handleSelectContact: PropTypes.func.isRequired,
  actionContactText: PropTypes.string,
  optionalData: PropTypes.shape({}),
  contacts: PropTypes.instanceOf(Array),
  personalContacts: PropTypes.instanceOf(Array),
  className: PropTypes.string,
  renderIcon: PropTypes.func,
  searchPlaceholder: PropTypes.string,
  buttonClass: PropTypes.string,
  customClassAutoComplete: PropTypes.string,
  isContactView: PropTypes.bool,
  isPickupLocation: PropTypes.bool,
  isAlwaysRenderContact: PropTypes.bool,
  locationContactID: PropTypes.number,
  onRef: PropTypes.func.isRequired,
  onShowContacts: PropTypes.func,
  focusInputID: PropTypes.string,
  onkey: PropTypes.string,
  isAddMeClicked: PropTypes.bool,
  setIsAddMeClicked: PropTypes.func,
  myLocationId: PropTypes.string,
  setMyLocationId: PropTypes.func,
}

MyLocations.defaultProps = {
  actionContactText: '',
  optionalData: {},
  contacts: [],
  personalContacts: [],
  className: '',
  renderIcon: undefined,
  searchPlaceholder: '',
  buttonClass: '',
  customClassAutoComplete: '',
  myLocationId: '',
  isContactView: false,
  isPickupLocation: false,
  isAlwaysRenderContact: false,
  locationContactID: undefined,
  callbackSwitchTabs: undefined,
  onkey: '',
  onShowContacts: undefined,
  focusInputID: '',
  isAddMeClicked: false,
  setIsAddMeClicked: () => {},
  setMyLocationId: () => {},
}

MyLocations.childContextTypes = {
  handleContactsChange: PropTypes.func,
}

export default MyLocations
