/* Utils for Jquery */
import moment from 'moment'
import $ from 'jquery'
import _ from 'lodash-es'
import CommonUtils from './common'
import { ADD_FLASH_BATCHES, REMOVE_FLASH_BATCHES, MAX_LENGTH_ALPHABET } from 'constants/ezSpreadSheetConstants'
import { GOOGLE_ANALYTIC_CODE, LTL_CUSTOMER_DOMAIN_PH, LTL_CUSTOMER_DOMAIN } from 'constants/appConstants'
import {
  FULL_DAY,
  LONG_HAUL,
  NOW,
  SCHEDULE,
  TALLY_LIST_STATUS_ACCEPT,
  FRESH_CHAT_CHANNEL,
  FRESH_CHAT_CHANNEL_PH,
  FRESH_CHAT_CHANNEL_TH,
  SHOW_PAYMENT_METHOD_LIST,
  CUSTOMER_QUOTE_PAYMENT,
} from 'constants/bookingConstants'
import { POPUP_ID_CHANGE_INFO_AFTER_ASSIGNED_DRIVER } from 'constants/common/popupConstants'
import { DRIVER_PREFERENCES } from 'constants/newBookingConstants'
import { ICON_AVATAR_FLEET_PARTNER_ASSIGN_DRIVER, CART_SHOPPING } from 'constants/imageConstants'
import {
  URL_WEBAPP_ID, URL_WEBAPP_TH, URL_WEBAPP_PH, COUNTRY_TH, COUNTRY_ID, COUNTRY_PH,
  URL_STG_ID, URL_STG_TH, URL_STG_PH,
} from 'constants/dashBoardAnalyticsConstants'
import { LocationPopover } from './LocationUtil'
import firebaseInit from './firebaseInit'
import { EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS } from 'constants/extraServiceConstants'
import I18n from 'i18n/i18n'
import toastr from 'utils/toast';
import { showLoading } from 'assets/javascripts/webapp-v2/common'
import { getParamFromURL } from './booking/common'
import { isCustomerEditBooking } from './new_booking/common'

let uniqueId = new Date().getTime()

export const Utils = {
  addClassForHotLiveChat: (className) => {
    $('body').addClass(className)
  },
  checkColumnTableEZ: (currentStep, stepClass) => {
    // function hasScrollBar(element) {
    //   return element.get(0).scrollHeight > element.get(0).clientHeight
    // }

    function checkTableEZ() {
      const indexClass = stepClass.find('.Table-Sortable')
      let overflowYScroll = 10
      // set Calc Height TableEZ
      const menuElements = $('.Menu').outerHeight() || 0
      const headElements = $('.PageHead').outerHeight() || 0
      const stepElements = $('.Custom-Step').outerHeight() || 0
      const buttonElements = $('.BatchUploadValidate section.actions').outerHeight() || 0
      const titleElements = $('.Batch-Custom .BatchUpload-Title').outerHeight() || 0
      const titleDescElements = $('.Batch-Custom .BatchUpload-Title.description-area').outerHeight() || 0
      let getHeight = 0
      getHeight = menuElements + headElements + stepElements + buttonElements + titleElements + titleDescElements

      // let OSName
      // if (navigator.appVersion.indexOf('Mac') !== -1) OSName = 'MacOS'
      // const isOpera = !!window.opera || navigator.userAgent.indexOf('Opera') >= 0
      const isFirefox = typeof InstallTrigger !== 'undefined'
      // const isSafari = Object.prototype.toString.call(window.HTMLElement).indexOf('Constructor') > 0
      // const isChrome = !!window.chrome
      // if (OSName == "MacOS" && isChrome == true) overflowYScroll = 0
      if (isFirefox) overflowYScroll = 16

      indexClass.css({
        'padding-top': stepClass.find('.Batch-Table > .Table').height() + 2,
        width: stepClass.find('.Batch-Table > .Table').width() + 2 + overflowYScroll,
        'max-height': window.innerHeight - getHeight - overflowYScroll,
        height: window.innerHeight - getHeight - overflowYScroll,
        'box-sizing': 'border-box'
      })

      indexClass.css('padding-bottom', '')
      if (overflowYScroll) {
        indexClass.css('padding-bottom', '70px')
      }
    }

    const checkTable = stepClass.find('.Table-Sortable')
    if (checkTable.length) {
      setTimeout(() => {
        checkTableEZ()
      }, 250)
      $(window).resize(() => {
        checkTableEZ()
      })
    }
  },
  checkColumnGridEZ: () => {
    const menuElements = $('.Menu').outerHeight() || 0
    const pageHeadElements = $('.PageHead').outerHeight() || 0
    const pageStepElements = $('.Custom-Step').outerHeight() || 0
    const buttonElements = $('.BatchUploadValidate section.actions').outerHeight() || 0
    const titleElements = $('.Batch-Custom .BatchUpload-Title').outerHeight() || 0
    const titleDescElements = $('.Batch-Custom .BatchUpload-Title.description-area').outerHeight() || 0
    const scaleZoom = CommonUtils.detectSmallLaptop() ? (window.innerHeight / 100) * 15 : 0
    let getHeight = 0
    getHeight = menuElements + pageHeadElements + pageStepElements + buttonElements + titleElements + titleDescElements
    return window.innerHeight - getHeight + scaleZoom
  },
  setMinWidthPages: () => {
    $('body').css('min-width', '1266px')
  },
  setMinWidthPagesWithElments: (element) => {
    element.css('min-width', '1266px')
  },
  checkLeftListBatchsStep06: () => {
    function checkList() {
      const titleElements = $('.Batch-Custom-Left > h3').outerHeight(true) || 0
      const searchElements = $('.Batch-Custom-Left-Search').outerHeight(true) || 0
      const paginationElements = $('.Batch-Custom-Left-Pagination').outerHeight(true) || 0
      $('.Batch-Custom-Left > ul').css({
        top: titleElements + searchElements,
        bottom: paginationElements + 16
      })
    }

    if ($('.Batch-Custom-Left ul').length) {
      setTimeout(() => {
        checkList()
      }, 250)
      $(window).resize(() => {
        checkList()
      })
    }
  },
  checkWidthIconStatusStep06: () => {
    if ($('.Status-Custom-Line').width() !== undefined) {
      $('.locations_status').removeClass('custom')
    } else {
      $('.locations_status').addClass('custom')
    }
  },
  resetExpandButton: () => {
    $('.Batch-Table-Sortable').find('.expanded__expandButton').removeClass('expanded__collapseButton')
    $('.Batch-Table-Sortable').find('.Pickup-Hide').removeClass('Show')
  },
  showInputSearch: () => {
    $('.Search-Common').addClass('Show')
  },
  initSearchCommon: (event) => {
    const element = $(event.target)
    if (element.hasClass('Show')) {
      return
    }
    element.addClass('Show')
    setTimeout(() => {
      element.find('input').focus()
      $(document).mouseup((e) => {
        // if the target of the click isn't the container nor a descendant of the container
        if (!element.is(e.target) && element.has(e.target).length === 0 && element.find('input').val() === '') {
          element.removeClass('Show')
        }
      })
    }, 400)
  },
  showWarningPopupSwitchAccount: (ReactDOM, renderNode, callback) => {
    $('.expression_link_choose').attr('data-method', '')
    $(document).on('click', '.expression_link_choose', (event) => {
      event.stopPropagation()
      event.preventDefault()
      const info = {
        icon: '',
        title: I18n.t('popup.unsaved.title'),
        specialClass: 'z-index-max RightMenu w100',
        isDefaultPopup: true,
      }
      const content = {
        title: I18n.t('popup.unsaved.content'),
        specialClass: 'default-font reset m p Left-text',
      }
      const buttons = { specialClass: 'Modal-Actions Box-Actions Box-Actions-Customs mt10 flex' }
      buttons.list = [
        {
          title: I18n.t('popup.unsaved.btn_cancel'),
          specialClass: 'Button gray flex-index mr5',
          options: {},
          specialClick: () => ReactDOM.unmountComponentAtNode(renderNode),
        },
        {
          title: I18n.t('popup.unsaved.btn_ok'),
          specialClass: 'Button expression_link_choose green flex-index ml5',
          options: {},
          specialClick: () => {
            const id = event?.currentTarget?.attributes?.getNamedItem('data-customer-id')?.value
            $('#loading-modal').addClass('visible')
            $('.RightMenu').removeClass('visible')
            ReactDOM.unmountComponentAtNode(renderNode)
            $(`#customer_${id}`)[0].click()
          },
        }
      ]
      callback(info, content, buttons)
    })
  },

  getLTLCustomerDomain: defaultCountry => (defaultCountry?.toUpperCase() === COUNTRY_PH && LTL_CUSTOMER_DOMAIN_PH
    ? LTL_CUSTOMER_DOMAIN_PH : LTL_CUSTOMER_DOMAIN),

  showBlockingPopup: (ReactDOM, renderNode, callback) => {
    const info = {
      icon: CART_SHOPPING,
      title: I18n.t('webapp.shopping.booking_in_progress'),
      specialClass: 'Normal-Booking Reset-Width-Popup-Booking Reset-Title-Popup-Booking w320 zoom-scale-8',
      maxHeightIcon: true,
    }
    const content = {
      title: I18n.t('webapp.shopping.block_booking'),
      specialClass: 'reset p m default-font White-text center',
    }
    const buttons = { specialClass: 'mt10 flex' }
    buttons.list = [
      {
        title: I18n.t('closures.label.my_bookings'),
        specialClass: 'Button white Green-text Button-Default flex-index default-medium-font',
        specialClick: () => {
          window.location.href = '/bookings'
        },
      }
    ]
    callback(info, content, buttons)
  },
  addPaddingBottomToValidateStep: () => {
    $('.BatchUploadValidate .Table-Sortable').animate({
      scrollTop: $('.BatchUploadValidate .Table-Sortable').prop('scrollHeight') + 70
    }, 'slow')
  },
  showToastrMessage: (messageType, messageContent) => {
    // toastr.remove()
    toastr[messageType](messageContent)
  },
  removeOptionToastrMessage: (timeout) => {
    setTimeout(() => {
      toastr.options = {
        closeButton: false,
        debug: false,
        newestOnTop: true,
        progressBar: false,
        positionClass: 'toast-top-center',
        preventDuplicates: true,
        onclick: null,
        showDuration: '300',
        hideDuration: '1000',
        timeOut: '5000',
        extendedTimeOut: '1000',
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut'
      }
    }, timeout)
  },
  hourFormatByCountry: (countryCode) => {
    let formatHour
    switch (countryCode?.toUpperCase()) {
      case 'PH':
        formatHour = 'h'
        break
      default:
        formatHour = 'H'
    }
    return formatHour
  },
  formatAssignDriverDate: (date, shortDayFormat = false) => {
    let day = I18n.t(`webapp.${shortDayFormat ? 'format_short_day' : 'format_day'}.day_${date.day()}`)
    if (date.isSame(moment(), 'day')) {
      day = I18n.t('label.today')
    } else if (date.isSame(moment().add(1, 'days'), 'day')) {
      day = I18n.t('label.tomorrow')
    }
    const month = I18n.t(`webapp.format_short_month.month_${moment(date).month()}`)
    const formatDate = moment(date).format(`D-[${month}]-YYYY`)
    return `${day}, ${formatDate}`
  },
  formatDateTime: (dateTime, countryCode, shortDayFormat = true) => {
    const formatHour = Utils.hourFormatByCountry(countryCode)
    const month = I18n.t(`webapp.format_short_month.month_${moment(dateTime).month()}`)
    const day = I18n.t(`webapp.${shortDayFormat ? 'format_short_day' : 'format_day'}.day_${moment(dateTime).day()}`)
    return {
      date: moment(dateTime).format(`D-[${month}]-YY`),
      time: moment(dateTime).format(`${formatHour}:mm a`),
      fullDate: moment(dateTime).format(`D-[${month}]-YYYY`),
      fullTimeWithOutDate: moment(dateTime).format(`${formatHour}:mm a, D-[${month}]-YY`),
      fullTimeDate: moment(dateTime).format(`${formatHour}:mm a, [${day}] D-[${month}]-YY`),
      time24Format: moment(dateTime).format('H:mm')
    }
  },
  pickupTimeLTL: (startTime, endTime, dateTime, countryCode) => {
    const formatHour = Utils.hourFormatByCountry(countryCode)
    const month = I18n.t(`webapp.format_short_month.month_${moment(dateTime).month()}`)
    const pickupTime = moment(dateTime).date()
    const currentDate = moment().date()
    const selectedHour = moment(dateTime).hour()
    const selectedMinute = moment(dateTime).minute()
    const startHour = startTime ? parseInt(startTime.slice(0, 2), 0) : null
    const startMinute = startTime ? parseInt(startTime.slice(3), 0) : null
    const endHour = endTime ? parseInt(endTime.slice(0, 2), 0) : null
    const endMinute = endTime ? parseInt(endTime.slice(3), 0) : null
    const startTimeFormat = moment(moment().set({ hour: startHour, minute: startMinute })).format(`${formatHour}:mm a`)
    const endTimeFormat = moment(moment().set({ hour: endHour, minute: endMinute })).format(`${formatHour}:mm a`)
    const pickupDate = moment(dateTime).format(`D-[${month}]-YY`)
    if (currentDate === pickupTime) {
      if (selectedHour > startHour || (selectedHour === startHour && selectedMinute > startMinute)) {
        return I18n.t('webapp.booking.end_time_ltl', { end_time: endTimeFormat })
      }
      return I18n.t('webapp.booking.range_pickup_time_ltl', { start_time: startTimeFormat, end_time: endTimeFormat })
    }
    return I18n.t('webapp.booking.pickup_time_ltl', {
      start_time: startTimeFormat, end_time: endTimeFormat, pickup_date: pickupDate
    })
  },
  generateArrayTimeByStep: (step) => {
    const hours = []
    const dt = moment().startOf('day')
    while (dt.isBefore(moment().endOf('day'))) {
      hours.push(dt.format('HH:mm'))
      dt.add(step, 'minutes')
    }
    return hours
  },
  checkPickupTimeValidAssignDriver: (data, pickupTime, eta, numberFullDays = 1) => {
    if (_.isEmpty(data)) {
      return true
    }
    let validate = 0
    const start = moment(pickupTime)
    for (let i = 0; i < numberFullDays; i += 1) {
      start.add(i, 'days')
      const end = start.clone().add(eta, 'seconds')
      validate = _.findIndex(data, (blockedTime) => {
        const blockedFrom = moment(blockedTime.start_at)
        const blockedEnd = moment(blockedTime.end_at)
        return start.isSameOrBefore(blockedEnd) && end.isSameOrAfter(blockedFrom)
      })
      if (validate !== -1) {
        break
      }
    }
    return validate === -1
  },
  getAvailableTimeSlots: (blockedTimeInfos, selectedTime, minutePickup, eta, numberFullDays = 1) => {
    const steps = Utils.generateArrayTimeByStep(blockedTimeInfos.step)
    const minPickup = moment().add(minutePickup, 'minutes')
    const { data } = blockedTimeInfos
    const availableSteps = []
    _.forEach(steps, (step) => {
      const [hour, minute] = _.split(step, ':')
      const startTime = moment(selectedTime).hours(hour).minutes(minute)
      if (startTime.isBefore(minPickup)) {
        return true
      }
      let validate = 0
      for (let i = 0; i < numberFullDays; i += 1) {
        startTime.add(i, 'days')
        const endTime = startTime.clone().add(eta, 'seconds')
        validate = _.findIndex(data, (blockedTime) => {
          const blockedFrom = moment(blockedTime.start_at)
          const blockedEnd = moment(blockedTime.end_at)
          return startTime.isSameOrBefore(blockedEnd) && endTime.isSameOrAfter(blockedFrom)
        })
        if (validate !== -1) {
          break
        }
      }
      if (validate === -1) {
        availableSteps.push(step)
      }
      return true
    })
    return availableSteps
  },
  getPreferTimeSlot: (arrayTimeSlots, pickupTime, countryCode) => {
    const formatHour = Utils.hourFormatByCountry(countryCode)
    const pickupTimeToSecond = Utils.convertFormatTimeToSecond(moment(pickupTime).format(`${formatHour}:mm`))
    return _.reduce(arrayTimeSlots, (prev, cur) => Utils.compareCloserTime(prev, cur, pickupTimeToSecond), 0)
  },
  compareCloserTime: (prev, cur, pickupTimeToSecond) => {
    const curMinusTime = Math.abs(Utils.convertFormatTimeToSecond(cur) - pickupTimeToSecond)
    const prevMinusTime = Math.abs(Utils.convertFormatTimeToSecond(prev) - pickupTimeToSecond)
    return curMinusTime < prevMinusTime ? cur : prev
  },
  convertFormatTimeToSecond: (time) => {
    let result = 0
    if (time) {
      const arrayTimes = time.split(':')
      _.map(arrayTimes, (num, index) => { result += num * (3600 / (60 ** index)) })
    }
    return result
  },
  convertSecondsToTimeString: (seconds, { delimiter = ' ', ignoreSecond = true } = {}) => {
    const hh = Number.parseInt(seconds / 3600, 10)
    const mm = Number.parseInt(seconds / 60, 10) - hh * 60
    const ss = seconds - (hh * 60 * 60 + mm * 60)
    let result = ''
    const hourUnit = I18n.t('webapp.tally.unit.hour')
    const minuteUnit = I18n.t('webapp.tally.unit.minute')
    const secondUnit = I18n.t('webapp.tally.unit.second')
    if (!ignoreSecond) {
      result = `${ss}${secondUnit}`
    }

    if (mm > 0 || hh > 0) {
      result = result ? `${mm}${minuteUnit}${delimiter}${result}` : `${mm}${minuteUnit}`
    }

    if (hh > 0) {
      result = result ? `${hh}${hourUnit}${delimiter}${result}` : `${hh}${hourUnit}`
    }

    return result
  },
  toggleClass: (element, className) => {
    element.toggleClass(className)
  },
  addClass: (element, className) => {
    element.addClass(className)
  },
  removeClass: (element, className) => {
    element.removeClass(className)
  },
  addCSS: (element, config) => {
    element.css(config)
  },
  removeAttr: (element, attr) => {
    element.attr(attr, '')
  },
  replaceText: (source, target, replacement, hyperLink = false) => {
    if (target && replacement) {
      let newReplacement = replacement
      if (hyperLink) {
        newReplacement = `<a class="no-hover yellow underline hyperlink-default-color" href=${replacement} target="_blank">${target}</a>`
      }
      const pattern = new RegExp(target, 'gm')
      return _.replace(source, pattern, newReplacement)
    }
    return source
  },
  addLocalStorage: (batches, BATCH_STATUS_PARTIALLY_CONFIRMED, BATCH_STATUS_UNCONFIRMED) => {
    const getStatus = localStorage.getItem('batch_status')
    const getItem = parseInt(localStorage.getItem('batch_id'), 10)

    if (getStatus || getItem) {
      const checkLocalStatus = [BATCH_STATUS_PARTIALLY_CONFIRMED, BATCH_STATUS_UNCONFIRMED].includes(getStatus)
      const checkBatchStatus = [BATCH_STATUS_PARTIALLY_CONFIRMED, BATCH_STATUS_UNCONFIRMED].includes(batches.status)
      if (checkLocalStatus === checkBatchStatus && getItem === parseInt(batches.id, 10)) {
        Utils.flashBatchMenu(ADD_FLASH_BATCHES)
        localStorage.setItem('batch_retry', true)
      }
    } else {
      Utils.flashBatchMenu(ADD_FLASH_BATCHES)
    }
  },
  checkLocalStorage: (batches, BATCH_STATUS_PARTIALLY_CONFIRMED, BATCH_STATUS_UNCONFIRMED) => {
    const getStatus = localStorage.getItem('batch_status')
    const getItem = parseInt(localStorage.getItem('batch_id'), 10)
    const getRetry = localStorage.getItem('batch_retry')
    const checkLocalStatus = [BATCH_STATUS_PARTIALLY_CONFIRMED, BATCH_STATUS_UNCONFIRMED].includes(getStatus)
    const checkBatchStatus = [BATCH_STATUS_PARTIALLY_CONFIRMED, BATCH_STATUS_UNCONFIRMED].includes(batches.status)
    if (getStatus || getItem) {
      if (checkLocalStatus === checkBatchStatus && getItem === parseInt(batches.id, 10)) {
        if (getRetry === 'true') {
          Utils.flashBatchMenu(ADD_FLASH_BATCHES)
        } else {
          Utils.flashBatchMenu(REMOVE_FLASH_BATCHES)
        }
      } else if (checkLocalStatus === checkBatchStatus) {
        Utils.flashBatchMenu(ADD_FLASH_BATCHES)
      }
    } else if (checkBatchStatus) {
      Utils.flashBatchMenu(ADD_FLASH_BATCHES)
    }
  },
  flashBatchMenu: (types) => {
    if (types === ADD_FLASH_BATCHES) {
      $('.Dropdown-Batch').addClass('flash-button-parent')
      $('.Dropdown-Batch .Dropdown-Head').addClass('flash-button')
    } else {
      $('.Dropdown-Batch').removeClass('flash-button-parent')
      $('.Dropdown-Batch .Dropdown-Head').removeClass('flash-button')
    }
  },
  initGalerry: () => {
    $('.basic-popup-gallery').magnificPopup({
      delegate: 'a:not(.View_Target)',
      type: 'image',
      tLoading: 'Loading...',
      mainClass: 'mfp-img-mobile',
      gallery: {
        enabled: false,
        navigateByImgClick: false,
      },
      image: {
        tError: 'The image could not be loaded.',
      }
    })
  },
  initGalerryWithoutFile: () => {
    $('.basic-popup-gallery a').each(function getFile() {
      const extension = $(this).attr('href').split('?')[0].split('.').pop()
      if (extension === 'pdf' || extension === 'docs' || extension === 'doc') {
        $(this).addClass('View_Target')
        $(this).attr('target', '_blank')
      }
    })
  },
  scrollTop: (config) => {
    config.element.animate({
      scrollTop: config.positionScroll
    }, config.animation)

    // Focus Element
    if (config.elementFocus) {
      config.elementFocus.focus()
    }
  },
  getHeightOutSideMultipleBooking: () => {
    const menuElements = $('.Menu').outerHeight() || 0
    const pageHeadElements = $('.PageHead').outerHeight() || 0
    const headerElements = $('.MultipleBookingLayout .BookingWizard .Header').outerHeight() || 0
    const headerCustomElements = $('.MultipleBookingLayout .BookingWizard .Header.Header-Custom').outerHeight() || 0
    return (menuElements + pageHeadElements + headerElements + headerCustomElements)
  },
  checkValidateMultiple: (elements) => {
    if (!_.isEmpty(elements)) {
      const getElement = elements
      const elementOffsetTop = getElement.find('.error').first().offset().top || 0
      const calSubHeight = Utils.getHeightOutSideMultipleBooking()
      const elementScrollTop = getElement.scrollTop()
      const calHeight = elementOffsetTop - calSubHeight + elementScrollTop
      const config = {
        element: getElement,
        positionScroll: calHeight - 20,
        animation: 300,
        elementFocus: false
      }
      Utils.scrollTop(config)
    }
  },
  checkHeightPopup: (element, position = 120) => {
    if (element.height() + position > element.parent().height()) {
      element.addClass('No-Seft')
    } else {
      element.removeClass('No-Seft')
    }
  },
  checkLengthOfInput: (inputBatch, widthTitle) => {
    inputBatch.css({
      width: widthTitle + 50,
    })
  },
  displayAddress: (location, addressComponents = [], useShorterAddress = false) => {
    const defaultAddress = (location.formatted_address || location.address) || location.name
    if (!useShorterAddress || _.isEmpty(addressComponents) || _.isEmpty(location.address_components)) {
      return defaultAddress
    }
    const address = location.address_components.map((component) => {
      const validComponent = component.types.some(type => addressComponents.includes(type))
      return validComponent ? component.long_name : undefined
    })
    const shortenAddress = _.join(_.compact(address), ', ')
    return (shortenAddress || defaultAddress)
  },
  getAddressComponents: (address) => {
    const errorMsg = 'Geocoded Error'
    return new Promise((resolve, reject) => {
      if (typeof (google) !== 'undefined' && typeof (window.google.maps) !== 'undefined') {
        new window.google.maps.Geocoder().geocode({ address }, (results, status) => {
          if (status === window.google.maps.GeocoderStatus.OK) {
            resolve(results[0])
          } else if (status === window.google.maps.GeocoderStatus.ERROR) {
            reject(Error(errorMsg))
          } else {
            resolve(undefined)
          }
        })
      } else {
        resolve(undefined)
      }
    })
  },
  scrollLeft: (config) => {
    config.element.animate({
      scrollLeft: config.positionScroll
    }, config.animation)

    // Focus Element
    if (config.elementFocus) {
      config.elementFocus.focus()
    }
  },
  initMaskingBatchEZStep04: () => {
    const html = '<div class="MultipleReviewWrapper-Title-Icon"><span class=""><i class="material-icons Icon">arrow_drop_down</i></span></div>'
    $('.MultipleReviewWrapper').each(function eachWrapper() {
      $(this).find('h3.Custom:not(.Custom-Color-Multitple)').append(html)
      $(this).find('.MultipleBlock').addClass('Hide')
    })
    $('.MultipleReviewWrapper-Title-Icon').click(function toggleIcon() {
      $(this).parent().next().toggleClass('Hide')
      $(this).toggleClass('Reset-Padding Rotate')
    })
  },
  setContactLocation: (location, contact, contactActions, stepActions, callback, parent) => {
    stepActions.loading()
    const cloneContact = { ...contact }
    Utils.getAddressComponents(contact.address)
      .then((response) => {
        if (response) { _.assign(cloneContact, { addressComponents: response.address_components }) }
      })
      .then(() => {
        contactActions.updateContactInfo(cloneContact)
      })
      .then(() => {
        callback(
          location, contact, contact.address, contact.latitude,
          contact.longitude, cloneContact.addressComponents, parent
        )
      })
      .then(() => stepActions.loaded())
  },
  showAlphabet: (index) => {
    if (index >= MAX_LENGTH_ALPHABET) {
      const matchFloorAlphabet = Math.floor(index / MAX_LENGTH_ALPHABET)
      const calMatchFloorAlphabet = String.fromCharCode(65 + (index - (matchFloorAlphabet * MAX_LENGTH_ALPHABET)))
      return String.fromCharCode(65 + (matchFloorAlphabet - 1)) + calMatchFloorAlphabet
    }
    return String.fromCharCode(65 + index)
  },
  removeAllClass: (element, className) => {
    _.forEach($(element), (item) => {
      $(item).removeClass(className)
    })
  },
  addAllClass: (element, className) => {
    _.forEach($(element), (item) => {
      $(item).addClass(className)
    })
  },
  checkScrollBookingSummaryStep4: () => {
    const element = $('.MultipleBookingLayout-Summary-BatchEZ-Dotline')
    const parentElement = $('.MultipleBookingLayout-Collapse').outerHeight(true) || 0
    const businessFee = $('.MultipleBookingLayout-Summary-Group-Business').outerHeight(true) || 0
    const personalFee = $('.MultipleBookingLayout-Summary-Group-Personal').outerHeight(true) || 0
    const noteFee = $('.MultipleBookingLayout-Summary-Group-Note').outerHeight(true) || 0
    const totalFee = $('.MultipleBookingLayout-Summary-Group-Total').outerHeight(true) || 0
    const titleFee = $('.MultipleBookingLayout-Summary-Title').outerHeight(true) || 0
    const cashbackInfo = $('.cashback-info-section').outerHeight(true) || 0
    const creditSection = $('.credit-section').outerHeight(true) || 0
    const calHeight = parentElement - (
      titleFee
      + totalFee
      + noteFee
      + businessFee
      + personalFee
      + cashbackInfo
      + creditSection
    )

    element.css('max-height', calHeight - 40)
  },
  showPopupDropdown: (e) => {
    const closestElement = $(e.target).parent()
    const element = closestElement.find('.Default-Dropdown')
    const elementChild = element.find('.Default-Dropdown-List')
    const getHeight = element.outerHeight(true) + 14
    element.toggleClass('Show')
    element.css('top', -getHeight)

    $(document).on('click', elementChild, function toggleClick() {
      $(this).closest('.Default-Dropdown').removeClass('Show')
    })

    $(document).mouseup((ee) => {
      if (
        (!element.is(ee.target) && element.has(ee.target).length === 0)
        && (!closestElement.is(ee.target) && closestElement.has(ee.target).length === 0)
      ) {
        element.removeClass('Show')
      }
    })
  },
  checkNonePricing: (timeType, allowNonePricing) => (
    _.includes([NOW, SCHEDULE], timeType) && allowNonePricing?.regular_booking)
    || (_.includes([FULL_DAY], timeType) && allowNonePricing?.full_day)
    || (_.includes([LONG_HAUL], timeType) && allowNonePricing?.long_haul
    ),
  openPopupLongHaul: (id, options) => {
    LocationPopover.closeClass($('.Info.Popover'), 'visible')

    const closetLongHaulElement = `#booking_locations_attributes_longhaul_${id}`

    const config = {
      type: 'long-haul',
      popover: 30,
      top: 70,
      arrow: 74
    }
    const elementConfig = {
      element: $(`${closetLongHaulElement}`),
      arrow_element: $(`${closetLongHaulElement} .Popover-Arrow`),
      element_left_position: '100%',
      element_index_position: 99999,
      arrow_element_position: 15
    }
    let input = options.nameNode
    if ($(options.nameNode).css('display') === 'none') {
      input = options.recentLocationsNode
    }
    LocationPopover.init(
      input,
      $(`${closetLongHaulElement}`).height(),
      0,
      config,
      elementConfig
    )

    const calcLongHaulLine = $(`${closetLongHaulElement} .Popover-Item-LongHaul-Line`).outerHeight(true) || 0
    const calcLongHaulInput = $(`${closetLongHaulElement} .Popover-Item-LongHaul-Input`).outerHeight(true) || 0
    const calcLongHaulButton = $(`${closetLongHaulElement} .Popover-Item-LongHaul-Actions button`).outerHeight(true) || 0
    const calcLongHaulTitle = $(`${closetLongHaulElement} .Popover-Item-LongHaul-Title`).outerHeight(true) || 0
    const calcLongHaulTitleInput = $(`${closetLongHaulElement} .Popover-Item-LongHaul-Title-Input`).outerHeight(true) || 0
    const calcElement = calcLongHaulLine
      + calcLongHaulInput
      + calcLongHaulButton
      + calcLongHaulTitle
      + calcLongHaulTitleInput
    const configElement = {
      element: $(`${closetLongHaulElement} .Popover-Item-LongHaul-Choose`),
      calc: calcElement,
      padding_margin: 120,
    }
    LocationPopover.checkScroll(configElement)

    LocationPopover.addClass($('.Locations-Customs-SortableList'), 'disabled-drag')
    LocationPopover.setFocus($('.Popover-Item-LongHaul-Input textarea'), 100)
  },
  showOverlayMultiple: () => {
    $('.MultipleBookingLayout .overlay-multiple').css({
      display: 'block',
      width: $('.MultipleBookingLayout').width() + 40
    })
  },
  initDriverSlider: (options) => {
    if ($(options.contentElement).outerWidth() > $(options.closetElement).outerWidth()) {
      const arrowLeft = `.${_.replace($(options.arrowLeft).attr('class'), / /g, '.')}`
      $(options.arrowLeft).show()
      $(document).on('click', arrowLeft, () => {
        const calcScroll = $(options.closetElement).scrollLeft() - $(options.contentElement).find('span').outerWidth() - 15
        $(options.closetElement).animate({
          scrollLeft: calcScroll
        }, 250)

        if (calcScroll <= 0) {
          $(options.arrowLeft).addClass('disabled')
        } else {
          $(options.arrowLeft).removeClass('disabled')
        }

        $(options.arrowRight).removeClass('disabled')
      })
      const arrowRight = `.${_.replace($(options.arrowRight).attr('class'), / /g, '.')}`
      $(options.arrowRight).show()
      $(document).on('click', arrowRight, () => {
        const calcScroll = $(options.closetElement).scrollLeft() + $(options.contentElement).find('span').outerWidth() + 15
        const calcCloset = parseInt($(options.closetElement).scrollLeft() + $(options.closetElement).outerWidth(), 10)
        const lastScroll = Math.round(calcCloset + $(options.contentElement).find('span').outerWidth(), 10) + 15 >= parseInt($(options.contentElement).outerWidth(), 10)
        $(options.closetElement).animate({
          scrollLeft: calcScroll
        }, 250)

        if (lastScroll) {
          $(options.arrowRight).addClass('disabled')
        } else {
          $(options.arrowRight).removeClass('disabled')
        }

        $(options.arrowLeft).removeClass('disabled')
      })

      const offsetLeft = $(options.contentElement).offset().left - $(options.contentElement).parent().offset().left

      if (offsetLeft === 0) {
        $(options.arrowLeft).addClass('disabled')
      } else {
        $(options.arrowLeft).removeClass('disabled')
      }

      const calcCloset = parseInt($(options.closetElement).scrollLeft() + $(options.closetElement).outerWidth(), 10)
      const lastScroll = Math.round(calcCloset + $(options.contentElement).find('span').outerWidth(), 10) + 15 >= parseInt($(options.contentElement).outerWidth(), 10)

      if (lastScroll) {
        $(options.arrowRight).addClass('disabled')
      } else {
        $(options.arrowRight).removeClass('disabled')
      }
    } else {
      $(options.arrowLeft).hide()
      $(options.arrowRight).hide()
    }
  },
  slideToTimeSlot: (options, time) => {
    const findElement = $(options.contentElement).find(`span:contains("${time}")`)
    if (_.size(findElement)) {
      const halfClosetElement = $(options.closetElement).outerWidth() / 2
      const halfFindElement = findElement.position().left - (findElement.outerWidth() / 2)
      $(options.closetElement).animate({
        scrollLeft: $(options.closetElement).scrollLeft() + halfFindElement - halfClosetElement
      }, 0)
    }
  },
  updateUISelectCity: (boolean, config) => {
    $(config.selectCityCountry).attr('style', '')
    if (boolean) {
      const scrollContainer = $(config.countryGroup).position().left + $(config.selectCity).scrollLeft()
      $(config.selectCityCountry).css({
        marginLeft: scrollContainer - 45,
      })
    } else {
      _.forEach(config.listCountry, (item, index) => {
        const elm = $(config.selectCity).children().eq(index)
        const scrollContainer = elm.children().position().left - ((elm.outerWidth() + 10) * index)
        const scrollLeft = _.size(config.listCountry) <= 3 ? (10 * index) : 0
        $(config.selectCityCountry).find(`div:nth-of-type(${_.size(config.listCountry)}n+${index + 1})`).css({
          paddingLeft: scrollContainer - 40 + scrollLeft
        })
      })
    }
  },
  hideInputAutocomplete: (addressName, container) => {
    addressName.hide()
    container.show()
  },
  showInputAutocomplete: (addressName, container) => {
    addressName.show()
    container.hide()
  },
  initToolTip: (config) => {
    $(document).on({
      mouseenter: function mouseEnter() {
        const value = $(this).data('title')
        config.elementContent.css({
          display: 'block',
          left: $(this).position().left + $(this).outerWidth() + 15,
          top: $(this).position().top + 3
        })
        config.elementContent.html(value)
      },
      mouseleave: function mouseLeave() {
        config.elementContent.attr('style', '')
      }
    }, config.elementClosest)
  },

  updateInfoAssignDriverPopupActions: (
    action,
    currentPopupIDActions,
    infoAssignDriverPopupActions,
    bookingID
  ) => {
    infoAssignDriverPopupActions.updateInfoAssignDriverPopup({ func: action.func, backFunc: action.backFunc })
    const popupWithBookingID = _.isUndefined(bookingID) ? POPUP_ID_CHANGE_INFO_AFTER_ASSIGNED_DRIVER : `${bookingID}-${POPUP_ID_CHANGE_INFO_AFTER_ASSIGNED_DRIVER}`
    currentPopupIDActions.updateCurrentPopupID(popupWithBookingID)
  },

  /* eslint-disable */
  sentEventTrackingtoGA(eventCategory, eventAction, eventLabel = null, eventValue = null, fieldsObject = null) {
    (function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
        (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

    ga('create', GOOGLE_ANALYTIC_CODE, 'auto');
    ga('send', 'event', eventCategory, eventAction, eventLabel, eventValue, fieldsObject);
  },
    /* eslint-enable */

  // available values for zeroValue are: free, - and 0
  formatFee: (totalPrice, countryLanguage, currency, forceZeroValue = undefined, precision = 0) => {
    if (!_.isUndefined(forceZeroValue) && totalPrice === 0) return forceZeroValue
    if (currency !== undefined) return `${currency} ${Utils.formatNumberByCountry(totalPrice, countryLanguage, precision)}`
    if (totalPrice === 0) return I18n.t('webapp.pricing_display.free')
    return Utils.formatNumberByCountry(totalPrice, countryLanguage, precision)
  },

  formatDistance: (distance, countryLanguage) => Utils.formatNumberByCountry(distance, countryLanguage, 1),

  formatPercentage: (percentage, countryLanguage) => Utils.formatNumberByCountry(percentage, countryLanguage, 2),

  formatNumberByCountry: (number, countryLanguage, precision = 0) => {
    if (countryLanguage !== undefined && countryLanguage !== null
      && countryLanguage !== 'tl' && countryLanguage !== 'ph') {
      return parseFloat(parseFloat(number).toFixed(precision)).toLocaleString(countryLanguage)
    }
    return parseFloat(parseFloat(number).toFixed(precision)).toLocaleString()
  },

  updateBookingIdForFreshChat(bookingID) {
    if (window.fcWidget !== undefined) {
      if (window.fcWidget.isOpen() === true) {
        Utils.updateBookingId(bookingID)
      } else {
        window.fcWidget.on('widget:opened', () => {
          window.fcWidget.user.isExists((response) => {
            if (response.data === true) {
              Utils.updateBookingId(bookingID)
            }
          })
        })
      }
    }
  },

  updateBookingId(bookingId) {
    window.fcWidget.user.update({
      meta: {
        BookingId: bookingId
      }
    })
  },

  driverFirstName(fullName) {
    return _.isNil(fullName) ? '' : _.trim(fullName).split(' ')[0]
  },

  isConflictPickupTime(curBooking, nextBooking) {
    const curStartAt = new Date(curBooking.pickup_time).getTime() / 1000
    const curEndAt = curStartAt + curBooking.outOfServiceStatus.estimated_working_time
    const nextStartAt = new Date(nextBooking.pickup_time).getTime() / 1000
    const nextEndAt = nextStartAt + nextBooking.outOfServiceStatus.estimated_working_time
    return (
      curStartAt <= nextEndAt && curStartAt >= nextStartAt)
      || (nextStartAt <= curEndAt && nextStartAt >= curStartAt
      )
  },

  getBookingIdsConflictAssignDriver(bookings) {
    const bookingIds = []
    const assignedDriverBookings = _.filter(bookings, booking => booking.assignedDriver
      && !_.isInteger(booking.assignedDriver.fleet_partner_id))

    for (let i = 0; i < assignedDriverBookings.length - 1; i += 1) {
      if (!_.includes(bookingIds, assignedDriverBookings[i].id)) {
        for (let j = i + 1; j < assignedDriverBookings.length; j += 1) {
          if (assignedDriverBookings[i].assignedDriver.id === assignedDriverBookings[j].assignedDriver.id
            && Utils.isConflictPickupTime(assignedDriverBookings[i], assignedDriverBookings[j])) {
            if (!_.includes(bookingIds, assignedDriverBookings[i].id)) {
              bookingIds.push(assignedDriverBookings[i].id)
            }
            bookingIds.push(assignedDriverBookings[j].id)
          }
        }
      }
    }
    return bookingIds
  },

  showAsFleetDriver(tab, showSearchDrivers) {
    return tab === DRIVER_PREFERENCES.banned || showSearchDrivers
  },

  driverName(driver, tab, showSearchDrivers) {
    if (!_.isNull(driver.fleet_partner_id)) {
      if (Utils.showAsFleetDriver(tab, showSearchDrivers)) {
        return `${Utils.driverFirstName(driver.name)}, ${I18n.t('webapp.fleet_partner.fleet_infomation')}`
      }
      return driver.fleet_partner_name
    }
    return Utils.driverFirstName(driver.name)
  },

  orderDriversByName(drivers, tab, showSearchDrivers) {
    if(drivers?.length > 0){
      let data = _.cloneDeep(drivers)
      data.sort((d1, d2) => {
        const preName = Utils.driverName(d1, tab, showSearchDrivers)
        const nextName = Utils.driverName(d2, tab, showSearchDrivers)
        return (nextName).localeCompare(preName)
      })
      return data
  }
  },

  renderAvatar(driver, tab) {
    let icon = driver.driver_image_url
    if (!_.isNull(driver.fleet_partner_id) && tab !== DRIVER_PREFERENCES.banned) {
      icon = ICON_AVATAR_FLEET_PARTNER_ASSIGN_DRIVER
    }
    return icon
  },

  checkAssignablOnlineDrivers(inputs, callbacks) {
    const driverIDs = _.map(inputs.drivers, driver => driver.id)
    const onlineDrivers = inputs.onlineDrivers
    const vehicleTypeIDs = []
    let driverID
    _.forEach(inputs.drivers, (driver) => {
      if (!_.isUndefined(driver?.preferences_info?.vehicle_type_id)) {
        vehicleTypeIDs.push(driver.preferences_info.vehicle_type_id)
      }
    })
    const firebaseClients = []
    _.forEach(_.uniq(vehicleTypeIDs), (vehicleTypeID) => {
      const firebaseClient = firebaseInit.listen(`vehicle_types/${vehicleTypeID}/online_drivers`)
      firebaseClient.on('child_added', (data) => {
        driverID = _.toInteger(data.key)
        if (!onlineDrivers.includes(driverID) && _.includes(driverIDs, driverID)) {
          onlineDrivers.push(driverID)
          callbacks.updateOnlineDriverIDs(onlineDrivers)
        }
      })
      firebaseClient.on('child_removed', (data) => {
        driverID = _.toInteger(data.key)
        if (onlineDrivers.includes(driverID)) {
          onlineDrivers.splice(onlineDrivers.indexOf(driverID), 1)
          callbacks.updateOnlineDriverIDs(onlineDrivers)
        }
      })
      firebaseClients.push(firebaseClient)
    })
    callbacks.updateFireBase(firebaseClients)
  },

  handleClickMapMarker: (bookingID) => {
    const closetElement = $('.Batch-Map-Left .Batch-Left-Bottom .Custom-Scroll-Group')
    const config = {
      element: closetElement,
      positionScroll: closetElement.find(`.Custom-Scroll-List-${bookingID}`).position().top + closetElement.scrollTop(),
      animation: 0,
      elementFocus: false
    }
    Utils.scrollTop(config)
  },

  convertPickupTimeToMinutes(pickupTime) {
    const pickupTimeMinutes = moment(pickupTime).startOf('minutes').diff(moment().startOf('minutes'), 'minutes')
    return pickupTimeMinutes
  },

  quickChoiceSelected(isMultipleBooking = false, bookingID = 1) {
    let pickupTimeNowElement = ''
    if (isMultipleBooking) {
      pickupTimeNowElement = $(`#PickupTime-DatePicker-Introduce-now-${bookingID}-name`)
    } else {
      pickupTimeNowElement = $('#PickupTime-DatePicker-Introduce-now-name')
    }
    return pickupTimeNowElement && pickupTimeNowElement.hasClass('selected')
  },

  modifyPopupMultiple: (isZoom = false, bookingID, updateStore = true) => {
    const bookingCard = $(`#BookingWizard-${bookingID}`)
    if (updateStore) {
      $(`#BookingWizard-${bookingID}`).attr('data-bookingID', bookingID)
      window.zoomScale = isZoom
    }
    function attrCSS() {
      return {
        maxWidth: '365px',
        borderRadius: '6px',
        top: bookingCard.offset().top,
        height: bookingCard.height(),
        left: bookingCard.offset().left,
        zoom: CommonUtils.detectSmallLaptop() && isZoom ? 0.8 : 1,
      }
    }
    return bookingCard.length ? attrCSS() : {}
  },

  resizeModifyPopupMultiple: () => {
    $(window).resize(() => {
      const element = $('.Modal.visible, .PickupTime-DatePicker')
      if (element.length) {
        const bookingID = element.data('bookingid')
        const style = Utils.modifyPopupMultiple(window.zoomScale, bookingID, false)
        element.css(style)
      }
    })
  },

  triggerModifyPopupMultiple: (element, bookingID) => {
    const style = Utils.modifyPopupMultiple(true, bookingID)
    element.css(style)
  },

  detectDropdownActionsLocation: () => {
    $(document).on('click', '.dropdown-actions', (event) => {
      const classElm = $('.dropdown-actions')
      const { target } = event
      _.forEach(_.range(0, _.size(classElm)), (key) => {
        const id = $(target).closest('.dropdown-actions').attr('id')
        if (classElm[key].id !== id) {
          const elm = $(`#${classElm[key].id}`)
          if (elm.hasClass('dropdown-actions--active')) {
            elm.click()
          }
        }
      })
    })
  },
  formatTallyDatetime: (rangeDateTime, { longYearFormat = false, countryCode = '' } = {}, shareBookingMobile = false) => {
    const yearFormat = longYearFormat ? 'YYYY' : 'YY'
    const formatHour = Utils.hourFormatByCountry(countryCode)
    const bestDateTime = moment(rangeDateTime[0])
    const bestShortMonth = I18n.t(`webapp.format_short_month.month_${bestDateTime.month()}`)
    let resultRangeDate = bestDateTime.format(`${formatHour}:mm a, D-[${bestShortMonth}]-${yearFormat}`)
    if (rangeDateTime[1]) {
      const worstDateTime = moment(rangeDateTime[1])
      const worstShortMonth = I18n.t(`webapp.format_short_month.month_${worstDateTime.month()}`)
      // check two day different
      const bestStartDay = moment(rangeDateTime[0]).startOf('day').toString()
      const worstStartDay = moment(rangeDateTime[1]).startOf('day').toString()
      if (bestStartDay !== worstStartDay) {
        resultRangeDate = !shareBookingMobile ? I18n.t('webapp.tally.time_to_time', {
          best_time: bestDateTime.format(`${formatHour}:mm a, D-[${bestShortMonth}]-${yearFormat}`),
          worst_time: worstDateTime.format(`${formatHour}:mm a, D-[${worstShortMonth}]-${yearFormat}`)
        }) : `${bestDateTime.format(`${formatHour}:mm a`)} - ${worstDateTime.format(`${formatHour}:mm a`)}, ${bestDateTime.format(`D-[${bestShortMonth}]-${yearFormat}`)}`
      } else {
        const worstHoursMinute = worstDateTime.format(`${formatHour}:mm a`)
        resultRangeDate = `${bestDateTime.format(`${formatHour}:mm a`)} - ${worstHoursMinute}, ${bestDateTime.format(`D-[${bestShortMonth}]-${yearFormat}`)}`
      }
    }
    return resultRangeDate
  },
  isTallyEnable: (booking) => {
    const {
      status,
      tally_enabled: tallyEbabled,
      current_estimate_status: currentEstimateStatus,
      original_estimate_values: originalEstimateValues
    } = booking

    if (TALLY_LIST_STATUS_ACCEPT.includes(status)
      && tallyEbabled
      && currentEstimateStatus
      && !_.isEmpty(originalEstimateValues)
    ) {
      return true
    }
    return false
  },
  sleep: ms => (new Promise(resolve => (setTimeout(resolve, ms)))),
  uniqueId: () => {
    uniqueId += 1
    return uniqueId.toString()
  },
  createDebounceEventHandler(fn, delay = 300, options = {}) {
    return function debouncedHandler(event) {
      // cache target
      const target = event.target
      /* eslint-disable no-param-reassign */
      // create debounce only once
      if (!fn.cachedDebounce) {
        fn.cachedDebounce = _.debounce(() => fn(target), delay, options)
      }

      fn.cachedDebounce()
      /* eslint-enable no-param-reassign */
    }
  },
  // suppose '/credit_balances/topup?amount=1000&key=value', getQueryStringValue('amount') will return 1000
  getQueryStringValue(key) {
    const query = window.location.search.substring(1)
    const vars = query.split('&')
    for (let i = 0; i < vars.length; i += 1) {
      const pair = vars[i].split('=')
      if (decodeURIComponent(pair[0]) === key) {
        return decodeURIComponent(pair[1])
      }
    }

    return null
  },
  // change '/credit_balances/topup?amount=1000&key=value'  to '/credit_balances/topup?key=value'
  removeQueryStringFromUrl(key) {
    const query = window.location.search.substring(1)
    const vars = query.split('&')
    const pairs = []
    for (let i = 0; i < vars.length; i += 1) {
      const pair = vars[i].split('=')
      if (decodeURIComponent(pair[0]) !== key) {
        pairs.push(`${pair.join('=')}`)
      }
    }

    const qs = pairs.length ? `?${pairs.join('&')}` : ''
    if (window.history.replaceState) {
      window.history.replaceState('', document.title, `${window.location.href.split('?')[0]}${qs}`)
    }
  },
  toSnakeCase(key) {
    return key && key
      .replace(/\W+/g, ' ') // change any non-word character into space
      .split(/\B(?=[A-Z])| /) // split by word boundary ('customFields' ---> ['custom', 'Fields']) or by space
      .map(e => e.toLowerCase()) // lowercase all fragments
      .join('_') // glue all elements using '_'
  },
  generateIdPopupRebursement(popupType, bookingId = 0, vehicleId = 0, reimbursementId = 0) {
    return `popup_reimbursement_${bookingId}_${popupType}_${vehicleId}_${reimbursementId}`
  },
  handleAndKeepDataCusomReimbursement(
    preCustomReimbursements = [], currentCustomReimbursements = [], isChangeTimeType,
    isVehicleTypeChanged
  ) {
    if (_.isEmpty(currentCustomReimbursements)) {
      return []
    }

    const customReimbursements = currentCustomReimbursements.map(item => ({
      ...item,
      selected_amount: ((item.check_by_default && !item.allow_user_to_enter_amount) ? 1 : 0)
    }))

    if (_.isEmpty(preCustomReimbursements)) {
      return [
        ...customReimbursements
      ]
    }
    return customReimbursements.map((reimbursement) => {
      const preReimbursement = preCustomReimbursements.find(item => item.id === reimbursement.id)
      // for case new
      if (_.isEmpty(preReimbursement) || isChangeTimeType || isVehicleTypeChanged) {
        return reimbursement
      }

      const maxCap = Number(_.get(preReimbursement, 'customer_maximum_cap_per_location', 0))
      return {
        ...reimbursement,
        selected_amount: preReimbursement.selected_amount,
        ...(preReimbursement.user_input_amount ? {
          user_input_amount: preReimbursement.user_input_amount,
          invalid: maxCap > 0 && Number(preReimbursement.user_input_amount) > maxCap
        } : {})
      }
    })
  },
  handleExtraRequirements({
    extraRequirement, allowToUseOldData, timeType,
    foundExtraRequirement, selectedPricingID, isPreSelect
  }) {
    let selected = extraRequirement.check_by_default
    let selectedAmount = extraRequirement.check_by_default ? 1 : 0
    let selectedPricing

    if (extraRequirement.pricing_method === EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS
        && !_.isEmpty(extraRequirement.pricings)) {
      const isAllowedNone = Utils.checkNonePricing(timeType, extraRequirement?.allow_none_option_pricing_list)
      const defaultSelectedAmount = isAllowedNone ? 0 : 1
      const foundCurrentPricing = !_.isUndefined(selectedPricingID) && _.find(extraRequirement.pricings,
        iP => iP.id === selectedPricingID)
      const getDefaultPricing = () => {
        if (allowToUseOldData && foundCurrentPricing) return foundCurrentPricing
        if (isAllowedNone) return undefined
        return extraRequirement.pricings[0]
      }

      selected = allowToUseOldData
        ? (foundExtraRequirement?.selected || !!foundExtraRequirement?.selected_amount) : !isAllowedNone
      selectedAmount = allowToUseOldData ? foundExtraRequirement?.selected_amount : defaultSelectedAmount
      selectedPricing = getDefaultPricing()
    } else if (allowToUseOldData) {
      selected = foundExtraRequirement?.selected || !!foundExtraRequirement?.selected_amount
      selectedAmount = foundExtraRequirement?.selected_amount
      selectedPricing = undefined
    }

    // Pre-select L300 start
    if (isPreSelect && Number(isPreSelect) === Number(extraRequirement.id)) {
      selected = true
      selectedAmount = 1
    }
    // Pre-select L300 end

    return { selected, selectedAmount, selectedPricing }
  },
  handleBookAgainRequirements({ extraRequirement, booking, timeType }) {
    const foundExtraRequirement = Utils.findExtraRequirementTemp(booking, extraRequirement.id)

    if (!_.isUndefined(foundExtraRequirement)) {
      if (extraRequirement.pricing_method === EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS
        && !_.isEmpty(extraRequirement.pricings)) {
          const foundCurrentPricing = extraRequirement.pricings.find(
            element => element.id === foundExtraRequirement.extra_requirement_pricing_id
        )

        const { selectedPricing } = foundExtraRequirement
        if (foundCurrentPricing || selectedPricing) {
          return {
            selected: true,
            selectedAmount: foundExtraRequirement.selected_amount,
            selectedPricing: foundCurrentPricing || selectedPricing
          }
        }
      } else {
        return {
          selected: !!foundExtraRequirement.selected_amount,
          selectedAmount: foundExtraRequirement.selected_amount,
          selectedPricing: undefined
        }
      }
    }

    if (extraRequirement.pricing_method === EXTRA_REQUIREMENT_VEHICLE_TYPE_BY_OPTIONS
      && !_.isEmpty(extraRequirement.pricings)) {
      const isAllowedNone = Utils.checkNonePricing(timeType, extraRequirement?.allow_none_option_pricing_list)

      return {
        selected: !isAllowedNone,
        selectedAmount: isAllowedNone ? 0 : 1,
        selectedPricing: isAllowedNone ? undefined : extraRequirement.pricings[0]
      }
    }

    return { selected: false, selectedAmount: 0, selectedPricing: undefined }
  },
  handleAndKeepDataExtraRequirements(
    booking,
    action,
    isChangeTimeType,
    isBookAgainCase,
    isVehicleTypeChanged
  ) {
    const extraRequirements = action.extraServices?.extra_requirements?.data || []

    if (!_.isEmpty(extraRequirements)) {
      const {
        preExtraServicesAPIData, extraRequirements: bookingExtraRequirements
      } = booking.extraServices
      if (!_.isUndefined(preExtraServicesAPIData) && !isBookAgainCase) {
        const extraRequirementsToString = JSON.stringify(extraRequirements)
        const preExtraServicesAPIDataToString = JSON.stringify(preExtraServicesAPIData)
        const isEqualData = bookingExtraRequirements?.length > 0 && !isChangeTimeType
        && extraRequirementsToString === preExtraServicesAPIDataToString
        if (isEqualData) {
          return bookingExtraRequirements
        }
      }

      _.forEach(extraRequirements, (extraRequirement) => {
        const foundExtraRequirement = Utils.findExtraRequirementTemp(booking, extraRequirement.id)
        const allowToUseOldData = !isChangeTimeType && !_.isUndefined(foundExtraRequirement)
        const isBookAgainOnly = isBookAgainCase && !isChangeTimeType && !isVehicleTypeChanged
        const { selected, selectedAmount, selectedPricing } = isBookAgainOnly
          ? this.handleBookAgainRequirements({ booking, extraRequirement, timeType: action.timeType })
          : this.handleExtraRequirements({
            extraRequirement,
            allowToUseOldData,
            timeType: action.timeType,
            foundExtraRequirement,
            selectedPricingID: foundExtraRequirement?.extra_requirement_pricing_id
          })

        _.assign(extraRequirement, {
          selected,
          selected_amount: selectedAmount,
          selectedPricing
        })
      })
    }

    return extraRequirements
  },
  handleAndKeepDataCusomReimbursementForEntrieBatch(
    preCustomReimbursements = [], currentCustomReimbursements = []
  ) {
    if (_.isEmpty(currentCustomReimbursements)) {
      return []
    }

    const customReimbursements = currentCustomReimbursements.map(item => ({
      ...item,
      selected_amount: ((item.check_by_default && !item.allow_user_to_enter_amount) ? 1 : 0)
    }))

    if (_.isEmpty(preCustomReimbursements)) {
      return [
        ...customReimbursements
      ]
    }
    return customReimbursements.map((reimbursement) => {
      const preReimbursement = preCustomReimbursements.find(item => item.id === reimbursement.id)

      const maxCap = Number(_.get(preReimbursement, 'customer_maximum_cap_per_location', 0))

      return {
        ...reimbursement,
        selected_amount: _.get(preReimbursement, 'selected_amount', 0),
        ...(preReimbursement && preReimbursement.user_input_amount ? {
          user_input_amount: preReimbursement.user_input_amount,
          invalid: maxCap > 0 && Number(_.get(preReimbursement, 'user_input_amount', 0)) > maxCap
        } : { user_input_amount: '' })
      }
    })
  },
  keepDataStaticReimbursement(
    preCompanySettings, curCompanySettings = {}, preVehicleTypeSettings = {}, isChangeTimeType = false,
    isVehicleTypeChanged = false
  ) {
    const lockToggle = {
      lock_check_tolls_fees: curCompanySettings.lock_check_tolls_fees,
      lock_check_parking_fees: curCompanySettings.lock_check_parking_fees,
      lock_check_waiting_time_fees: curCompanySettings.lock_check_waiting_time_fees,
    }

    if (_.isEmpty(preCompanySettings) || isChangeTimeType || isVehicleTypeChanged) {
      return {
        allow_tolls_fees: curCompanySettings.allow_tolls_fees,
        allow_parking_fees: curCompanySettings.allow_parking_fees,
        allow_waiting_time_fees: curCompanySettings.allow_waiting_time_fees,
        ...lockToggle
      }
    }

    // keep data when user back step 1 and to step 2 again
    return {
      allow_tolls_fees: (
        preVehicleTypeSettings.enable_tolls
          ? preCompanySettings.allow_tolls_fees : curCompanySettings.allow_tolls_fees
      ),
      allow_parking_fees: (
        preVehicleTypeSettings.enable_parking
          ? preCompanySettings.allow_parking_fees : curCompanySettings.allow_parking_fees
      ),
      allow_waiting_time_fees: (
        preVehicleTypeSettings.enable_waiting_time
          ? preCompanySettings.allow_waiting_time_fees : curCompanySettings.allow_waiting_time_fees
      ),
      ...lockToggle
    }
  },
  hasPreExtraServiceData(preExtraServices) {
    if (!_.isEmpty(preExtraServices)) {
      return (
        !_.isEmpty(preExtraServices.vehicleTypeSettings)
        || !_.isEmpty(preExtraServices.customReimbursements)
        || !_.isEmpty(preExtraServices.extraRequirements)
      )
    }
    return false
  },
  getPaymentMethodForNonBp: (booking) => {
    if (booking.credit_amount > 0 && booking.discount_amount > 0) return 'paid_by_credit_and_code'
    if (booking.credit_amount > 0) return 'paid_by_credit'
    if (booking.discount_amount > 0) return 'paid_by_code'
    return 'pay_by_cash'
  },
  getPaymentMethodForNonBpMultiple: (bookings, totalFees) => {
    if (totalFees > 0) return 'pay_by_cash'
    const listOfPaymentMethod = _.map(bookings, Utils.getPaymentMethodForNonBp)
    const result = []
    if (_.includes(listOfPaymentMethod, 'paid_by_credit')) result.push('paid_by_credit')
    if (_.includes(listOfPaymentMethod, 'paid_by_code')) result.push('paid_by_code')
    if (_.includes(listOfPaymentMethod, 'paid_by_credit_and_code') || (result.length > 1)) return 'paid_by_credit_and_code'
    return result[0]
  },
  findExtraRequirementTemp: (booking, extraRequirementId) => {
    let result = null
    if (_.isUndefined(booking.booking_extra_requirements)) {
      result = _.find(booking.extraServices.extraRequirements, { id: extraRequirementId })
    } else result = _.find(booking.booking_extra_requirements, { extra_requirement_id: extraRequirementId })
    return result
  },
  buildParamToFTL: ({
    countryCode, areaId, companyId
  }) => {
    const accessToken = window.localStorage.getItem('access_token') || ''
    const language = I18n.language
    const paramToken = accessToken ? `token=${accessToken}` : 'isLogout=true'
    return `${paramToken}&countrycode=${countryCode}&language=${language}&areaId=${areaId}&companyId=${companyId}`
  },
  sortDriversByName: drivers => (drivers ? _.sortBy(drivers, (driver) => {
    if (driver.fleet_partner_name) {
      return driver.fleet_partner_name.toLowerCase()
    }
    return driver.name.toLowerCase()
  }) : []),
  groupDriversByIsRequirementMet(driversData) {
    const driverGrouped = _.groupBy(driversData, 'is_requirement_met')
    const metRequirementDrivers = this.sortDriversByName(driverGrouped.true)
    const notMetRequirementDrivers = this.sortDriversByName(driverGrouped.false)

    return [...metRequirementDrivers, ...notMetRequirementDrivers]
  },
  getOSAUrl: (countryCode, locale, index) => {
    let url = '#'
    const localeEN = 'en'
    const isWebapp = process.env.NODE_ENV === 'production'
    const urlID = isWebapp ? URL_WEBAPP_ID : URL_STG_ID
    const urlPH = isWebapp ? URL_WEBAPP_PH : URL_STG_PH
    const urlTH = isWebapp ? URL_WEBAPP_TH : URL_STG_TH
    switch (countryCode) {
      case COUNTRY_ID:
        if (index !== 0) {
          if (localeEN === locale) {
            url = `${urlID}${locale}/smart-trucking/service-area/?hide_address_checker=true&is_destination=true`
          } else {
            url = `${urlID}ekspedisi-pintar/area-layanan/?hide_address_checker=true&is_destination=true`
          }
        } else if (index === 0) {
          if (localeEN === locale) {
            url = `${urlID}${locale}/smart-trucking/service-area/?hide_address_checker=true`
          } else {
            url = `${urlID}ekspedisi-pintar/area-layanan/?hide_address_checker=true`
          }
        }
        break
      case COUNTRY_PH:
        if (index !== 0) {
          url = `${urlPH}trucking-solutions/service-area/?hide_address_checker=true&is_destination=true`
        } else {
          url = `${urlPH}trucking-solutions/service-area/?hide_address_checker=true`
        }
        break
      case COUNTRY_TH:
        if (index !== 0) {
          url = `${urlTH}${locale}/delivery/service-area/?hide_address_checker=true&is_destination=true`
        } else {
          url = `${urlTH}${locale}/delivery/service-area/?hide_address_checker=true`
        }
        break
      default:
        if (index !== 0) {
          if (localeEN === locale) {
            url = `${urlID}${locale}/smart-trucking/service-area/?hide_address_checker=true&is_destination=true`
          } else {
            url = `${urlID}ekspedisi-pintar/area-layanan/?hide_address_checker=true&is_destination=true`
          }
        } else if (index === 0) {
          if (localeEN === locale) {
            url = `${urlID}${locale}/smart-trucking/service-area/?hide_address_checker=true`
          } else {
            url = `${urlID}ekspedisi-pintar/area-layanan/?hide_address_checker=true`
          }
        }
        break
    }
    return url
  },
  scrollToElement: (element) => {
    $('html, body').animate({
      scrollTop: element.offset().top - 100
    }, 0)
  },
  format12Hour: (time, countryCode) => {
    if (countryCode.toUpperCase() !== 'PH') {
      return time
    }
    if (!time) return time
    const temp = time.split(':')
    const amOrPm = +temp[0] < 12 ? 'AM' : 'PM'
    temp[0] = +temp[0] % 12 || 12
    return temp.join(':') + amOrPm
  },
  hideOverlayStep2: (isHide = true) => {
    if (isHide) {
      $('.Popover-Item-Drivers').addClass('hide-overlay')
    } else {
      $('.Popover-Item-Drivers').removeClass('hide-overlay')
    }
  },
  hideCloseBtn: (isHide = true) => {
    if (isHide) {
      $('#close-popup-btn').hide()
    } else {
      $('#close-popup-btn').show()
    }
  },
  buildCustomReimbursementsOfBookAgain: (
    customReimbursementsOfBookAgain,
    reimbursementOfBookAgain,
    reimbursement,
    validReimbursement
  ) => {
    let selectedAmount = 0
    let userInputAmount = ''
    if (validReimbursement) {
      const checkAllowEnterAmountWithZero = reimbursementOfBookAgain.allow_user_to_enter_amount
        && !reimbursementOfBookAgain.amount && !reimbursementOfBookAgain.user_input_amount
      if (!checkAllowEnterAmountWithZero) {
        selectedAmount = reimbursementOfBookAgain.selected_amount || reimbursementOfBookAgain.unit
        userInputAmount = reimbursementOfBookAgain.fees || reimbursementOfBookAgain.user_input_amount
        if (reimbursement.unit === 1) {
          // for Pre-set Amount to Allow driver input amount
          selectedAmount = 1
        } else if (reimbursement.unit < reimbursementOfBookAgain.unit) {
          // for the new setting unit < Customer select unit
          selectedAmount = reimbursement.unit
        }
      }
    }
    return {
      oldSelectedAmount: selectedAmount,
      oldUserInputAmount: userInputAmount,
    }
  },
  isOverTimeReimburse(booking = {}, extraInfos = {}) {
    const companyId = booking.company_id
    const completedAt = booking.completed_at
    const optionTime = extraInfos.paid_reimbursement_confirmation_timeout_after
    const end = companyId ? moment.unix(completedAt).add(1, 'day') : moment.unix(completedAt).add(optionTime, 'minutes')
    const now = moment()
    const duration = end.diff(now, 'seconds')
    return duration < 0
  },
  getCompanyAccount(account) {
    if (account.last_login_employ_id && account.employs) {
      return account.employs.find(item => item.company_id === account.last_login_employ_id)
    }
    return {}
  },
  compressImage(img, originWidth, originHeight, qualityImage = 0.8) {
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      const canvas = document.createElement('canvas')
      const { width, height } = Utils.getResolutionDPI(originWidth, originHeight)
      canvas.width = width
      canvas.height = height
      const ctx = canvas.getContext('2d')
      ctx.drawImage(img, 0, 0, originWidth, originHeight, 0, 0, canvas.width, canvas.height)
      return canvas.toDataURL('image/jpeg', qualityImage)
    }
    return null
  },
  getQualityImage(size) {
    let qualityImage
    if (_.inRange(size, 200, 1024)) {
      qualityImage = 0.5
    } else if (_.inRange(size, 1024 + 1, 1024 * 2)) {
      qualityImage = 0.4
    } else if (_.inRange(size, 1024 * 2 + 1, 1024 * 3)) {
      qualityImage = 0.3
    } else if (_.inRange(size, 1024 * 3 + 1, 1024 * 4)) {
      qualityImage = 0.2
    } else {
      qualityImage = 0.15
    }
    return qualityImage
  },
  getResolutionDPI(originWidth, originHeight) {
    let width
    let height
    let scaleDPI
    if (originWidth >= originHeight && originWidth > 1024) {
      scaleDPI = originWidth / 1024
      width = parseInt(originWidth / scaleDPI, 10)
      height = parseInt(originHeight / scaleDPI, 10)
    } else if (originHeight >= originWidth && originHeight > 1024) {
      scaleDPI = originHeight / 1024
      width = parseInt(originWidth / scaleDPI, 10)
      height = parseInt(originHeight / scaleDPI, 10)
    } else {
      width = originWidth
      height = originHeight
    }

    return {
      width,
      height,
    }
  },
  dataURLtoFile(dataurl, filename) {
    const arr = dataurl.split(',')
    const mime = arr[0].match(/:(.*?);/)[1]
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while (n) {
      n -= 1
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], filename, { type: mime })
  },
  isValidPassword(newPassword, confirmNewPassword) {
    if (newPassword.length > 0 && newPassword.length < 6) {
      this.showToastrMessage('info', I18n.t('validations.minlength_password', { number: 6 }))
      return false
    }
    if (newPassword !== confirmNewPassword) {
      this.showToastrMessage('info', I18n.t('error.messages.not_match_password'))
      return false
    }
    return true
  },
  delay(timeDelay) {
    return new Promise((resolve) => {
      setTimeout(resolve.bind(null), timeDelay)
    })
  },
  openFreshChat: ({
    bookingId, countryCode, replyText
  }) => {
    const filledText = window.localStorage.getItem(`filltext_freshchat_${bookingId}`)
    const freshChatChannel = {
      PH: FRESH_CHAT_CHANNEL_PH,
      TH: FRESH_CHAT_CHANNEL_TH,
    }
    const name = freshChatChannel[countryCode] || FRESH_CHAT_CHANNEL
    if (window.fcWidget.isOpen() !== true) {
      if (_.isNull(filledText)) {
        window.localStorage.setItem(`filltext_freshchat_${bookingId}`, 'true')
        window.fcWidget.open({ name, replyText })
        $('#app-conversation-editor').attr('placeholder', '')
      } else {
        window.fcWidget.open({ name })
      }
    }
  },
  forEachWithDelay: (array, callback, delay, callbackAfterCompleted) => {
    let index = 0
    const interval = setInterval(() => {
      callback(array[index], index)
      // eslint-disable-next-line no-plusplus
      if (++index === array.length) {
        clearInterval(interval)
        callbackAfterCompleted()
      }
    }, delay)
  },
  checkInWorkingTimeRange: ({ checkTime, isInTimeRange = false, extraInfos }) => {
    const { ltl_booking_start_time: startTime, ltl_booking_end_time: endTime } = extraInfos || {}
    const selectedDate = moment(checkTime).date()
    const currentDate = moment().date()
    const selectedHour = moment(checkTime).hour()
    const selectedMinute = moment(checkTime).minute()
    const endHours = endTime ? parseInt(endTime.slice(0, 2), 0) : null
    const endMinute = endTime ? parseInt(endTime.slice(3), 0) : null
    const startHours = startTime ? parseInt(startTime.slice(0, 2), 0) : null
    const startMinute = startTime ? parseInt(startTime.slice(3), 0) : null
    if (currentDate === selectedDate) {
      if (isInTimeRange) {
        if ((selectedHour < endHours && selectedHour > startHours)
          || (selectedHour === endHours && selectedMinute < endMinute)
          || (selectedHour === startHours && selectedMinute > startMinute)) {
          return true
        }
      } else if ((selectedHour > endHours || (selectedHour === endHours && selectedMinute > endMinute))) {
        return true
      }
    }
    return false
  },
  renderCalendarNotify: ({
    pickupTimeLTL,
    extraInfos,
    countryCode,
    isMyBooking = false,
    hasDriver = false
  }) => {
    const { ltl_booking_start_time: startTime, ltl_booking_end_time: endTime } = extraInfos || {}
    const month = I18n.t(`webapp.format_short_month.month_${moment(pickupTimeLTL).month()}`)
    const formatHour = Utils.hourFormatByCountry(countryCode)
    const pickupDate = moment(pickupTimeLTL).format(`D-[${month}]-YY`)
    const startTimeFormat = Utils.format12Hour(startTime, countryCode)
    const endTimeFormat = Utils.format12Hour(endTime, countryCode)
    /*
      case 1: current time < market open time ==> Open to [qualified cut off time + buffer]
      case 2: current time >=  market open time ==> Now to [qualified cut off time + buffer]
      case 3: (current time >=  market open time AND qualified cut off time + buffer >= market closing time)
        OR no cut off time in the same day  ==> Now to market closing time
      case 4: current time > market closing time ==> Open to Close and select next day
    */
    let content = ''
    if (isMyBooking) {
      content = `${startTimeFormat}, ${pickupDate}`
    } else {
      content = I18n.t('webapp.ltl.pickup_window_from_to', {
        startTime: startTimeFormat,
        endTime: endTimeFormat,
      })
    }
    const selectedTime = moment(pickupTimeLTL) || moment()
    const isSameDay = selectedTime.isSame(new Date(), 'day')
    const currentTime = moment()
    const splitStartTime = startTime.split(':')
    const openTime = moment().set('hour', splitStartTime[0]).set('minute', splitStartTime[1])
    const splitEndTime = endTime.split(':')
    const closingTime = moment().set('hour', splitEndTime[0]).set('minute', splitEndTime[1])
    const isCurrentLessThanOpen = currentTime.diff(openTime) < 0
    const isSelectedGreaterThanClose = selectedTime.diff(closingTime) > 0
    if (isSameDay && !isCurrentLessThanOpen) {
      const isInWorkingTime = Utils.checkInWorkingTimeRange({
        checkTime: selectedTime, isInTimeRange: true, extraInfos
      })
      if (isInWorkingTime) {
        if (isMyBooking) {
          content = I18n.t('webapp.ltl.pickup_now_to', {
            time: selectedTime.format(`${formatHour}:mm`),
            date: pickupDate,
          })
        } else {
          content = I18n.t('webapp.ltl.pickup_window_now', {
            endTime: selectedTime.format(`${formatHour}:mm`)
          })
        }
      }
      /*
        This condition for case getPickupLTL api returns is_next_day = true
        selectedTime's hour and minute will be the same endTime from setting (case no cut off time)
        and case selected date time greater than closing time but current time
        in range of Market time
      */
      if (selectedTime.format(`${formatHour}:mm`) === endTimeFormat || isSelectedGreaterThanClose) {
        if (isMyBooking) {
          content = I18n.t('webapp.ltl.pickup_now_to', {
            time: endTimeFormat,
            date: pickupDate,
          })
        } else {
          content = I18n.t('webapp.ltl.pickup_window_now', {
            endTime: endTimeFormat
          })
        }
      }
    }
    if (isSameDay && isCurrentLessThanOpen) {
      if (isMyBooking) {
        content = I18n.t('webapp.booking.pickup_time_ltl', {
          start_time: startTimeFormat,
          end_time: selectedTime.format(`${formatHour}:mm`),
          pickup_date: pickupDate
        })
      } else {
        content = I18n.t('webapp.ltl.pickup_window_from_to', {
          startTime: startTimeFormat,
          endTime: selectedTime.format(`${formatHour}:mm`)
        })
      }
    }
    if (isSameDay && hasDriver) {
      content = `${selectedTime.format('HH:mm')}, ${selectedTime.format('DD-MMM-YY')}`
    }
    return content
  },
  handleGoToDetailsFromPayment: (id, isClickGoToDetails = false) => {
    const type = getParamFromURL('type')
    const isMajor = getParamFromURL('is_major')
    const isCebVAPayment = +type === CUSTOMER_QUOTE_PAYMENT
    let path = `/bookings/${id}`
    if (isMajor === 'false') {
      window.localStorage.setItem('minor_booking_id', id)
      path = '/bookings'
    }
    if (isMajor === 'true') {
      // The is_major_changes flag represents for waiting driver accept changes
      // It will be removed after driver accept, decline or timeout.
      window.localStorage.setItem('is_major_changes', id)
      // Enable this flag for showing the driver cancel modal...
      // ... it will be remove after navigating to booking detail
      window.localStorage.setItem('is_driver_not_available', id)
    }
    if (isCebVAPayment && isClickGoToDetails) path = `${path}?is_ceb_va_payment=${isCebVAPayment}`
    showLoading()
    window.location.href = path
  },
  isAddPaymentAttr: (bankTransfer, status) => !_.isEmpty(bankTransfer) && !isCustomerEditBooking()
    && SHOW_PAYMENT_METHOD_LIST.includes(status)
}

export { Utils as default }
