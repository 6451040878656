import { Suspense, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import RouteList from 'routers'
import paths from 'routers/paths'
import firebaseInit from 'utils/firebaseInit'
import CustomerAPI from 'api/customers'
import areaApi from 'api/Area'
import * as utilsCommon from 'utils/booking/common'
import * as initApp from 'utils/initApp'
import StorageKeys from 'constants/storage-keys'
import { checkToShowLoadingForNewBooking } from './utils/new_booking/common'
import { Utils } from 'utils/Utils'
import {
  handleListenMessage,
  handleInitToken,
  setAccessToken,
  handleConnectCrossStorage,
  parseJwt,
} from './utils/crossStorage'
import * as areaActionCreators from 'store/actions/common/areaActionCreators'
import * as customerActionCreators from 'store/actions/common/customerActionCreators'

import NavBarContainer from './containers/NavBarContainer'
import FreshChat from 'utils/FreshChat'
import { initBasicModal } from 'assets/javascripts/webapp-v2/common/basic-modal'
import LoadingModal from 'components/common/loading_modal/LoadingModal'
import { LCL_CUSTOMER_URL } from 'constants/appConstants'

const App = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const currentCustomer = useSelector((state) => state.currentCustomer)
  const authenticationToken = window.localStorage.getItem(StorageKeys.ACCESS_TOKEN) || ''
  const lastLoginEmployId = currentCustomer.last_login_employ_id || null

  const areaIdUrl = utilsCommon.getParamFromURL('area_id')
  const countryCode = utilsCommon.getParamFromURL('country_code')
  if (areaIdUrl) window.localStorage.setItem(StorageKeys.AREA_ID, areaIdUrl)
  const areaIdLocal = window.localStorage.getItem(StorageKeys.AREA_ID)
  const extraInfos = useSelector((state) => state.extraInfos)
  const finalAreaId = Number(extraInfos?.id || areaIdUrl || areaIdLocal)

  const location = window.location
  const isReady =
    (extraInfos?.id && (!authenticationToken || currentCustomer.id)) || location.pathname === paths.SELECT_AREA

  useEffect(() => {
    // check to sync language to session
    initApp.syncLanguageToSession()

    initBasicModal()

    const urlParams = new URLSearchParams(location.search)
    const isLoggedOut = urlParams.get('log_out_success')
    if (isLoggedOut) {
      Utils.showToastrMessage('info', t('devise.sessions.signed_out'))
      setAccessToken('')
      window.history.replaceState({}, document.title, '/')
    }
    if (authenticationToken) {
      // sync jwt token to FTL token
      handleInitToken()
    } else {
      handleConnectCrossStorage()
    }

    // todo: we need to rehandle when use router dom
    // only for new booking
    checkToShowLoadingForNewBooking()

    window.addEventListener('message', handleListenMessage)
    return () => {
      window.removeEventListener('message', handleListenMessage)
    }
  }, [])

  useEffect(() => {
    if (currentCustomer?.id) {
      firebaseInit.load()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentCustomer?.id])

  useEffect(() => {
    if (finalAreaId && authenticationToken) {
      dispatch(customerActionCreators.getCurrentCustomer(authenticationToken, finalAreaId))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticationToken, finalAreaId])

  useEffect(() => {
    const getCustomerSetting = () => {
      dispatch(
        customerActionCreators.getCustomerSettings(authenticationToken, finalAreaId, () => {}, extraInfos.country_code)
      )
    }
    if (!finalAreaId) return
    if (extraInfos?.country_code && !authenticationToken) getCustomerSetting()
    if (extraInfos?.country_code && authenticationToken && finalAreaId) {
      getCustomerSetting()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalAreaId, extraInfos.country_code, currentCustomer.id, !!lastLoginEmployId])

  useEffect(() => {
    if (!finalAreaId) {
      dispatch(areaActionCreators.getCurrentAreaCustomer(countryCode))
    }
    if (authenticationToken && !currentCustomer.id) return
    if (finalAreaId) {
      dispatch(areaActionCreators.getAreaInfo(finalAreaId))
      // sync area_id for session FTL
      areaApi.setNewCurrentAreaSession(finalAreaId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finalAreaId, currentCustomer.last_login_employ_id, currentCustomer.id])

  useEffect(() => {
    const lclBookingId = utilsCommon.getParamFromURL('spil_number')
    const decodeToken = parseJwt(currentCustomer.authentication_token)
    const companyIdFromToken = +decodeToken?.company_id || 0
    const companyIdNow = +currentCustomer.last_login_employ_id || 0
    if (!lclBookingId) return
    const getLclInfo = async (lclBookingId) => {
      const {
        data: { data },
      } = await CustomerAPI.getInfoLclBooking(lclBookingId)
      window.location = `${LCL_CUSTOMER_URL}/tracking/${data.id}`
    }
    if (!currentCustomer.id && window.showSigninModal) {
      window.showSigninModal()
    } else if (currentCustomer.id && companyIdFromToken === companyIdNow) {
      getLclInfo(lclBookingId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currentCustomer.id,
    currentCustomer.last_login_employ_id,
    currentCustomer.authentication_token,
    window.showSigninModal,
  ])

  //todo: init showloading, we need hide loading for each pages
  return (
    <div className="app">
      <Suspense fallback={<LoadingModal />}>
        <BrowserRouter>
          {isReady && (
            <>
              <FreshChat />
              <NavBarContainer />
              <RouteList />
            </>
          )}
          <LoadingModal />
        </BrowserRouter>
      </Suspense>
    </div>
  )
}

export default App
