import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { STATUS_SAVE_AS_DRAFT } from 'constants/bookingConstants'
import _ from 'lodash'

const initialState: any = {}

const selectedBatchSlice = createSlice({
  name: 'selectedBatch',
  initialState,
  reducers: {
    updateSelectedBatch: (state, action: PayloadAction<any>) => action.payload.selectedBatch,
    updateBatchBookings: (state, action: PayloadAction<any>) => {
      const currentBookings = state.bookings
      let newBookings = _.cloneDeep(state.bookings)
      action.payload.bookings.forEach((newBooking: any) => {
        const index = _.findIndex(
          currentBookings,
          (booking: any) => booking.batch_tracking_token === newBooking.batch_tracking_token
        )
        if (index !== -1) {
          newBookings[index] = newBooking
        }
      })
      newBookings = _.sortBy(newBookings, [
        function (a) {
          state = a.status === STATUS_SAVE_AS_DRAFT ? Number.POSITIVE_INFINITY : a.id
        },
      ])
      state.bookings = newBookings
    },
    updateBatchBookingAttrs: (state, action: PayloadAction<any>) => {
      const currentBatch = { ...state }
      const bookings = [...currentBatch.bookings]
      const booking = _.find(bookings, (b) => b.batch_tracking_token === action.payload.attrs.batch_tracking_token)
      if (!_.isUndefined(booking)) {
        _.assign(booking, action.payload.attrs)
        if (_.isNumber(action.payload.attrs.location_has_signature)) {
          const locations = booking.locations || booking.locations_attributes
          _.forEach(locations, (loc, index) => {
            if (index <= action.payload.attrs.location_has_signature) {
              _.assign(loc, { has_signature: true })
            }
          })
          _.assign(booking, { locations })
        }
      }
      state = currentBatch
      return state
    },
    updateBatchChatBookingAttrs: (state, action: PayloadAction<any>) => {
      const batch = { ...state }
      const { batch_bookings: bookings } = batch
      const booking = _.find(bookings, (b) => b.id === action.payload.attrs.id)
      if (!_.isUndefined(booking)) {
        _.assign(booking, action.payload.attrs)
      } else if (batch.id === action.payload.attrs.batch_id) {
        // new booking added from queue
        bookings.push(action.payload.attrs)
      }
      state = batch
      return state
    },
    updateCurrentBatchChatBooking: (state, action: PayloadAction<any>) => {
      state.currentChatBookingID = action.payload.bookingID
    },
    updateActiveBooking: (state, action: PayloadAction<any>) => {
      const batch = { ...state }
      const bookingIndex = _.findIndex(batch.bookings, (booking: any) => booking.id === action.payload.booking.id)
      if (bookingIndex !== -1 && batch.bookings[bookingIndex].active_tracking) {
        _.assign(batch.bookings[bookingIndex], { active_tracking: false })
      } else {
        const currentActivedBooking = _.findIndex(batch.bookings, (booking: any) => booking.active_tracking)
        if (currentActivedBooking !== -1) {
          batch.bookings[currentActivedBooking].active_tracking = false
        }
        _.assign(batch.bookings[bookingIndex], { active_tracking: true })
      }
      state = batch
      return state
    },
    updateAttrListBooking:  (state, action: PayloadAction<any>) => {
      const currentBatch = { ...state }
      const bookings = [...currentBatch.bookings]
      let selectedBookings = []
      selectedBookings = bookings.map((booking) => {
        if (_.includes(action.payload.bookingIds, _.toInteger(booking.id))) {
          return _.assign({}, booking, action.payload.attrs)
        }
        return booking
      })
      return {
        ...currentBatch,
        bookings: selectedBookings
      }
    }
  },
  extraReducers: () => {},
})

export const selectedBatchActionsCreators = selectedBatchSlice.actions

export default selectedBatchSlice
