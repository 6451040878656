import { API_GATEWAY } from 'constants/appConstants'
import apiClient from 'services/axiosApp'

const SettingAPI = {
  getCustomerSettings: async (authenticationToken, query, callback, countryCode) => {
    try {
      const res = await apiClient.get('/api/v3/settings/customers', {
        params: query, headers: {
          'Country-Code': countryCode,
        }
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  updateAutomaticSettings: async (authenticationToken, params, callback) => {
    try {
      const res = await apiClient.put('api/v3/settings/automatic_booking_frequency', params, {
        headers: {
          'Device-Type': 'Web'
        }
      })
      return callback(res)
    } catch (err) {
      throw new Error(err)
    }
  },
  getFrontPageLink: (countryCode) =>
    apiClient.get(`${API_GATEWAY}/v1/setting/system-settings?keys=first_box_url&keys=second_box_url&keys=third_box_url`, {
      headers: {
        'countrycode': countryCode
      }
    }),
  getSettingsCustomerByArea: (areaId) =>
    apiClient.get(`/api/v3/settings/customers_by_area?area_id=${areaId}&section_type=pickup_change`)
}

export default SettingAPI
