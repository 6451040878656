import _ from 'lodash'
import { BOOKING_SCOPE_FAVORITED, FULL_DAY, LOCATION_STATUSES, SCHEDULE, CUSTOM_PRICE, ONE_MIN_TO_MS, TIME_TYPE_TEXT, PAYMENT_LIST_COUNTRY, PAY_BY_CASH, PAID_BY_CREDIT_AND_CASH, SHOW_PAYMENT_METHOD_LIST } from 'constants/bookingConstants'
import { POPUP_TYPE_FULL_DAY_ETA, POPUP_TYPE_NON_FULL_DAY_ETA } from 'constants/common/popupConstants'
import { FULL_DAY_ICON, LONG_HAUL_BOOKING_ICON, NOW_ICON, SCHEDULE_BOOKING_ICON_YELLOW } from 'constants/imageConstants'
import { NOW, PAY_BY_ONLINE_PAYMENT_LIST, QUICK_CHOICE } from 'constants/newBookingConstants'
import I18n from 'i18n/i18n'
import { Utils } from '../Utils'
import moment from 'moment'

const checkCustomReimbursementsChanged = (extraServices = {}, newExtraServices = {}) => {
  let { customReimbursements = [] } = extraServices

  let { customReimbursements: newCustomReimbursements = [] } = newExtraServices

  customReimbursements = customReimbursements
    .filter(({ selected_amount: selectedAmount }) => selectedAmount >= 1)
    .map((item) => ({
      id: item.id,
      selected_amount: item.selected_amount,
      amount: item.amount,
      user_input_amount: item.user_input_amount,
    }))

  newCustomReimbursements = newCustomReimbursements
    .filter(({ selected_amount: selectedAmount }) => selectedAmount >= 1)
    .map((item) => ({
      id: item.id,
      selected_amount: item.selected_amount,
      amount: item.amount,
      user_input_amount: item.user_input_amount,
    }))

  return !_.isEqual(customReimbursements, newCustomReimbursements)
}

/* eslint-disable import/prefer-default-export */
export const detectBookingDataChanges = (oldValue, newValue) => {
  let { locations } = oldValue
  const { fullDayPricing = {} } = oldValue.extraServices || {}
  let { extraRequirements = [], extraRequirementsNegativePosition = [] } = oldValue.extraServices || {}

  let { locations: newLocations } = newValue
  const { fullDayPricing: newFullDayPricing = {} } = newValue.extraServices
  let {
    extraRequirements: newExtraRequirements = [],
    // for full day booking such as "Overtime Hours",...
    extraRequirementsNegativePosition: newExtraRequirementsNegativePosition = [],
  } = newValue.extraServices

  const checkingFields = ['codPodFees']
  const serviceVehicleTimeTypeFields = ['serviceTypeID', 'vehicleTypeID', 'timeType']
  const oldFieldValues = _.pick(oldValue, checkingFields)
  const newFieldValues = _.pick(newValue, checkingFields)

  locations = locations.filter(({ lat, lng }) => lat && lng)
  newLocations = newLocations.filter(({ lat, lng }) => lat && lng)
  /* eslint-disable no-param-reassign */
  const extraRequirementLocations = locations
    .reduce((initial, { extra_requirement_locations: _extraRequirementLocations }) => {
      _extraRequirementLocations = _extraRequirementLocations || []

      return [...initial, ..._extraRequirementLocations]
    }, [])
    .filter(({ selected_amount: selectedAmount }) => selectedAmount)
  const newExtraRequirementLocations = newLocations
    .reduce((initial, { extra_requirement_locations: _extraRequirementLocations }) => {
      _extraRequirementLocations = _extraRequirementLocations || []

      return [...initial, ..._extraRequirementLocations]
    }, [])
    .filter(({ selected_amount: selectedAmount }) => selectedAmount)
  /* eslint-enable no-param-reassign */
  const extraRequirementLocationsChanged = !_.isEqual(extraRequirementLocations, newExtraRequirementLocations)

  const isCustomReimbursementsChanged = checkCustomReimbursementsChanged(oldValue.extraServices, newValue.extraServices)
  /**
   * location change detection base on change of lat, lng, cod_invoice_fees
   * Noted: cod_invoice_fees is changed when changing COD
   */
  // eslint-disable-next-line camelcase
  locations = locations.map(({ lat, lng }) => ({ lat, lng }))

  // eslint-disable-next-line camelcase
  newLocations = newLocations.map(({ lat, lng }) => ({ lat, lng }))

  // Only check non-free extra services
  extraRequirements = extraRequirements
    // eslint-disable-next-line camelcase
    .map(({ selected_amount, id, selectedPricing }) => ({ selected_amount, id, selectedPricing }))
  newExtraRequirements = newExtraRequirements
    // eslint-disable-next-line camelcase
    .map(({ selected_amount, id, selectedPricing }) => ({ selected_amount, id, selectedPricing }))

  const isLocationChanged = !_.isEqual(locations, newLocations) || extraRequirementLocationsChanged

  const isPickupTimeChanged = !_.isEqual(_.pick(oldValue, ['pickupTime']), _.pick(newValue, ['pickupTime']))
  const isServiceVehicleTimeTypeChanged = !_.isEqual(
    _.pick(oldValue, serviceVehicleTimeTypeFields),
    _.pick(newValue, serviceVehicleTimeTypeFields)
  )
  const isOtherChanged =
    !_.isEqual(oldFieldValues, newFieldValues) || !_.isEqual(extraRequirements, newExtraRequirements)

  // extraRequirementsNegativePosition
  const fullDayPricingChanged = +fullDayPricing.unit_price > 0 && !_.isEqual(fullDayPricing, newFullDayPricing)
  extraRequirementsNegativePosition = extraRequirementsNegativePosition
    // eslint-disable-next-line camelcase
    .map(({ selected_amount }) => ({ selected_amount }))
  newExtraRequirementsNegativePosition = newExtraRequirementsNegativePosition
    // eslint-disable-next-line camelcase
    .map(({ selected_amount }) => ({ selected_amount }))

  const fullDayOptionChanged = !_.isEqual(extraRequirementsNegativePosition, newExtraRequirementsNegativePosition)
  const isTransitTimeChanged = fullDayPricingChanged || fullDayOptionChanged

  return {
    isLocationChanged,
    isOtherChanged,
    isTransitTimeChanged,
    isServiceVehicleTimeTypeChanged,
    isPickupTimeChanged,
    isCustomReimbursementsChanged,
  }
}

export const getETAPopupTypeByTimeType = (timeType) =>
  timeType === FULL_DAY ? POPUP_TYPE_FULL_DAY_ETA : POPUP_TYPE_NON_FULL_DAY_ETA

export const isWaitingForDriverReviewChanges = (reviewChangesTimeoutAt, driverAcceptChangedRequest) => {
  const reviewTimeNotExpired = +reviewChangesTimeoutAt > new Date().getTime() / 1000

  return reviewTimeNotExpired && !_.isBoolean(driverAcceptChangedRequest)
}

/**
 * Check same lat, lng between 2 locations
 * @param {Number} firstLat
 * @param {Number} secondLat
 * @param {Number} firstLng
 * @param {Number} secondLng
 * @returns Boolean
 */
export const checkIsSameLocation = (firstLat, secondLat, firstLng, secondLng) =>
  firstLat === secondLat && firstLng === secondLng

/**
 * Calculate valid address and add it to new list
 * @param {Array} locations
 * @returns Object with number of location valid and new valid location list
 */
export const calculateAndFormatLocationList = (locations) => {
  let locationCount = 0
  const formatLocationList = []
  _.map(locations, (location) => {
    const { lat, lng, name, id } = location
    let newLocation = { ...location }
    if (_.isEmpty(id)) {
      newLocation = {
        ...location,
        id: location.temp_id,
      }
    }
    if (!_.isUndefined(lat) && !_.isUndefined(lng) && name) {
      locationCount += 1
      formatLocationList.push(newLocation)
    }
  })
  return { locationCount, formatLocationList }
}

/**
 * Set error distance list
 * @param {Array} nextRoutes
 * @param {Array} locations
 * @returns Error distance list
 */
export const setErrorDistanceList = (nextRoutes, locations) => {
  if (_.isEmpty(nextRoutes)) {
    return []
  }
  const { locationCount, formatLocationList } = calculateAndFormatLocationList(locations)
  const errorDistanceList = []
  // Parse Object to Array
  const result = Object.keys(nextRoutes).map((key) => ({ key, value: nextRoutes[key] }))
  if (!_.isEmpty(result)) {
    // Case 2 valid location
    if (locationCount === 2) {
      const isSameAddress = checkIsSameLocation(
        formatLocationList[0].lat,
        formatLocationList[1].lat,
        formatLocationList[0].lng,
        formatLocationList[1].lng
      )
      // Set both location if distance = 0 and not same address
      if (result[0].value.distance === 0 && !isSameAddress) {
        errorDistanceList.push(formatLocationList[0].id)
        errorDistanceList.push(formatLocationList[1].id)
      }
    }
    // Case > 2 valid location
    if (locationCount > 2) {
      _.map(result, (route, index) => {
        if (_.isEmpty(errorDistanceList)) {
          // Check route 0 -> n - 2
          if (route.value.distance === 0 && index !== result.length - 1) {
            // Check route n and n + 1 with distance = 0
            if (!_.isEmpty(result[index + 1]) && result[index + 1].value.distance === 0) {
              const isSameFirstRoute = checkIsSameLocation(
                formatLocationList[index].lat,
                formatLocationList[index + 1].lat,
                formatLocationList[index].lng,
                formatLocationList[index + 1].lng
              )
              const isSameSecondRoute = checkIsSameLocation(
                formatLocationList[index + 1].lat,
                formatLocationList[index + 2].lat,
                formatLocationList[index + 1].lng,
                formatLocationList[index + 2].lng
              )
              // 2 routes we have 3 locations
              // Return false if 3 locations are the same
              if (isSameFirstRoute && isSameSecondRoute) {
                return false
              }
              // Set 3rd location if location 1 and 2 are the same and location 3 different
              if (isSameFirstRoute && !isSameSecondRoute) {
                errorDistanceList.push(formatLocationList[index + 2].id)
                return true
              }
              // Set 1st location if location 2 and 3 are the same and location 1 different
              if (!isSameFirstRoute && isSameSecondRoute) {
                errorDistanceList.push(formatLocationList[index].id)
                return true
              }
              // Set 2nd location if location location 2 different location 1 and 3
              errorDistanceList.push(formatLocationList[index + 1].id)
              return true
            }
            // Check route n and n + 1 with route n = 0 and n + 1 !== 0
            if (!_.isEmpty(result[index + 1]) && result[index + 1].value.distance !== 0) {
              // 2 routes we have 3 locations, n + 1 !== 0 so location 2 !== location 3
              const isSameAddress = checkIsSameLocation(
                formatLocationList[index].lat,
                formatLocationList[index + 1].lat,
                formatLocationList[index].lng,
                formatLocationList[index + 1].lng
              )
              // Return false if location 1 and 2 are the same
              if (isSameAddress) {
                return false
              }
              // Set 1st location if location 1 and 2 are different
              errorDistanceList.push(formatLocationList[index].id)
              return true
            }
            // // Check route 0 -> n - 1 (last round)
            // Last round distance = 0
          } else if (route.value.distance === 0 && index === result.length - 1) {
            // Check round n - 2 === 0
            if (!_.isEmpty(result[index - 1]) && result[index - 1].value.distance === 0) {
              const isSameAddress = checkIsSameLocation(
                formatLocationList[result.length].lat,
                formatLocationList[result.length - 1].lat,
                formatLocationList[result.length].lng,
                formatLocationList[result.length - 1].lng
              )
              // Return false if last location and location above are the same
              if (isSameAddress) {
                return false
              }
              // Set last location if last location and location above are different
              errorDistanceList.push(formatLocationList[result.length].id)
              return true
            }
            // Check round n - 2 !== 0
            if (!_.isEmpty(result[index - 1]) && result[index - 1].value.distance !== 0) {
              const isSameAddress = checkIsSameLocation(
                formatLocationList[result.length].lat,
                formatLocationList[result.length - 1].lat,
                formatLocationList[result.length].lng,
                formatLocationList[result.length - 1].lng
              )
              // Return false if last location and location above are the same
              if (isSameAddress) {
                return false
              }
              // Set last location if last location and location above are different
              errorDistanceList.push(formatLocationList[result.length].id)
              return true
            }
          }
        }
        return false
      })
    }
  }
  return errorDistanceList
}

export const setErrorText = (isErrorDistance, isErrorFDMegazone, fullLoadView = true) => {
  if (isErrorDistance && fullLoadView) {
    return I18n.t('webapp.new_booking.step_1.problem_address')
  }
  if (isErrorFDMegazone && fullLoadView) {
    return I18n.t('webapp.new_booking.step_1.outside_area_error_text_megazone')
  }
  return I18n.t('webapp.new_booking.step_1.outside_area_error_text')
}

export const setErrorMessage = (isErrorDistance, isErrorFDMegazone, radius, fullLoadView = true) => {
  if (isErrorDistance && fullLoadView) {
    return I18n.t('webapp.new_booking.step_1.error_distance')
  }
  if (isErrorFDMegazone && fullLoadView) {
    return I18n.t('webapp.new_booking.step_1.error_full_day_megazone', { distance: radius })
  }
  return I18n.t('webapp.new_booking.step_1.outside_address_service_area')
}

export const drawFulldayMegazone = (map, timeType, extraInfos, locations, megazone, callback = () => { }) => {
  const lat = locations?.[0]?.lat || locations?.[0]?.latitude
  const lng = locations?.[0]?.lng || locations?.[0]?.longitude
  if (lat && lng) {
    const location = new window.google.maps.LatLng(lat, lng)
    const fullDayMegazoneRadius = extraInfos.full_day_megazone_main_radius * 1000
    if (timeType === FULL_DAY && extraInfos.enable_full_day_megazone) {
      if (megazone) {
        megazone.setMap(null)
      }
      const circle = new window.google.maps.Circle({
        center: location,
        map,
        radius: fullDayMegazoneRadius,
        fillColor: '#0e730f',
        fillOpacity: 0.1,
        strokeColor: '#0e730f',
        strokeWeight: 2
      })
      callback(circle)
    }
  }
  if (megazone && timeType !== FULL_DAY) {
    megazone.setMap(null)
  }
}

export const renderIconTimeType = (timeType) => {
  switch (timeType) {
    case 'now':
      return NOW_ICON
    case 'schedule':
      return SCHEDULE_BOOKING_ICON_YELLOW
    case 'full_day':
      return FULL_DAY_ICON
    case 'long_haul':
      return LONG_HAUL_BOOKING_ICON
    default:
      return NOW_ICON
  }
}

export const setIsErrorFDMegazone = (timeType, enableFullDayMegazone, index) =>
  timeType === FULL_DAY && enableFullDayMegazone && index !== 0

export const renderHyperLink = (hyperLink, hyperLinkContent, hyperLinkText, className) => {
  let content = hyperLinkContent
  if (
    !_.isEmpty(hyperLink) &&
    !_.isEmpty(hyperLinkText) &&
    hyperLink.length > 0 &&
    hyperLinkText.length > 0 &&
    hyperLinkContent.indexOf(hyperLinkText) !== -1 &&
    _.trim(hyperLinkContent).length >= _.trim(hyperLinkText).length
  ) {
    const subContent = `<a href='${hyperLink}' class='${className}' target='_blank'>${hyperLinkText}</a>`
    content = hyperLinkContent.split(hyperLinkText).join(subContent)
  }
  return content
}

export const setIsErrorAddress = (outsideList, tempID) => _.includes(outsideList, tempID)

export const checkIsValidAddress = (name, lat, lng) => !_.isEmpty(name) && !_.isUndefined(lat) && !_.isUndefined(lng)

export const getFDMegazoneLink = (vehicleType, extraInfos) => {
  if (!_.isEmpty(vehicleType)) {
    return vehicleType
  }
  if (!_.isEmpty(extraInfos)) {
    return extraInfos
  }
  return ''
}

export const getIndexListOfValidAddress = (locations) => {
  const indexList = []
  let indexCount = 0
  _.map(locations, (item, index) => {
    if (item.name && !_.isUndefined(item.lat) && !_.isUndefined(item.lng)) {
      const value = {
        index,
        newIndex: indexCount,
      }
      indexList.push(value)
      indexCount += 1
    }
  })
  return indexList
}

export const getOSAAndESAList = (locations, indexList, outsideList, extendedList) => {
  const uniqOutSideList = _.uniq(outsideList)
  const uniqExtendList = _.uniq(extendedList)
  const filterOutSideList = _.filter(uniqOutSideList, (item) => !_.includes(uniqExtendList, item))
  const filterExtendList = _.filter(uniqExtendList, (item) => _.includes(uniqOutSideList, item))
  const formatOutSideList = []
  const formatExtendList = []
  const normalOSAList = []
  _.forEach(indexList, (item) => {
    if (_.includes(uniqOutSideList, item.newIndex)) {
      normalOSAList.push(locations[item.index].id)
    }
    if (_.includes(filterOutSideList, item.newIndex)) {
      formatOutSideList.push(locations[item.index].id)
    }
    if (_.includes(filterExtendList, item.newIndex)) {
      formatExtendList.push(locations[item.index].id)
    }
  })
  return { formatOutSideList, formatExtendList, normalOSAList }
}

export const getParamFromURL = (param) => {
  const urlParams = new URLSearchParams(window.location.search)
  return urlParams.get(param)
}
export const removeParamOnURL = (key) => {
  const urlParams = new URLSearchParams(window.location.search)
  if (key) urlParams.delete(key)
  let newUrl = `${window.location.pathname}?${urlParams.toString()}`
  if(!urlParams.toString()) {
    newUrl = window.location.pathname
  }
  window.history.pushState({}, '', newUrl)
}
export const getUndefinedAddress = (locations) => {
  const idList = []
  _.forEach(locations, (location) => {
    const { lat, lng, id, name } = location
    let newLocation = { ...location }
    if (_.isEmpty(id)) {
      newLocation = {
        ...location,
        id: location.temp_id,
      }
    }
    if ((_.isUndefined(lat) || _.isUndefined(lng)) && name) {
      idList.push(newLocation.id)
    }
  })
  return idList
}

export const getParamsForCalBatchEZ = (currentCustomer, bookings, extraInfos, CPODUtils) => {
  const bookingParams = _.map(bookings, (booking) => {
    const bookingTimeType = !_.isEmpty(booking.time_type_option) ? booking.time_type_option.type_key : SCHEDULE
    const mount = !_.isEmpty(booking.time_type_option) ? booking.time_type_option.fullday_selected_amount : 1
    const currentVehicleTypeID = booking.vehicle_type ? booking.vehicle_type.id : 0
    const locations = booking.locations.map((location) =>
      _.assign({}, location, { latitude: location.lat, longitude: location.lng })
    )

    const verifyParams = CPODUtils.validateParamsDocumentReturn(locations, booking.checkLocations)
    const bookingTrackingAttr = verifyParams ? CPODUtils.validParamsBeforeCallAPI(booking.documentReturn) : null

    return {
      vehicleTypeID: currentVehicleTypeID,
      locations: locations.map((location) => ({
        latitude: location.latitude,
        longitude: location.longitude,
        need_pod: location.need_pod,
        need_cod: verifyParams ? false : location.need_cod,
        cod_invoice_fees: verifyParams ? '' : _.toInteger(location.cod_invoice_fees),
        name: location.name,
        extra_requirement_locations: location.extra_requirement_locations,
      })),
      currentCustomer,
      id: booking.id,
      extraRequirements: _.filter(booking.extra_requirements, { selected: true }),
      timeType: bookingTimeType,
      pickupTime: booking.pickup_date_time,
      fullDaySelectedAmount: mount,
      discountCode: undefined,
      name: booking.name,
      roundTripDiscount: booking.round_trip_discount || false,
      bookingTrackingAttr,
      customReimbursements: booking.customReimbursements,
      extraInfos,
      use_credit: _.isUndefined(booking.use_credit) ? null : booking.use_credit,
    }
  })
  return bookingParams
}

export const getValidLocationForBooking = (booking) => {
  const newBooking = { ...booking }
  const { locations } = newBooking
  const { formatLocationList } = calculateAndFormatLocationList(locations)
  return {
    ...newBooking,
    id: newBooking.temp_id,
    locations: formatLocationList,
  }
}

export const handleVerifyLocationList = (locations, errorDistanceList) =>
  _.map(locations, (location) => ({
    ...location,
    verify: {
      ...location.verify,
      name: _.includes(errorDistanceList, location.temp_id)
        ? false
        : checkIsValidAddress(location.name, location.lat, location.lng),
      isErrorDistance: _.includes(errorDistanceList, location.temp_id)
        ? false
        : checkIsValidAddress(location.name, location.lat, location.lng),
    },
  }))

export const checkErrorDistanceTable = (data, bookings) => {
  const finalBooking = []
  let isValid = true
  let errorCount = 0
  _.forEach(data, (booking, index) => {
    const { routes } = booking
    const errorDistanceList = setErrorDistanceList(routes, bookings[index].locations)
    if (!_.isEmpty(errorDistanceList)) {
      isValid = false
      const verifyLocationList = handleVerifyLocationList(bookings[index].locations, errorDistanceList)
      const modifyBooking = {
        ...bookings[index],
        locations: verifyLocationList,
      }
      errorCount += errorDistanceList.length
      finalBooking.push(modifyBooking)
    } else {
      finalBooking.push(bookings[index])
    }
  })
  return { finalBooking, isValid, errorCount }
}

export const isLocationCompleted = (locationHasSignature, index) =>
  !_.isNull(locationHasSignature) && locationHasSignature >= index

export const isLocationFailedDelivery = (status) => status === LOCATION_STATUSES.FAILED

export const tallyTitle = (timeType, isPTL) =>
  timeType === NOW
    ? I18n.t(`webapp.tally.${isPTL ? 'at_delivery' : 'at_final_stop'}`)
    : I18n.t(`webapp.tally.${isPTL ? 'reach_delivery_by' : 'reach_final_stop_by'}`)

export const copyToClipBoard = (content) => {
  const textArea = document.createElement('textarea')
  textArea.value = content
  document.body.appendChild(textArea)
  textArea.select()
  document.execCommand('Copy')
  textArea.remove()
}

export const handleClickOutside = (ref, event, callback) => {
  if (ref && !ref.contains(event.target)) {
    callback()
  }
}

export const getCurrentEmploy = (employs, lastLoginEmployId) =>
  _.find(employs, (employ) => employ.company_id === lastLoginEmployId)

export const getCompanyByEmployId = (companies, companyId) => _.find(companies, (com) => com.id === companyId)
export const isMarketingPage = getParamFromURL('is_mkt') && getParamFromURL('quote_id')
export const getQueryVariable = (variable) => {
  const query = window.location.search.substring(1)
  const vars = query.split('&')
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=')
    if (pair[0] === variable) { return pair[1] }
  }
  return (false)
}
export const isEditBooking = () => window.location.pathname?.split('/').includes('edit')
export const isBookAgain = () => window.location.pathname?.split('/').includes('book_again')
export const isNewDriver = () => getQueryVariable('is_new_driver') === 'true'
export const isEditDraft = () => getQueryVariable('edit_draft') === 'true'
export const isTrackingPage = () => window.location.pathname?.split('/').includes('tracking')
export const hasAssignDriver = () => isEditBooking() && getQueryVariable('driver_id')
export const isNewBooking = window.location.pathname === '/'
  || window.location.pathname?.split('/').includes('new')
export const isPaymentBooking = (paymentMethodForNonBp) => PAY_BY_ONLINE_PAYMENT_LIST.includes(paymentMethodForNonBp)

export const isHideLoadingNewBooking = (params) => {
  const { serviceTypes, extraInfos, selectedServiceTypeID } = params
  return (
    serviceTypes.length > 0 &&
    extraInfos?.id &&
    extraInfos.tc_link &&
    selectedServiceTypeID &&
    !isEditBooking() &&
    !isBookAgain()
  )
}

export const isHideLoadingEditBooking = (params) => {
  const { serviceTypes, extraInfos, selectedServiceTypeID, bookAgainDetails } = params
  return (
    serviceTypes.length > 0 &&
    extraInfos?.id &&
    extraInfos.tc_link &&
    selectedServiceTypeID &&
    ((isEditBooking() || isBookAgain()) && !_.isEmpty(bookAgainDetails))
  )
}

export const isHideLoadingMarketingBooking = (params) => {
  const { serviceTypes, extraInfos, isGetSuccessDataMarketing, bookAgainDetails, selectedServiceTypeID } = params

  return (
    serviceTypes.length > 0 &&
    extraInfos?.id &&
    extraInfos.privacy_policy_link &&
    isGetSuccessDataMarketing &&
    selectedServiceTypeID &&
    ((isEditBooking() || isBookAgain()) && !_.isEmpty(bookAgainDetails))
  )
}
export const setAttachments = (attachments) =>
  _.range(3).map((index) => {
    let autoAttachment = attachments[index]
    if (_.isEmpty(autoAttachment) || (!_.isEmpty(autoAttachment) && _.isEmpty(autoAttachment.document_url))) {
      autoAttachment = {
        id: undefined,
        document_url: undefined,
        document_content_type: undefined,
        document_file_name: undefined,
        document_type: undefined,
        allow_to_delete: true,
        file: undefined,
      }
    } else {
      _.assign(autoAttachment, { file: undefined })
    }
    return autoAttachment
  })

export const getCurentCompanyTypeId = (currentCustomer) => {
  const company = currentCustomer?.companies?.find((item) => item.id === currentCustomer?.current_company_id)
  return company?.company_type_id || ''
}

export const redirectToSelectArea = () => {
  window.localStorage.removeItem('areaId')
  window.location.href = '/business/select_area'
}

const getCompanyById = (currentCustomer) =>
  currentCustomer?.companies?.find((item) => item.id === currentCustomer?.current_company_id)

const getCurentCompanyDropOffDestination = (currentCustomer) => {
  const company = getCompanyById(currentCustomer)
  return company?.business_account_number_dropping_of_destination + 1 || 0
}

export const setDefaultMaxLocation = (currentCustomer = {}, extraInfos = {}) => {
  const isBusiness = !!currentCustomer.last_login_employ_id
  const { customer_number_dropping_of_destination: customerNumberDroppingOfDestination } = extraInfos
  if (currentCustomer.id) {
    if (isBusiness) {
      return getCurentCompanyDropOffDestination(currentCustomer)
    }
    return customerNumberDroppingOfDestination + 1
  }
  return 16
}

export const getValidTabName = (tabName) => (tabName === 'favorited_v2' ? BOOKING_SCOPE_FAVORITED : tabName)

export const renderFee = (booking, countryLanguage, vehicleType) => {
  const workingTime = vehicleType?.settings?.full_day_working_time
  const selectedDay = booking?.full_day_selected_amount
  let fullDayHours = 0
  if (workingTime && selectedDay) {
    fullDayHours = workingTime * selectedDay
  }
  const unit = fullDayHours <= 1 ? I18n.t('webapp.tally.unit.full_text_hour') : I18n.t('webapp.tally.unit.full_text_hours')
  if (booking.time_type === FULL_DAY) {
    return `${I18n.t('webapp.pricing_display.full_day_fee', { number: fullDayHours })} ${unit}`
  }
  switch (booking.booking_type) {
    case CUSTOM_PRICE:
      return I18n.t('webapp.pricing_display.fare', { distance: Utils.formatDistance(booking.total_distance, countryLanguage) })
    default:
      return I18n.t('webapp.pricing_display.standard_fee', { number: Utils.formatDistance(booking.total_distance, countryLanguage) })
  }
}

export const verifyPickupTime = ({ minutes = 0, pickupTime, timeType }) => {
  const pickupTimeTemp = new Date(pickupTime)
  const now = new Date()
  const comparingTime = pickupTimeTemp - now
  if (comparingTime > (minutes * ONE_MIN_TO_MS)
    || _.isEmpty(timeType)
    || timeType === NOW
    || timeType === QUICK_CHOICE) {
    return true
  }
  return false
}

export const generateEventCancelModal = booking => ({
  'Booking ID': booking.id,
  'Service Type ID': booking.service_type.id,
  'Service Type Name': booking.service_type.name,
  'Vehicle Type ID': booking.vehicle_type.id,
  'Vehicle Type Name': booking.vehicle_type.name,
  'Time Type': TIME_TYPE_TEXT[booking.time_type],
  ...(booking.batch_id && { 'Batch ID': booking.batch_id }),
})

export const isActivePayment = (booking, currentCustomer, batchIdBooking) => {
  const paymentMethodForNonBp = booking?.payment_method_for_non_bp
  const batchId = getParamFromURL('batch_id') || batchIdBooking
  return booking?.special_settings?.enable_online_payment && !currentCustomer?.last_login_employ_id
    && !batchId && SHOW_PAYMENT_METHOD_LIST.includes(paymentMethodForNonBp)
}

export const setDateTimeForPayment = (date) => {
  const formatDate = moment(date).format('DD-MMM-YYYY')
  if (moment().isSame(formatDate, 'day')) return I18n.t('label.today').toLowerCase()
  if (moment(date).isSame(moment().add(1, 'day'), 'day')) return I18n.t('label.tomorrow').toLowerCase()
  return formatDate
}

export const convertMilSecToSec = time => time * 1000