// UTILS
import * as BatchHelpers from 'utils/common/batch'
import { CPODUtils } from 'utils/booking/CPODUtils'
import $ from 'jquery'
import _ from 'lodash'
import toastr from 'utils/toast';
// API
import BatchesAPI from 'api/batches'
import SubAccountAPI from 'api/subAccountTag'
// ACTIONS
// COMPONENTS
// CONSTANTS
// CONSTANTS

import {
  UPDATE_DRAFT_BATCHES,
  CREATED_FROM,
} from 'constants/ezSpreadSheetConstants'
import {
  STATUS_LOCATING_DRIVER, WEB_EZ_SHEET, WEB_PLANNER, SCHEDULE,
  FULL_DAY, LONG_HAUL, NOW
} from 'constants/bookingConstants'

import {
  EZ_SHEET_UPLOAD,
  SMART_LOAD_PLANNER,
} from 'constants/common/batchConstants'
import { batchActionsCreator } from 'store/toolkit/batch/batch.reducer';
import { subAccountTagListActionsCreator } from 'store/toolkit/subAccountTagList/subAccountTagList.reducer';
import { selectedBookingActionsCreator } from 'store/toolkit/selectedBooking/selectedBooking.reducer';
import { selectedLocationActionsCreator } from 'store/toolkit/selectedLocation/selectedLocation.reducer';
// ASSETS

const UPDATE_FIELDS = [
  'after_hour_service_fees',
  'business_credit_amount',
  'cod_pod_fees',
  'new_gen_pod',
  'company_type_discount',
  'country_code',
  'credit_amount',
  'currency',
  'discount_amount',
  'display_total_fees',
  'distance_fees',
  'distance_fee_details',
  'first_time_discount_message',
  'full_day_selected_amount',
  'out_of_service_area_fee',
  'parking_fees',
  'subtotal',
  'surcharge_pricing_percent',
  'surcharge_pricing_title',
  'surcharges_fees',
  'surcharges_adjustments',
  'has_surcharge',
  'time_type',
  'total_distance',
  'total_fees',
  'tolls_fees',
  'vehicle_type_id',
  'way_point_fees',
  'waiting_time_fees',
  'round_trip_discount',
  'round_trip_discount_amount',
  'custom_reimbursements_info',
  'custom_reimbursements',
  'show_tbd',
  'special_adjustment_dynamic_text',
  'demand_adjustment_dynamic_text',
  'cash_back_amount',
  'cashback_credit_used',
  'use_credit',
  'free_reimbursement_max_cap',
  'free_reimbursements'
]


export const updateBatch = attrs => batchActionsCreator.updateBatch({ attrs })

export const resetBatchBooking = bookings => batchActionsCreator.resetBatchBooking(bookings)

export const updateBatchBookings = bookings => batchActionsCreator.updateBatchBookings({ bookings })

export const updateBooking = booking => batchActionsCreator.updateBooking({
  booking
})

export const updateBookingLocation = (booking, location) => batchActionsCreator.updateBookingLocation({
  booking,
  location
})

export const updateBookingBadge = (booking, badge) => batchActionsCreator.updateBookingBadge({
  booking,
  badge
})

export const deleteBookingLocation = (booking, location) => batchActionsCreator.deleteBookingLocation({
  booking,
  location
})

export const addNewLocationSelectedBooking = () => (dispatch, getState) => {
  const currentCustomer = getState().currentCustomer
  dispatch(selectedBookingActionsCreator.addNewLocationSelectedBooking({ currentCustomer }))
}

export const insertRoundTripDiscountSelectedBooking = (location, maxNumberOfLocation) => batchActionsCreator.insertRoundTripDiscountSelectedBooking({
  location,
  maxNumberOfLocation,
})

export const updateLocationSelectedBooking = (location, resetOptimized = true) => selectedBookingActionsCreator.updateLocationSelectedBooking({
  location,
  resetOptimized
})

export const deleteLocationSelectedBooking = location => selectedBookingActionsCreator.deleteLocationSelectedBooking({
  location
})

export const addExtraRequirementToSelectedBooking = extraRequirement => selectedBookingActionsCreator.addExtraRequirementToSelectedBooking({
  extraRequirement
})

export const deleteExtraRequirementSelectedBooking = extraRequirement => selectedBookingActionsCreator.deleteExtraRequirementSelectedBooking({
  extraRequirement
})


export const updateCustomReimbursementToSelectedBooking = customReimbursement => selectedBookingActionsCreator.updateCustomReimbursementToSelectedBooking({
  customReimbursement
})

export const setSelectedBooking = booking => selectedBookingActionsCreator.setSelectedBooking({
  booking
})

export const setSelectedLocation = location => selectedLocationActionsCreator.setSelectedLocation({
  location
})

export const sortBookingLocationsOrder = () => selectedBookingActionsCreator.sortBookingLocationsOrder()

export const updateSelectedBooking = attrs => selectedBookingActionsCreator.updateSelectedBooking({
  attrs
})

export const removeExtraPerLocation = payload => selectedBookingActionsCreator.removeExtraPerLocation({
  payload
})

export const updateExtraPerLocation = payload => selectedBookingActionsCreator.updateExtraPerLocation({
  payload
})

export const calculate = (bookingParams, bookings, callback) => (dispatch) => {
  BatchesAPI.calculate(bookingParams, (response) => {
    if (response.error) {
      toastr.error(response.error)
      $('#loading-modal').removeClass('visible')
      return null
    }
    const bookingsCalculated = response.bookings.data
    const footNote = response.footnote_for_batch
    let updatedBooking
    let newBooking
    bookings.forEach((booking, index) => {
      updatedBooking = { ...booking }
      newBooking = bookingsCalculated[index]
      UPDATE_FIELDS.forEach((field) => {
        updatedBooking[field] = newBooking[field]
        updatedBooking.footnote_for_batch = footNote
      })
      dispatch(batchActionsCreator.updateBooking({ booking: updatedBooking }))
    })
    callback(response)
  })
}

export const actionCalculateBookingCredit = (updatedBooking, currentCustomer) => (dispatch) => {
  const bookingAfterCalculateCredit = BatchHelpers.calculateCreditAndCashback(updatedBooking, currentCustomer)
  dispatch(updateBooking(bookingAfterCalculateCredit))
}
export const calculateParallel = (bookingParam, booking, callback) => async (dispatch, getState) => {
  const { currentCustomer } = getState()
  const res = await BatchesAPI.calculateParallelApi(bookingParam, currentCustomer)
  const response = res?.data
  if (response?.error) {
    toastr.error(response.error)
    $('#loading-modal').removeClass('visible')
    return null
  }
  const bookingCalculated = response?.object
  const updatedBooking = { ...booking }
  UPDATE_FIELDS.forEach((field) => {
    updatedBooking[field] = bookingCalculated[field]
  })
  // Todo: this handle is wrong, we need fix this bug asap
  dispatch(actionCalculateBookingCredit(updatedBooking, currentCustomer))
  return callback(bookingCalculated)
}
export const getFootNoteBatch = listBooking => async (dispatch, getState) => {
  const { currentCustomer, batch } = getState()
  const timeTypes = listBooking.map(booking => booking.time_type)
  let timeType = FULL_DAY
  if (timeTypes.includes(SCHEDULE)) {
    timeType = SCHEDULE
  } else if (timeTypes.includes(LONG_HAUL)) {
    timeType = LONG_HAUL
  } else if (timeTypes.includes(NOW)) {
    timeType = NOW
  }
  const listBookingHasTimeType = listBooking.filter(booking => booking.time_type === timeType)
  let bookingFootNote = listBookingHasTimeType[0]
  const index = listBookingHasTimeType.findIndex(booking => booking.surcharges_fees > 0)
  if (index !== -1) {
    bookingFootNote = listBookingHasTimeType[index]
  }
  const params = {
    time_type: bookingFootNote.time_type,
    vehicle_type_id: bookingFootNote.vehicle_type_id,
    ...(currentCustomer.current_company_id && { company_id: currentCustomer.current_company_id })
  }
  const res = await BatchesAPI.getFootNoteBatchApi(params, currentCustomer)
  const footNote = res?.data?.footnote_for_batch
  dispatch(updateBooking({
    footnote_for_batch: footNote,
    temp_id: batch.bookings[0]?.temp_id
  }))
}


const buildBookingBadges = booking => () => {
  let bookingBadges = []
  _.forEach(booking.badges, (badge) => {
    const badgeAmount = _.toInteger(badge.amount) > 0 ? _.toInteger(badge.amount) : 1
    const bookingBadge = {
      id: badge.id,
      badgeable_relation_type: badge.badgeable_relation_type,
      selected_amount: badgeAmount
    }
    bookingBadges = _.concat(bookingBadges, bookingBadge)
  })
  return bookingBadges
}

const buildBookingExtraRequirements = extraRequirements => () => {
  const extras = _.filter(extraRequirements, { selected: true })
  extras.map((extraRequirement) => {
    const result = {
      extra_requirement_id: extraRequirement.id,
      selected_amount: extraRequirement.selected_amount,
      is_flat: extraRequirement.is_flat,
      position: extraRequirement.position,
      unit_price: extraRequirement.unit_price
    }
    if (_.isUndefined(extraRequirement.selected_amount)) {
      result.selected_amount = 1
    }
    if (!_.isUndefined(extraRequirement.selectedPricing)) {
      result.unit_price = extraRequirement.selectedPricing.fees
      result.level_price = extraRequirement.selectedPricing.level_price
      result.extra_requirement_pricing_id = extraRequirement.selectedPricing.id
      result.selected = true
    }
    return result
  })
  return extras
}

const mappingBookingParams = batch => (dispatch) => {
  const newBatch = { ...batch }
  let duplicateBookings = []
  const { bookings } = newBatch

  _.forEach(bookings, (booking) => {
    const newBooking = { ...booking }
    const bookingAttachmentIDs = []

    if (!_.isUndefined(booking.bookingAttachmentsAttributes) && booking.bookingAttachmentsAttributes.length > 0) {
      _.forEach(booking.bookingAttachmentsAttributes, (attachment) => {
        if (!attachment.isAutoAttachment && !_.isUndefined(attachment.id)) {
          bookingAttachmentIDs.push(attachment.id)
        }
      })
    }

    _.assign(newBooking, {
      time_type: booking.time_type_option.type_key,
      display_time_type: booking.time_type_option.display_time_type_key,
      vehicle_type_id: booking.vehicle_type.id,
      service_type_id: booking.vehicle_type.service_type_id,
      pickup_time: booking.pickup_date_time,
      full_day_selected_amount: booking.time_type_option.fullday_selected_amount,
      note: booking.note_to_driver,
      badges: dispatch(buildBookingBadges(booking)),
      status: STATUS_LOCATING_DRIVER,
      batch_tracking_token: BatchHelpers.genBatchTrackingToken(),
      booking_attachment_ids: bookingAttachmentIDs,
      extra_requirements: dispatch(buildBookingExtraRequirements(booking.extra_requirements)),
      require_signatures: booking.require_signatures,
      custom_reimbursements: booking.custom_reimbursements,
      sub_account_tag_attributes: booking.sub_account_tag,
      use_credit: booking.use_credit
    })

    const validLocations = _.filter(booking.locations,
      location => !_.isUndefined(location.lat) && !_.isUndefined(location.lng))
    const verifyParams = CPODUtils.validateParamsDocumentReturn(validLocations, booking.checkLocations)

    if (verifyParams) {
      _.assign(newBooking, {
        booking_tracking_attributes: booking.documentReturn,
      })
    }

    let newLocations = []
    _.forEach(booking.locations, (location) => {
      const isPhoneMask = !_.isEmpty(location.phone_mask) && _.isUndefined(location.contact_id)
      const newLocation = { ...location }

      _.assign(newLocation, {
        description: location?.location_note,
        latitude: location.lat,
        longitude: location.lng,
        is_phone_mask: isPhoneMask,
        need_cod: verifyParams ? false : location.need_cod,
        cod_invoice_fees: verifyParams ? '' : _.toInteger(location.cod_invoice_fees),
        extra_requirement_locations_attributes: location.extra_requirement_locations || []
      })
      newLocations = _.concat(newLocations, newLocation)
    })

    newBooking.locations = newLocations
    duplicateBookings = _.concat(duplicateBookings, newBooking)
  })
  newBatch.bookings = duplicateBookings
  return newBatch
}

export const batchBooking = (
  currentCustomer,
  extraRequirements,
  batch,
  paramBatchTemplate,
  callback,
  isSmartPlanner = false
) => (dispatch, getState) => {
  // mapping params
  const batchParams = dispatch(mappingBookingParams(batch))
  const { currentTab, stepUploadTabsData, extraInfos } = getState()
  const currentTabData = _.find(stepUploadTabsData, { tabName: currentTab })
  let willBeUseBatchTemplate = paramBatchTemplate

  if (currentTabData.mappingSystemFileDetected) {
    willBeUseBatchTemplate = undefined
  }
  batchParams.created_by = isSmartPlanner ? WEB_PLANNER : WEB_EZ_SHEET
  batchParams.batch_type = isSmartPlanner ? SMART_LOAD_PLANNER : EZ_SHEET_UPLOAD
  batchParams.cashback_credit_used_display = BatchHelpers.getAllBookingsCreditAmount({
    bookings: batch.bookings,
    currentCustomer,
  })

  BatchesAPI.create(
    currentCustomer,
    extraRequirements,
    batchParams,
    willBeUseBatchTemplate,
    extraInfos,
    (response) => {
      if (response.error && response.data.status !== 404) {
        toastr.error(response.data.error)
        $('#loading-modal').removeClass('visible')
        return null
      }
      callback(response)
    }
  )
}

const mappingSaveAsDraftParams = (batch, isSmartPlanner) => (dispatch, getState) => {
  const state = getState()
  const {
    subAccountTagList,
    smartPlanner
  } = state

  const bookings = _.map(batch.bookings, (booking) => {
    const hasSubAccountId = booking.sub_account_tag && booking.sub_account_tag.sub_account_id
    const subAccountTag = _.isEmpty(subAccountTagList)
      ? null
      : subAccountTagList.find(item => item.sub_account_id === hasSubAccountId)

    return {
      ...booking,
      googleMap: undefined,
      locationBeforeOptimize: undefined,
      added_from: CREATED_FROM.draft_batchez,
      sub_account_tag: subAccountTag,
      backup_vehicle_type: null,
      backup_transit_time: null,
      backup_pickup_date_time: null,
      backup_isVehicleOverload: null,
      locations: _.map(booking.locations, location => ({
        ...location,
        marker: undefined,
        googleMap: undefined
      })),
      use_credit: null
    }
  })

  return {
    ...batch,
    batch_type: isSmartPlanner ? SMART_LOAD_PLANNER : EZ_SHEET_UPLOAD,
    created_by: isSmartPlanner ? WEB_PLANNER : WEB_EZ_SHEET,
    planner_id: _.get(smartPlanner, 'optimizeRoutes.id', 0),
    bookings
  }
}

export const batchSaveAsDraft = (
  currentCustomer,
  batch,
  batchTemplate,
  callback,
  isSmartPlanner = false
) => (dispatch) => {
  const batchParams = dispatch(mappingSaveAsDraftParams(batch, isSmartPlanner))
  BatchesAPI.saveAsDraft(
    currentCustomer,
    batchParams,
    batchTemplate,
    isSmartPlanner,
    (response) => {
      callback(response)
    }
  )
}

const mappingOutOfServiceParams = batch => () => {
  const bookingTemps = []
  const { bookings } = batch
  _.forEach(bookings, (booking) => {
    if (!_.isEmpty(booking.vehicle_type)) {
      let locationTemps = []
      const { locations } = booking
      _.forEach(locations, (location) => {
        if (!_.isUndefined(location.lat) && _.trim(location.lat).length > 0
          && !_.isUndefined(location.lng) && _.trim(location.lng).length > 0) {
          locationTemps = _.concat(locationTemps, {
            temp_id: location.temp_id,
            lat: location.lat,
            lng: location.lng,
          })
        }
      })
      if (_.size(locationTemps) > 0 && !_.isEmpty(booking.time_type_option)) {
        bookingTemps.push({
          locations: locationTemps,
          temp_id: booking.temp_id,
          vehicle_type_id: booking.vehicle_type.id,
          service_type_id: booking.vehicle_type.service_type_id,
          booking_type: booking.time_type_option.type_value,
        })
      }
    }
  })
  return { bookings: bookingTemps }
}

export const batchCheckOutOfServices = (
  batch, currentCustomer, extraInfos, popupScreenType, callback
) => (dispatch) => {
  const batchParams = dispatch(mappingOutOfServiceParams(batch))
  if (!_.isEmpty(batchParams.bookings) && _.size(batchParams.bookings) > 0) {
    BatchesAPI.checkOutOfServiceLocaitons(batchParams, currentCustomer, extraInfos, popupScreenType, (response) => {
      callback(response)
    })
  } else {
    callback([])
  }
}

export const bookingCheckOutOfServices = (
  booking, serviceTypeID, vehicleTypeID, timeType, countryCode, callback, companyId = 0, currentCustomer = {}
) => () => {
  const { locations } = booking
  _.forEach(locations, (location) => {
    _.assign(location, {
      lat: location.lat,
      lng: location.lng
    })
  })

  BatchesAPI.checkOutOfServiceBookingLocations(
    locations,
    { timeType, serviceTypeID, vehicleTypeID },
    { countryCode, companyId, authenticationToken: currentCustomer.authentication_token },
    (response) => {
      callback(response)
    }
  )
}

export const deleteBookingAttachments = (deleteATMs, callback) => () => {
  BatchesAPI.deleteBatchBookingAttachments(deleteATMs, (response) => {
    callback(response)
  })
}

export const loadPaginateSavedBatches = (areaID, currentCustomer, params, callback) => () => {
  BatchesAPI.loadSavedBatches(areaID, currentCustomer, params, (response) => {
    callback(response)
  })
}

function generatePayloadRequestForCalculateTransitTime(booking, state) {
  const validLocations = _.filter(booking.locations,
    location => !_.isUndefined(location.lat) && !_.isUndefined(location.lng))
  const {
    time_type_option: timeTypeOption
  } = booking
  return {
    vehicleTypeID: booking.vehicle_type.id,
    locationsAttributes: validLocations.map((location) => {
      const result = {
        latitude: location.lat,
        longitude: location.lng,
        name: location.name,
        need_cod: location.need_cod,
        need_pod: location.need_pod,
        ...(location.cod_invoice_fees ? { cod_invoice_fees: _.toInteger(location.cod_invoice_fees) } : {})
      }
      return result
    }),
    currentCustomer: state.currentCustomer,
    pickupTime: booking.pickup_date_time,
    timeType: timeTypeOption.type_key,
    fullDaySelectedAmount: timeTypeOption.fullday_selected_amount || 1,
    ...(booking.eta_locations_id ? { etaLocationsId: booking.eta_locations_id } : {}),
    ...(booking.extra_requirements ? { extraRequirements: booking.extra_requirements } : {}),
  }
}

export const getTransitTimeForTally = booking => (dispatch, getState) => {
  const state = getState()
  const params = generatePayloadRequestForCalculateTransitTime(booking, state)

  return new Promise(resolve => (
    BatchesAPI.getTransitTimeForTally({ bookingParams: params }, (response) => {
      // we need to store eta_locations_id into booking and use it to send back to server for server optimisation
      const values = _.defaults(
        _.pick(
          response.object,
          ['transit_time', 'eta_locations_id', 'worst_transit_time'],
        ),
        {
          transit_time: null,
          worst_transit_time: null,
        }
      )

      if (values.transit_time) {
        resolve(dispatch(batchActionsCreator.updateTransitTime({
          booking,
          bookingAttrs: values,
        })))
      }
    })
  ))
}

export const setSubAccountTagList = (values = []) => (dispatch) => {
  let payload = []
  if (values && values.length) {
    payload = values.map(item => ({
      sub_account_id: item.id,
      sub_account_name: item.name,
      last_used_at: item.last_used_at,
      position: item.position,
    }))
  }
  dispatch(subAccountTagListActionsCreator.subAccountTagList(payload))
}

export const getSubAccountTagList = (params = {}) => (dispatch, getState) => {
  const state = getState()
  const currentCustomer = state.currentCustomer
  let newParams = { ...params }
  if (currentCustomer.current_company_id) {
    newParams = { ...newParams, company_id: currentCustomer.current_company_id }
  }
  SubAccountAPI.getSubAccountTagList(
    currentCustomer.authentication_token,
    newParams,
    (res) => {
      dispatch(setSubAccountTagList(res?.data?.data))
    }
  )
}

export const getDetailBatches = ({ batchIds, stepActions, isSavedBatch = false }, callback) => (dispatch, getState) => {
  const {
    currentCustomer,
    currentArea
  } = getState()
  const params = {
    'ids': batchIds,
    is_saved_batch: isSavedBatch,
    area_id: currentArea.id,
  }
  BatchesAPI.loadDetailBatches(currentArea.id, currentCustomer, params, (response) => {
    const bodyResponse = _.get(response, 'data') || {}

    if (bodyResponse.error) {
      stepActions.loaded()
      toastr.error(bodyResponse.error)
    } else {
      callback(bodyResponse.data)
    }
  })
}
