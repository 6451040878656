/* eslint-disable import/prefer-default-export */
import ReactDOM from 'react-dom'
import $ from 'jquery'
import _ from 'lodash'
// UTILS
import { currentServiceType, isCustomerEditBooking } from 'utils/new_booking/common'
import I18n from 'i18n/i18n'
import toastr from 'utils/toast';
// API
import PaymentIntegrationAPI from 'api/payment-integration'
// ACTIONS
import {
  getExtraServices,
  setUpBookingLocationForExtraRequirementPerLocation
} from './extraServiceActionCreators'
import {
  calculate,
  create,
  editBooking,
  addToFavoriteDrivers,
} from '../common/bookingActionCreators'
import { getCustomerCreditAmount } from '../common/customerActionCreators'
import { setAutoAttachments } from './attachmentActionCreators'

// COMPONENTS
import DynamicPopupWithButton from 'components/common/DynamicPopupWithButton'
// CONSTANTS
import {
  NOW, FULL_DAY, LONG_HAUL, PRICING_PAYMENT_INCREASE, PAYMENT_VA_STATUS, CUSTOMER_PAYMENT, CUSTOMER_QUOTE_PAYMENT,
} from 'constants/bookingConstants'
import { BATCH_STATUS_COMPLETED, BATCH_STATUS_CANCELED } from 'constants/ezSpreadSheetConstants'
// ASSETS
import {
  ICON_QUOTE_ID, ICON_DRIVER_PREFERENCES, ICON_CANCEL_BOOKING, CART_SHOPPING,
} from 'constants/imageConstants'
import { isEditBooking } from 'utils/booking/common'
import { bookingAgainDetailsActionsCreator } from 'store/toolkit/newBooking/bookingAgainDetails.reducer';
import { dataChangesActionsCreator } from 'store/toolkit/newBooking/dataChange.reducer';
import { othersActionsCreator } from 'store/toolkit/newBooking/others.reducer';
import { locationsActionsCreator } from 'store/toolkit/locations/locations.reducer';
import { tmpLocationActionsCreator } from 'store/toolkit/newBooking/updateTmpLocation.reducer';
import { extraInfosActionsCreator } from 'store/toolkit/extraInfos/extraInfos.reducer';
import { currentStepActionsCreator } from 'store/toolkit/currentStep/currentStep.reducer';
import { stepNewLongHaulActionsCreator } from 'store/toolkit/newBooking/stepNewLongHaul.reducer'
import { extraServicesActionsCreator } from 'store/toolkit/extraServices/extraServices.reducer'
import firebaseInit from 'utils/firebaseInit'

export const loading = () => () => {
  $('#loading-modal').addClass('visible')
}

export const loaded = () => () => {
  $('#loading-modal').removeClass('visible')
}

export const toggleDraggableMarkers = status => (dispatch, getState) => {
  getState().locations.forEach((location) => {
    if (location.marker) location.marker.setDraggable(status)
  })
}

export const changeStep = step => dispatch => dispatch(currentStepActionsCreator.changeStep(step))

export const shouldGoToStep2 = valid => stepNewLongHaulActionsCreator.shouldGoToStep2(valid)

export const setToFirstStep = () => (dispatch) => {
  Promise.resolve(
    dispatch(currentStepActionsCreator.changeStep(1)) // Return to first step
  ).then(() => dispatch(toggleDraggableMarkers(true)))
}

export const prevStep = step => (dispatch, getState) => {
  Promise.resolve(
    dispatch(currentStepActionsCreator.changeStep(getState().currentStep - (step || 1)))
  ).then(() => {
    if (getState().currentStep === 1) {
      dispatch(toggleDraggableMarkers(true))
    }
  })
}

export const showErrorPopup = (iconPopup, contentTitle, buttonPopup, config = {}) => (dispatch) => {
  dispatch(loaded())
  const info = {
    icon: iconPopup,
    title: config.title || '',
    subTitle: '',
    specialClass: config.classTitle || 'Normal-Booking Reset-Width-Popup-Booking Reset-Title-Popup-Booking',
    maxHeightIcon: config.maxHeightIcon || false,
  }
  const content = {
    title: config.content || contentTitle,
    specialClass: config.classContent || 'default-font reset m center',
  }
  return DynamicPopupWithButton(
    document.getElementById('CommonPopup'),
    info,
    content,
    buttonPopup,
  )
}

export const showErrorPopupMultiple = (elemID, iconPopup, contentTitle, buttonPopup, config = {}) => (dispatch) => {
  dispatch(loaded())
  const info = {
    icon: iconPopup,
    title: config.title || '',
    subTitle: '',
    specialClass: config.classTitle || 'Normal-Booking Reset-Width-Popup-Booking Reset-Title-Popup-Booking',
    maxHeightIcon: config.maxHeightIcon || false,
  }
  const content = {
    title: config.content || contentTitle,
    specialClass: config.classContent || 'default-font reset m center',
  }
  return DynamicPopupWithButton(
    document.getElementById(elemID),
    info,
    content,
    buttonPopup,
  )
}

const handleCreateVA = (presignedData, paymentId, customerId) => {
  const xPath = `customers/${customerId}/events/payments/${paymentId}/presigned`
  PaymentIntegrationAPI.createVAAPI(presignedData)
  PaymentIntegrationAPI.removeFirebaseChannel(xPath)
}

const handleActionFirebase = (params) => {
  const {
    snapshot, bookingId, isIncreasePrice, isMajor, transactionId, customerId,
  } = params
  if (snapshot.key === 'presigned') {
    handleCreateVA(snapshot.val(), transactionId, customerId)
  }
  if (snapshot.key === 'status') {
    const status = snapshot.val()
    if (status === PAYMENT_VA_STATUS.virtualAccountCreated) {
      let path = `/bookings/${bookingId}/payment?type=${CUSTOMER_PAYMENT}`
      if (isIncreasePrice) path = `/bookings/${bookingId}/payment?type=${CUSTOMER_QUOTE_PAYMENT}&is_major=${isMajor}`
      window.location.href = path
    }
  }
}

const initFirebasePaymentEvent = (params) => {
  const {
    transactionId, customerId
  } = params
  let firebasePayment = null
  firebaseInit.load()
  firebasePayment = firebaseInit.listen(`customers/${customerId}/events/payments/${transactionId}`)

  firebasePayment.on('child_added', (snapshot) => {
    handleActionFirebase({ ...params, snapshot })
  })

  firebasePayment.on('child_changed', (snapshot) => {
    handleActionFirebase({ ...params, snapshot })
  })
}

const handlePayment = async (state, params) => {
  try {
    const {
      transactionId, amount, bookingId, bankCode,
    } = params
    const { extraInfos, booking } = state
    const isIncreasePrice = booking?.payment_changed_price_status === PRICING_PAYMENT_INCREASE
    if (transactionId) {
      const { currentCustomer } = state
      const paramsPresigned = {
        action: 'payment',
        streamId: bookingId,
        transactionId,
        content: {
          amount,
          bankCode,
          customerName: currentCustomer.name,
          paymentInfo: I18n.t('webapp.label.ftl_booking_payment', { bookingId })
        }
      }
      PaymentIntegrationAPI.preSignAPIPayment(paramsPresigned, currentCustomer.id, extraInfos.country_code)
      const paramsForFirebase = {
        transactionId,
        customerId: currentCustomer.id,
        bookingId,
        isIncreasePrice,
        isMajor: booking.is_major,
      }
      initFirebasePaymentEvent(paramsForFirebase)
    }
  } catch (err) {
    console.log('error', err)
  }
}

const handlePaymentNewBooking = async (state, response, dispatch) => {
  try {
    const transactionId = response?.payment?.id
    const amount = response?.payment?.amount || 0
    const bookingId = response?.id
    if (transactionId) {
      const { bankTransfer } = state.booking
      const params = {
        transactionId, amount, bookingId, bankCode: bankTransfer.bankCode
      }
      handlePayment(state, params)
    }
  } catch (err) {
    console.log('error', err)
  }
}

const handlePaymentEditBooking = async (state, newPayment) => {
  const transactionId = newPayment?.id
  const amount = newPayment?.amount || 0
  const bookingId = window.location.pathname.split('/')[2]
  if (transactionId) {
      const params = {
        transactionId, amount, bookingId, bankCode: newPayment.bank_code
      }
      handlePayment(state, params)
  }
}

const callbackAfterCreated = response => async (dispatch, getState) => {
  const state = getState()
  const booking = state.booking
  switch (response.status) {
    case 200:
    case 201: {
      if (state.assignedDriver) {
        dispatch(addToFavoriteDrivers(state.assignedDriver))
      }
      const objectData = response?.data?.object
      if (booking?.payment_changed_price_status === PRICING_PAYMENT_INCREASE
        && objectData?.payment?.status === PAYMENT_VA_STATUS.paid && isCustomerEditBooking()) {
        handlePaymentEditBooking(state, booking?.newPayment, dispatch)
        return
      }
      if (!isCustomerEditBooking() && !_.isEmpty(objectData?.payment)) {
        handlePaymentNewBooking(state, objectData, dispatch)
        return
      }
      const body = response.data
      if (booking.is_major === false) {
        window.localStorage.setItem('minor_booking_id', body.object.id)
        if (booking.batch_id) {
          window.location.href = '/batches'
        } else {
          window.location.href = '/bookings'
        }
      } else if (body.batch_status
          && body.batch_status !== BATCH_STATUS_COMPLETED
          && body.batch_status !== BATCH_STATUS_CANCELED) {
        const urlParams = new URLSearchParams(window.location.search)
        const batchId = urlParams.get('batch_id')
        window.location.href = `/batches?batch_id=${batchId}`
      } else {
        if (booking.is_major === true) {
          // The is_major_changes flag represents for waiting driver accept changes
          // It will be removed after driver accept, decline or timeout.
          window.localStorage.setItem('is_major_changes', body.object.id)
          // Enable this flag for showing the driver cancel modal...
          // ... it will be remove after navigating to booking detail
          window.localStorage.setItem('is_driver_not_available', body.object.id)
        }
        window.location.href = `/bookings/${body.object.id}`
      }
      break
    }
    case 404: {
      const body = response.data
      dispatch(calculate((newBooking) => {
        dispatch(bookingAgainDetailsActionsCreator.updateBookAgainSettlementDetails(newBooking?.settlement_details))
        dispatch(showErrorPopup(ICON_QUOTE_ID, body.error, {
          specialClass: 'mt10 flex-center',
          list: [
            {
              title: I18n.t('webapp.action.ok'),
              specialClass: 'Button white green-text flex-index',
              specialClick: () => ReactDOM.unmountComponentAtNode(document.getElementById('CommonPopup')),
            },
          ],
        }))
      }))
      dispatch(getCustomerCreditAmount())
      break
    }
    case 422: {
      // Error Default
      if (response.data.error) {
        switch (response.data.error.code) {
          case 'SHOPPING_001':
            dispatch(showErrorPopup(CART_SHOPPING, I18n.t('webapp.shopping.booking_in_progress'), {
              specialClass: 'flex mt10',
              list: [
                {
                  title: I18n.t('webapp.booking.back'),
                  specialClass: 'Button gray Button-Default flex-index mr5 default-medium-font',
                  specialClick: () => ReactDOM.unmountComponentAtNode(document.getElementById('CommonPopup')),
                },
                {
                  title: I18n.t('webapp.shopping.track_shopping'),
                  specialClass: 'Button white Green-text Button-Default flex-index ml5 default-medium-font',
                  specialClick: () => {
                    window.location.href = '/bookings'
                  },
                }
              ],
            }, {
              title: I18n.t('webapp.shopping.booking_in_progress'),
              classTitle: 'Normal-Booking Reset-Width-Popup-Booking Reset-Title-Popup-Booking',
              content: I18n.t('webapp.shopping.block_booking'),
              classContent: 'mt15 reset p mdefault-font White-text center',
              maxHeightIcon: true,
            }))
            break
          case 'SHOPPING_003':
            Promise.resolve(
              dispatch(loaded())
            ).then(() => {
              window.showSwitchAccountModal()
            })
            break
          default:
            dispatch(showErrorPopup(ICON_CANCEL_BOOKING, response.data.error || '', {
              specialClass: '',
              list: [],
            }))
            break
        }
      } else {
        dispatch(showErrorPopup(ICON_CANCEL_BOOKING, response.data.error.message || '', {
          specialClass: '',
          list: [],
        }))
      }
      break
    }
    case 405: {
      // Error Assign Driver
      dispatch(showErrorPopup(ICON_DRIVER_PREFERENCES, response.data.error, {
        specialClass: '',
        list: [],
      }))
      break
    }
    default:
      toastr.error(response.data.error)
      dispatch(loaded())
      break
  }
}

export const clearCOD = () => (dispatch, getState) => {
  const state = getState()
  const locations = state.locations

  const needCODLocations = _.filter(locations, ['need_cod', true])
  _.each(needCODLocations, (location) => {
    const { id } = location
    dispatch(locationsActionsCreator.updateLocation({ id, locationAttrs: { need_cod: false, cod_invoice_fees: '', cod_note: '' } }))
    dispatch(tmpLocationActionsCreator.updateTmpLocation({ id, locationAttrs: { need_cod: false, cod_invoice_fees: '', cod_note: '' } }))
  })
}

export const clearPOD = () => (dispatch, getState) => {
  const state = getState()
  const locations = state.locations

  const needPODLocations = _.filter(locations, ['need_pod', true])
  _.each(needPODLocations, (location) => {
    const { id } = location
    dispatch(locationsActionsCreator.updateLocation({
      id, locationAttrs: {
        need_pod: false, pod_invoice_fees: '', pod_note: '', pre_selected: undefined
      }
    }))
    dispatch(tmpLocationActionsCreator.updateTmpLocation({
      id, locationAttrs: {
        need_pod: false, pod_invoice_fees: '', pod_note: '', pre_selected: undefined
      }
    }))
  })
}

export const nextStep = (navigate) => (dispatch, getState) => {
  dispatch(loading())
  switch (getState().currentStep) {
    case 1: {
      const state = getState()
      const extraRequirements = state.extraServices.extraRequirements
      const dataChange = state.dataChange
      const cancelToEdit = isEditBooking() && !isCustomerEditBooking()
      if (cancelToEdit
        && (state.timeType !== state.bookAgainDetails.time_type
          || state.selectedServiceTypeID !== state.bookAgainDetails.service_type_id
          || state.selectedVehicleTypeID !== state.bookAgainDetails.vehicle_type_id
          || state.locations[0].name !== state.bookAgainDetails.locations[0].name)) {
        const bookAgainDetails = {
          auto_attachments : [],
          booking_attachments : [],
          booking_badges : [],
          booking_extra_requirements : [],
          booking_extra_requirements_negative_position : [],
          service_type_id : state.selectedServiceTypeID,
          vehicle_type_id : state.selectedVehicleTypeID,
          time_type : state.timeType,
          locations : _.cloneDeep(state.bookAgainDetails.locations),
          allow_tolls_fees : ''
        }
        bookAgainDetails.locations[0] = state.locations[0]
        dispatch(bookingAgainDetailsActionsCreator.updateBookAgainDetails(bookAgainDetails))
        dispatch(extraInfosActionsCreator.updateExtraInfos({ new_booking: true }))
        dispatch(setAutoAttachments(getState().currentCustomer.booking_attachments))
      }

      // dataChange is wrong when eidit booking flow by clearDataChangeForEditBooking in NewBookingContainer
      const hasFullDayPricing = !state.extraServices?.fullDayPricing?.amount && state.timeType === FULL_DAY
      if (_.isEmpty(extraRequirements) || dataChange || hasFullDayPricing) {
        dispatch(getExtraServices(() => {
          Promise.resolve(
            dispatch(currentStepActionsCreator.changeStep(getState().currentStep + 1)),
            dispatch(toggleDraggableMarkers(false))
          ).then(() => {
            if (currentServiceType(getState())?.is_package) {
              dispatch(calculate(() => {
                Promise.resolve(
                  dispatch(currentStepActionsCreator.changeStep(getState().currentStep + 1))
                ).then(
                  dispatch(loaded())
                )
              }))
            } else if (getState().timeType === NOW) {
              Promise.resolve(
                dispatch(othersActionsCreator.updateOthers({ attrs : { sendToFavoriteFirst: false } }))
              ).then(dispatch(loaded()))
            } else if (getState().timeType === FULL_DAY) {
              Promise.resolve(
                dispatch(extraServicesActionsCreator.updateCompanySetting({ allow_waiting_time_fees: false }))
              ).then(dispatch(loaded()))
            } else if (getState().timeType === LONG_HAUL) {
              Promise.resolve(
                dispatch(clearCOD())
              ).then(dispatch(loaded()))
            } else {
              dispatch(loaded())
            }
            dispatch(dataChangesActionsCreator.updateDataChange(false))
          })
        }))
      } else {
        Promise.resolve(
          dispatch(setUpBookingLocationForExtraRequirementPerLocation()),
          dispatch(currentStepActionsCreator.changeStep(getState().currentStep + 1)),
          dispatch(toggleDraggableMarkers(false))
        ).then(() => {
          if (getState().timeType === LONG_HAUL) {
            Promise.resolve(
              dispatch(clearCOD())
            ).then(dispatch(loaded()))
          } else {
            dispatch(loaded())
          }
        })
      }
      return
    }
    case 2: {
      if (window.isShopping && window.isShopping()) {
        Promise.resolve(
          dispatch(currentStepActionsCreator.changeStep(getState().currentStep + 1))
        ).then(
          dispatch(loaded())
        )
      } else {
        dispatch(calculate(() => {
          Promise.resolve(
            dispatch(currentStepActionsCreator.changeStep(getState().currentStep + 1))
          ).then(
            dispatch(loaded())
          )
        }))
      }
      return
    }
    case 3: {
      if (isEditBooking()) {
        dispatch(editBooking(res => callbackAfterCreated(res, navigate)))
      } else {
        dispatch(create(res => callbackAfterCreated(res, navigate)))
      }
      break
    }
    default:
      // We shouldn't be here!
      break
  }
}
