import { useEffect } from 'react'

const useOutsideAlerter = (ref, callBack, escFunction = false) => {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target) && callBack) {
        callBack()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    if (escFunction)document.addEventListener('keydown', escFunction, false)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
      if (escFunction)document.removeEventListener('keydown', escFunction, false)
    }
  }, [ref])
}

export default useOutsideAlerter
