/* eslint-disable import/prefer-default-export */
// for Map
// for DRIVER POPUP
export const TRACKING_UPDATE_DRIVER_MARKER = 'TRACKING_UPDATE_DRIVER_MARKER'
export const TRACKING_INIT_DRIVER_INFOBOX = 'TRACKING_INIT_DRIVER_INFOBOX'
export const TRACKING_UPDATE_BATCH_LOCATION_MARKER = 'TRACKING_UPDATE_BATCH_LOCATION_MARKER'
export const TRACKING_UPDATE_BATCH_DRIVER_MARKER = 'TRACKING_UPDATE_BATCH_DRIVER_MARKER'
export const TRACKING_BATCH_DRIVER_INFOBOX = 'TRACKING_BATCH_DRIVER_INFOBOX'
export const ROUTE_COLORS = [
  '#FF877F', '#7680B2', '#B700FF', '#A73592', '#AE8529',
  '#24A9A2', '#FF5CA2', '#F39016', '#899C6C', '#0268BF'
]

// duration: second
// interval: milisecond
// Impact to check ETA, No signal icon
export const TRACKING_UPDATE_DRIVER_DURATION_INTERVAL = [
  { duration: 172800, interval: 86400000 }, // >48h : updated every 24 hours
  { duration: 86400, interval: 43200000 }, // >24h : update every 12 hours.
  { duration: 43200, interval: 21600000 }, // >12h : update every 6 hours.
  { duration: 21600, interval: 7200000 }, // > 6h : update every 2 hours.
  { duration: 7200, interval: 3600000 }, // >2h : update every 1 hour.
  { duration: 3600, interval: 600000 }, // >1h : update every 10min.
  { duration: 1800, interval: 300000 }, // >30min : update every 5min.
  { duration: 600, interval: 120000 }, // >10min : update every 2min.
  { duration: 0, interval: 60000 } // > 0min : update every 1min.
]

export const SECTION_TYPE = {
  CUSTOMER_ASSIGNED_DRIVER: 'customer_assigned_driver',
  CUSTOMER_DEVINA_STAGE_INFO: 'customer_devina_stage_info',
  VEHICLE_TYPE_SETTINGS: 'customer_details_vehicle_type_settings',
  MINOR_CHANGE: 'customer_submitted_minor_changes_popup',
  CUSTOMER_DRIVER: 'customer_driver',
  CUSTOMER_BATCH_BOOKING_DEVINA: 'customer_batch_booking_devina',
}
export const UPDATE_ASSIGN_DRIVER_BOOKING = 'UPDATE_ASSIGN_DRIVER_BOOKING'
export const UPDATE_CANCELABLE = 'UPDATE_CANCELABLE'
export const UPDATE_DEVINA_STAGE = 'UPDATE_DEVINA_STAGE'
export const UPDATE_VEHICLE_TYPE = 'UPDATE_VEHICLE_TYPE'
export const UPDATE_MASK_LOADING = 'UPDATE_MASK_LOADING'
export const UPDATE_LOCATING_DURATION = 'UPDATE_LOCATING_DURATION'
export const UPDATE_CS_FINDING_TIMEOUT = 'UPDATE_CS_FINDING_TIMEOUT'
export const GET_CUSTOMER_DRIVER = 'bookings/GET_CUSTOMER_DRIVER'
export const GET_CUSTOMER_DRIVER_SUCCESS = 'bookings/GET_CUSTOMER_DRIVER_SUCCESS'
export const RESET_CUSTOMER_DRIVER = 'bookings/RESET_CUSTOMER_DRIVER'
export const CUSTOMER_DRIVER_REFERENCE_SUCCESS = 'booking/CUSTOMER_DRIVER_REFERENCE_SUCCESS'