import { lazy } from 'react'
import { RouteProps } from 'react-router-dom'
import PATHS from './paths'
import SingleBookingView from 'view/SingleBookingView'
// VIEW
// const SingleBookingView = lazy(() => import('view/SingleBookingView'))
const MultipleBookingView = lazy(() => import('view/MultipleBookingView'))
const EzSheetView = lazy(() => import('view/EzSheetView'))
const SmartPlannerView = lazy(() => import('view/SmartPlannerView'))
const SelectAreaView = lazy(() => import('view/SelectAreaView'))
const Locations = lazy(() => import('view/Locations'))
const LocationEdit = lazy(() => import('view/LocationEdit'))
const NewLocation = lazy(() => import('view/NewLocation'))
const Employees = lazy(() => import('view/Employees/EmployeeList'))
const NewEmployee = lazy(() => import('view/Employees/NewEmployee'))
const CustomerSignUp = lazy(() => import('view/CustomerSign/CustomerSignUp'))
const CustomerSignIn = lazy(() => import('view/CustomerSign/CustomerSignIn'))
const Drivers = lazy(() => import('view/Drivers/DriversList'))
const MyBookingView = lazy(() => import('view/MyBookingView'))
const PaymentNoneBP = lazy(() => import('view/PaymentNoneBp'))

interface RoutesProps {
  [key: string]: RouteProps & {
    isPrivate: boolean
  }
}

const routes: RoutesProps = {
  //Single Booking
  NEW_BOOKING: {
    path: PATHS.NEW_BOOKING,
    element: <SingleBookingView />,
    isPrivate: false,
    caseSensitive: false,
  },
  NEW_BOOKING_SINGLE: {
    path: PATHS.NEW_BOOKING_SINGLE,
    element: <SingleBookingView />,
    isPrivate: false,
    caseSensitive: false,
  },
  EDIT_BOOKING: {
    path: PATHS.EDIT_BOOKING,
    element: <SingleBookingView />,
    isPrivate: true,
    caseSensitive: false,
  },
  BOOK_AGAIN_BOOKING: {
    path: PATHS.BOOK_AGAIN_BOOKING,
    element: <SingleBookingView />,
    isPrivate: true,
    caseSensitive: false,
  },
  PAYMENT: {
    path: PATHS.PAYMENTS,
    element: <PaymentNoneBP />,
    isPrivate: true,
    caseSensitive: false,
  },
  // Multiple booking
  MULTIPLE_BOOKING: {
    path: PATHS.MULTIPLE_BOOKING,
    element: <MultipleBookingView />,
    isPrivate: true,
    caseSensitive: false,
  },
  EZ_SPREAD_BOOKING: {
    path: PATHS.EZ_SPREAD_BOOKING,
    element: <EzSheetView />,
    isPrivate: true,
    caseSensitive: false,
  },
  SMART_PLANNER_BOOKING: {
    path: PATHS.SMART_PLANNER_BOOKING,
    element: <SmartPlannerView />,
    isPrivate: true,
    caseSensitive: false,
  },
  SELECT_AREA: {
    path: PATHS.SELECT_AREA,
    element: <SelectAreaView />,
    isPrivate: false,
    caseSensitive: false,
  },

  // AUTHENTICATION
  SIGN_IN: {
    path: PATHS.SIGN_IN,
    element: <CustomerSignIn/>,
    isPrivate: false,
    caseSensitive: false,
  },
  SIGN_UP: {
    path: PATHS.SIGN_UP,
    element: <CustomerSignUp/>,
    isPrivate: false,
    caseSensitive: false,
  },
  RESET_PASSWORD: {
    path: PATHS.RESET_PASSWORD,
    element: <div>reset-password</div>,
    isPrivate: false,
    caseSensitive: false,
  },
  CHANGE_PASSWORD: {
    path: PATHS.CHANGE_PASSWORD,
    element: <div>change-password</div>,
    isPrivate: false,
    caseSensitive: false,
  },
  MERGE_ACCOUNT: {
    path: PATHS.MERGE_ACCOUNT,
    element: <div>merge-account</div>,
    isPrivate: false,
    caseSensitive: false,
  },
  // my booking
  BOOKINGS: {
    path: PATHS.BOOKINGS,
    element: <MyBookingView />,
    isPrivate: true,
    caseSensitive: false,
  },

  //MORE
  LOCATIONS: {
    path: PATHS.LOCATIONS,
    element: <Locations/>,
    isPrivate: true,
    caseSensitive: false,
  },
  ADD_LOCATIONS: {
    path: PATHS.ADD_LOCATIONS,
    element: <NewLocation/>,
    isPrivate: true,
    caseSensitive: false,
  },
  LOCATION_DETAIL: {
    path: PATHS.LOCATION_DETAIL,
    element: <LocationEdit/>,
    isPrivate: true,
    caseSensitive: false,
  },
  EMPLOYEES: {
    path: PATHS.EMPLOYEES,
    element: <Employees/>,
    isPrivate: true,
    caseSensitive: false,
  },
  NEW_EMPLOYEES: {
    path: PATHS.NEW_EMPLOYEE,
    element: <NewEmployee/>,
    isPrivate: true,
    caseSensitive: false,
  },
  DRIVERS: {
    path: PATHS.DRIVERS,
    element: <Drivers/>,
    isPrivate: true,
    caseSensitive: false,
  }
}

export default routes
