export const paymentMethodSelected = (paymentMethod, bankName) => {
  const eventParams = {
    'Payment method': paymentMethod,
    ...(bankName && { 'Bank name': bankName }),
  }
  window.Moengage.track_event('Payment method selected', eventParams)
}

export const clickVANumberCopy = () => window.Moengage.track_event('VA number copy')

export const paymentInstructionsRowExpanded = (type) => window.Moengage.track_event('Payment Instructions row expanded', { Type: type })


export const paymentInstructionsTableExpanded = () => window.Moengage.track_event('Payment Instructions table expanded')

export const paymentBookingDetailsButton = async (isPaid) => await window.Moengage.track_event('Payment - Booking Details button click', { Screen: isPaid ? 'VA paid success' : 'VA payment' })
