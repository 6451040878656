import React from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from '@reduxjs/toolkit'
import { connect } from 'react-redux'
import ReactDOM from 'react-dom'
import _, { isEqual } from 'lodash'
import moment from 'moment'
// UTILS
import commonUtils from 'utils/common'
import I18n from 'i18n/i18n'
import toastr from 'utils/toast';
// ACTIONS
import * as customerActionCreators from 'store/actions/common/customerActionCreators'
import * as bookingActionCreators from 'store/actions/common/bookingActionCreators'
import * as authenticationActionCreators from 'store/actions/common/authenticationActionCreators'
import * as extraServiceActionCreators from 'store/actions/new_booking/extraServiceActionCreators'
import * as socialLoginActionCreators from 'store/actions/social_login/socialLoginActionCreators'
import * as stepActionCreators from 'store/actions/new_booking/stepActionCreators'
// COMPONENTS
import ModalSignIn from './ModalSignIn'
import ModalSignUp from './ModalSignUp'
import ModalResetPassword from './ModalResetPassword'
import ModalWelcomeRevolution from './ModalWelcomeRevolution'
import ModalVerificationCode from './ModalVerificationCode'
import ModalEditPhoneNumber from './ModalEditPhoneNumber'
import ModalChooseAccount from './ModalChooseAccount'
import ModalConfirmNewPassword from './ModalConfirmNewPassword'
import ModalSubAccountTag from './ModalSubAccountTag'
// CONSTANTS
import {
  V2_ICON_GREEN,
  V2_ICON_GREEN_TRANSPORTIFY,
} from 'constants/imageConstants'
import {
  GO_TO_STEP1_MODAL, MERGE_MODAL, RESET_PASSWORD_MODAL, VERIFICATION_CODE_MODAL,
  REVOLUTION_MODAL, SIGN_IN_MODAL, SIGN_UP_MODAL, SWITCH_ACCOUNT_MODAL,
  EDIT_PHONE_NUMBER_MODAL, SUB_ACCOUNT_MODAL, CONFIRM_PASSWORD_MODAL,
} from './constants'
// API
// ASSETS
import DynamicPopupWithButton from '../../common/DynamicPopupWithButton'
import ModalMerge from './ModalMerge/ModalMerge'
import CustomerAPI from 'api/customers'
import { Utils } from 'utils/Utils'
import { hideLoading, showLoading } from 'assets/javascripts/webapp-v2/common'
import $ from 'jquery';
import { dataChangesActionsCreator } from 'store/toolkit/newBooking/dataChange.reducer'
import { currentPopupIDActionsCreator } from 'store/toolkit/currentPopupID/currentPopupID.reducer'
import { batchActionsCreator } from 'store/toolkit/batch/batch.reducer'
import { setAccessToken } from 'utils/crossStorage';

const mapStateToProps = state => ({
  discountCode: state.discountCode,
  currentCustomer: state.currentCustomer,
  currentStep: state.currentStep,
})

const mapDispatchToProps = (dispatch) => {
  return {
    customerActions: bindActionCreators(customerActionCreators, dispatch),
    stepActions: bindActionCreators(stepActionCreators, dispatch),
    bookingActions: bindActionCreators(bookingActionCreators, dispatch),
    authenticationActions: bindActionCreators(authenticationActionCreators, dispatch),
    extraServiceActions: bindActionCreators(extraServiceActionCreators, dispatch),
    dataChangeActions: bindActionCreators(dataChangesActionsCreator, dispatch),
    socialLoginActions: bindActionCreators(socialLoginActionCreators, dispatch),
    currentPopupIDActions: bindActionCreators(currentPopupIDActionsCreator, dispatch),
    batchActions: bindActionCreators(batchActionsCreator, dispatch),
  }
}

class Modals extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentModal: undefined,
      paramSignUp: {},
      ableBackToSignUpForm: true,
      signInLogin: '',
      prevLoginInfo: {},
      paramsMergeSocial: {},
      socialToken: '',
      socialSignUpRedirect: false,
      isLoadingSignIn: false,
    }

    this.submitFormSignin = this.submitFormSignin.bind(this)
    this.submitFormResetPassword = this.submitFormResetPassword.bind(this)
    this.submitFormSignUp = this.submitFormSignUp.bind(this)
    this.editPhoneNumberForm = this.editPhoneNumberForm.bind(this)
    this.checkAfterSignedUp = this.checkAfterSignedUp.bind(this)
    this.closeModal = this.closeModal.bind(this)
    this.goToSignIn = this.goToSignIn.bind(this)
    this.goToSignUp = this.goToSignUp.bind(this)
    this.goToMergeAccount = this.goToMergeAccount.bind(this)
    this.goToSwitchAccount = this.goToSwitchAccount.bind(this)
    this.goToSelectSubAccountTag = this.goToSelectSubAccountTag.bind(this)
    this.goToFogotPassword = this.goToFogotPassword.bind(this)
    this.goToWelcomeRevolution = this.goToWelcomeRevolution.bind(this)
    this.goToVerificationCode = this.goToVerificationCode.bind(this)
    this.goToEditPhoneNumber = this.goToEditPhoneNumber.bind(this)
    this.chooseSubAccountTagHandler = this.chooseSubAccountTagHandler.bind(this)
    this.resendSmsTokenAfterSignUp = this.resendSmsTokenAfterSignUp.bind(this)
    this.verifySmsTokenAfterSignUp = this.verifySmsTokenAfterSignUp.bind(this)
    this.updateCurrentParamSignUp = this.updateCurrentParamSignUp.bind(this)
    this.updateMergeSocial = this.updateMergeSocial.bind(this)
    this.editPhoneNumberAndResendSms = this.editPhoneNumberAndResendSms.bind(this)
    this.checkRedirectPage = this.checkRedirectPage.bind(this)
    this.handleUpdatePassword = this.handleUpdatePassword.bind(this)
    this.handleSocialLogin = this.handleSocialLogin.bind(this)
    this.reloadTopNavAfterSignIn = this.reloadTopNavAfterSignIn.bind(this)
  }

  componentDidMount() {
    const {
      customerSignUpDirect, extraInfos, customerSignInDirect, currentCustomer
    } = this.props
    window.showSigninModal = () => this.goToSignIn()
    window.showSignupModal = () => this.goToSignUp()
    window.showSwitchAccountModal = () => this.goToSwitchAccount()
    window.showSelectSubAccountTagModal = () => this.goToSelectSubAccountTag()
    this.renderSignUpForm(customerSignUpDirect)
    this.renderSignInForm(customerSignInDirect)
    if (_.get(extraInfos, 'flowSignUpSocial', false)) {
      this.setState({ paramSignUp: extraInfos.socialParams })
    }
    if (currentCustomer?.id && !currentCustomer.verify_sms_code) {
      this.goToVerificationCode()
      this.setState({ paramSignUp: currentCustomer, ableBackToSignUpForm: false })
    }
    if (extraInfos.flowSignUpSocial) {
      this.setState({ paramSignUp: extraInfos.socialParams })
    }
  }

  trackSwitchAccountEventMoengage = async (currentCustomer, id, newCurrentCustomer) => {
    const { currentStep } = this.props
    const { isMultiple, isBatchEZ, isSmartLoad } = commonUtils
    if (!isMultiple() && !isBatchEZ() && !isSmartLoad()) {
      const {
        oldId,
        newId
      } = commonUtils.getMoengageId(currentCustomer, id)
      await window.Moengage.track_event('Switch Account', {
        'From Account': oldId,
        'To Account': newId,
        'At Step': commonUtils.getAtStepAttributeMoengage(currentStep)
      })
      await window.Moengage.destroy_session()
      await commonUtils.handleTrackMoengage(newCurrentCustomer, currentStep)
    }
  }

  submitFormSignin = async (params) => {
    const { customerActions, currentPopupIDActions, currentStep, authenticationActions } = this.props
    showLoading()
    currentPopupIDActions.updateCurrentPopupID('')
    const response = await customerActions.signIn(params)
    if (response.statusApi === 200) {
      // event tracking for signin
      // EventTracking.trackingSignInAction(currentStep)
      this.closeModal()
      if (response.must_update_password) {
        this.setState({
          currentModal: CONFIRM_PASSWORD_MODAL,
          prevLoginInfo: params,
        })
      } else {
        this.switchAccountHandler(response.last_login_employ_id, true, () => {
          commonUtils.handleTrackMoengage(response)
          this.checkRedirectPage()
        })
      }
    } else {
      toastr.error(response.data.message || response.data.error)
    }
    hideLoading()
  }

  async submitFormResetPassword(params) {
    await CustomerAPI.resetPassword(params)
    this.goToSignIn()
    toastr.success(I18n.t('devise.passwords.send_paranoid_instructions'))
    hideLoading()
  }

  updateCurrentParamSignUp(newParamSignUp) {
    this.setState({ paramSignUp: newParamSignUp })
  }

  updateMergeSocial(params) {
    this.setState({ paramsMergeSocial: params })
  }

  submitFormSignUp(params) {
    const {
      customerActions,
      currentPopupIDActions,
      currentStep,
      authenticationActions,
      extraInfos
    } = this.props
    showLoading()
    currentPopupIDActions.updateCurrentPopupID('')
    customerActions.signUp(params, async (response) => {
      if (response.access_token) {
        if (response.object) {
          const {
            id, last_name: lastName, first_name: firstName,
            name, email, phone, current_company_id: companyID, country_code: countryCode
          } = response.object
          const removePlusInPhone = phone && phone.slice(1)
          await commonUtils.moengageUserAttribute(id, firstName, lastName, name, email,
            removePlusInPhone, companyID, countryCode, false)
          await window.Moengage.track_event('Customer Signup', {
            'Registration Date': moment(new Date()).format('Y-MM-DDTHH:mm:ssZ'),
            'At Step': commonUtils.getAtStepAttributeMoengage(currentStep)
          })
          if (extraInfos?.enable_sms_verification) {
            this.setState({ ableBackToSignUpForm: false })
            this.goToWelcomeRevolution()
            // event trssacking for signup
            // EventTracking.trackingSignUpAction(currentStep)
            
          } else {
            this.setState({ currentModal: undefined })
            this.reloadTopNavAfterSignIn(() => {this.checkRedirectPage()})
            this.reCalculateAfterSignup()
          }
        } else {
          toastr.error(response.message)
        }
      } else {
        const error = response.message ? response : { message: response.error }
        this.showPopupExitsAccount(error)
      }
      hideLoading()
    })
  }

  switchAccountHandler = async (id, isLoginBeforeSwitchAccount = false, callback = () => {}) => {
    const {
      customerActions,
      currentCustomer,
      bookingActions,
      currentStep
    } = this.props

    // Loading
    $('#loading-modal').addClass('visible')

    bookingActions.updateSubAccountCheckBox(false)
    bookingActions.addSubAccount()

    if (
      (isLoginBeforeSwitchAccount && currentCustomer.current_company_id)
      || id === 0
      || (currentCustomer.current_company_id !== id && id !== null)
    ) {
      const accountId = id === 0 ? currentCustomer.id : id
      customerActions.switchAccount(accountId, {}, async (newCurrentCustomer) => {
        if (!isLoginBeforeSwitchAccount) {
          await this.trackSwitchAccountEventMoengage(currentCustomer, id, newCurrentCustomer)
        } else {
          await commonUtils.handleTrackMoengage(currentCustomer, currentStep)
        }
        this.checkAfterSignedIn(false, () => {
          this.reloadTopNavAfterSignIn(null, true)
        })

        this.handleConvertToken(id, newCurrentCustomer, () => {
          bookingActions.getSubAccountTagList()
          callback()
        })
      })
    } else if (!currentCustomer.enabled_new_gen_pod) {
      await commonUtils.handleTrackMoengage(currentCustomer, currentStep)
      this.checkAfterSignedIn(false, () => {
        this.reloadTopNavAfterSignIn(null, true)
      })
      callback()
    } else {
      this.reCalculateAfterSignup()
      this.setState({
        currentModal: undefined,
        isLoadingSignIn: false
      }, async () => {
        await commonUtils.handleTrackMoengage(currentCustomer, currentStep)
        this.reloadTopNavAfterSignIn(() => {
          callback()
        }, true)
      })
    }
  }

  reCalculateAfterSignup() {
    const {
      customerActions,
      bookingActions,
    } = this.props
    if(commonUtils.isSingle()) {
      // we need calculate step 3
      bookingActions.calculate()
      customerActions.getCustomerCreditAmount()
    }
  }

  resendSmsTokenAfterSignUp(params) {
    const { customerActions } = this.props
    customerActions.resendSmsToken(params, (response) => {
      if (response.status === 204) {
        toastr.success(I18n.t('success.messages.send_sms_verify'))
      } else if (response.data && response.data.error) {
        toastr.error(response.data.error)
      } else {
        toastr.error(I18n.t('errors.normal.verify_failed'))
      }
    })
  }

  verifySmsTokenAfterSignUp(authenticationToken, params) {
    const { customerActions, currentCustomer, authenticationActions } = this.props
    const companyId = currentCustomer.current_company_id || null
    customerActions.verifySmsToken(authenticationToken, params, (response) => {
      if (response.status === 204) {
        this.reCalculateAfterSignup()
        customerActions.updateCustomer({ verify_sms_code: true })
        this.checkAfterSignedIn(true)
        authenticationActions.convertToken(
          currentCustomer.authentication_token, companyId,
          () => this.reloadTopNavAfterSignIn(() => this.checkRedirectPage())
        )
        toastr.success(I18n.t('success.messages.verify_code'))
      } else if (response.data && response.data.error) {
        toastr.error(response.data.error)
      } else {
        toastr.error(I18n.t('errors.normal.verify_failed'))
      }
    })
  }

  editPhoneNumberAndResendSms(authenticationToken) {
    const { customerActions } = this.props
    const { paramSignUp } = this.state
    const countryCode = $("[name='customer[country_code]']").val()
    const params = {
      phone: paramSignUp.phone || '',
      country_code: countryCode
    }
    customerActions.updateCurrentCustomerProfile(authenticationToken, params, (response) => {
      if (response.status === 200) {
        this.goToVerificationCode()
      } else {
        toastr.error((response.data && response.data.error) || I18n.t('errors.customer.invalid_phone_number'))
      }
    })
  }

  editPhoneNumberForm() {
    this.goToEditPhoneNumber()
  }

  closeModal() {
    const { currentModal } = this.state
    if (currentModal !== undefined) {
      this.setState({ currentModal: undefined })
    }
  }

  goToFogotPassword() {
    const { currentModal } = this.state
    if (currentModal !== RESET_PASSWORD_MODAL) {
      this.setState({ currentModal: RESET_PASSWORD_MODAL })
    }
  }

  goToMergeAccount() {
    const { currentModal } = this.state
    if (currentModal !== MERGE_MODAL) {
      this.setState({ currentModal: MERGE_MODAL })
    }
  }

  goToSignUp() {
    const { currentModal } = this.state
    if (currentModal !== SIGN_UP_MODAL) {
      this.setState({ currentModal: SIGN_UP_MODAL })
    }
  }

  goToSwitchAccount() {
    const { currentModal } = this.state
    if (currentModal !== SWITCH_ACCOUNT_MODAL) {
      this.setState({ currentModal: SWITCH_ACCOUNT_MODAL })
    }
  }

  goToSelectSubAccountTag() {
    const { currentModal } = this.state
    if (currentModal !== SUB_ACCOUNT_MODAL) {
      this.setState({ currentModal: SUB_ACCOUNT_MODAL })
    }
  }

  goToSignIn() {
    const { currentModal } = this.state
    if (currentModal !== SIGN_IN_MODAL) {
      this.setState({ currentModal: SIGN_IN_MODAL })
    }
  }

  goToWelcomeRevolution() {
    const { currentModal } = this.state
    if (currentModal !== REVOLUTION_MODAL) {
      this.setState({ currentModal: REVOLUTION_MODAL })
    }
  }

  goToVerificationCode() {
    const { currentModal } = this.state
    if (currentModal !== VERIFICATION_CODE_MODAL) {
      this.setState({ currentModal: VERIFICATION_CODE_MODAL })
    }
  }

  goToEditPhoneNumber() {
    const { currentModal } = this.state
    if (currentModal !== EDIT_PHONE_NUMBER_MODAL) {
      this.setState({ currentModal: EDIT_PHONE_NUMBER_MODAL })
    }
  }

  goToStep1() {
    const {
      stepActions,
      extraServiceActions,
      dataChangeActions,
      customerActions,
    } = this.props
    extraServiceActions.clearVehicleTypeSettings()
    stepActions.setToFirstStep()
    dataChangeActions.updateDataChange(true)
    customerActions.handleSetIsLoginStep3(false)
    this.closeModal()
  }


  chooseSubAccountTagHandler(data) {
    const { bookingActions } = this.props
    bookingActions.addSubAccount(data)
  }

  handleConvertToken(companyId, newCurrentCustomer, callback = () => {}) {
    const {
      authenticationActions,
    } = this.props

    if (!_.isEmpty(newCurrentCustomer.authentication_token)) {
      authenticationActions.convertToken(newCurrentCustomer.authentication_token, companyId, () => {
        callback()
      })
    } else {
      callback()
    }
  }

  checkAfterSignedIn(isAfterSignedUp = false, callback = () => {}) {
    const { currentStep, currentCustomer } = this.props
    const companyId = currentCustomer.current_company_id || currentCustomer.last_login_employ_id
    const isBp = !!companyId
    const nextModal = (!isAfterSignedUp || isBp) && currentStep === 3 ? GO_TO_STEP1_MODAL : undefined

    this.setState({ currentModal: nextModal }, () => {
      callback()
    })
    hideLoading()
  }

  checkRedirectPage() {
    const { customerSignUpDirect, customerSignInDirect } = this.props
    if (customerSignUpDirect || customerSignInDirect) {
      window.location = '/'
    }
  }

  checkAfterSignedUp(params) {
    this.setState({ paramSignUp: params })
    hideLoading()
  }

  handleUpdatePassword(params) {
    const { prevLoginInfo } = this.state
    const { customerActions, currentCustomer } = this.props
    const { authentication_token: authenticationToken } = currentCustomer
    const newParams = {
      ...params,
      current_password: prevLoginInfo.password,
    }
    customerActions.updateCurrentCustomerProfile(
      authenticationToken, newParams, () => {
        const newInfo = {
          ...prevLoginInfo,
          password: params.password,
        }
        this.setState({ currentModal: undefined })
        this.submitFormSignin(newInfo)
      }
    )
  }

  async reloadTopNavAfterSignIn(callback, checkIfSignInFinished = false) {
    const { bookingActions } = this.props
    const { currentModal } = this.state
    if (currentModal !== GO_TO_STEP1_MODAL) {
      this.closeModal()
    }

    // sync token to session for FTL
    const res = await CustomerAPI.updateAuthenticationToken()
    if (res?.status === 200) {
      if (callback) {
        callback()
      }
      // }
      if (checkIfSignInFinished) {
        bookingActions.signInProgressFinished(true)
      }
      $('#loading-modal').removeClass('visible')
    }
  }

  showPopupExitsAccount(param) {
    const { customerSignUpDirect } = this.props
    const renderNode = document.getElementById('PopupExistAccount')
    const icon = commonUtils.isTransportify() ? V2_ICON_GREEN_TRANSPORTIFY : V2_ICON_GREEN
    const info = {
      icon,
      specialClass: 'w320',
    }
    const content = {
      title: param.message,
      specialClass: 'center',
    }
    const buttons = { specialClass: 'mt10 flex' }
    buttons.list = [
      {
        title: I18n.t('webapp.action.no_confirm'),
        specialClass: 'Button Button-Back flex-index mr5 default-medium-font',
        specialClick: () => ReactDOM.unmountComponentAtNode(renderNode),
      },
      {
        title: I18n.t('webapp.action.login'),
        specialClass: 'Button white green-text flex-index ml5 default-medium-font',
        specialClick: () => {
          if (customerSignUpDirect) {
            this.goToSignIn()
          } else {
            this.setState({ signInLogin: param.account })
            this.goToSignIn()
          }
          ReactDOM.unmountComponentAtNode(renderNode)
        },
      }
    ]
    return DynamicPopupWithButton(
      renderNode,
      info,
      content,
      buttons
    )
  }

  handleSocialLogin(socialInfo, err) {
    const {
      socialLoginActions, customerSignUpDirect, customerSignInDirect, authenticationActions, customerActions
    } = this.props
    const { paramSignUp } = this.state
    if (err) {
      if (err.error === 'popup_closed_by_user') {
        const error = { message: I18n.t('webapp.action.login_social_incognito') }
        toastr.options = {
          positionClass: `toast-top-center ${(customerSignUpDirect || customerSignInDirect) ? 'toast-social-login' : 'step3-social-login'}`,
        }
        Utils.showToastrMessage('info', error.message)
      }
      return null
    }
    if (socialInfo) {
      const profile = _.get(socialInfo, '_profile', {})
      const provider = _.get(socialInfo, '_provider', '')
      const accessToken = provider === 'facebook' ? _.get(socialInfo, '_token.accessToken', '') : _.get(socialInfo, '_token.idToken', '')
      const params = {
        email: profile.email,
        provider,
        access_token: accessToken,
      }
      showLoading()
      socialLoginActions.socialLoginAPI(params, async (res) => {
        if (!res.success) {
          if (res.error_code === 403) {
            const error = { message: I18n.t('errors.messages.suspended') }
            toastr.options = {
              positionClass: `toast-top-center ${(customerSignUpDirect || customerSignInDirect) ? 'toast-social-login' : 'step3-social-login'}`,
            }
            Utils.showToastrMessage('error', error.message)
            return null
          }
          if (_.isNull(res.object)) {
            const newParams = {
              ...paramSignUp,
              email: profile.email,
              firstName: profile.firstName,
              lastName: profile.lastName,
              accessToken,
              provider
            }
            this.setState({ paramSignUp: newParams, currentModal: SIGN_UP_MODAL, socialSignUpRedirect: true })
          } else {
            this.setState({
              paramsMergeSocial:
              {
                countryCode: res.object.country_code,
                email: res.object.email,
                phone: res.object.phone,
                firstName: res.object.first_name,
                lastName: res.object.last_name,
                businessName: res.object.business_name,
                referredBy: res.object.referred_by,
                access_token: accessToken,
                provider
              },
              socialToken: accessToken,
              currentModal: MERGE_MODAL
            })
          }
          hideLoading()
        } else {
          const {
            extraInfos
          } = this.props
          const { area_id: areaID } = extraInfos
          const response = await CustomerAPI.getCustomer(res.access_token, { area_id: areaID })
          if (response?.statusApi === 200) {
            customerActions.updateCustomer({
              ...response,
              current_company_id: response.last_login_employ_id
            })
            if (res.access_token) {
              setAccessToken(res.access_token)
            }
            const companyId = (!_.isEmpty(response) && response.current_company_id) || null
            authenticationActions.convertToken(
              res.access_token, companyId,
              () => this.reloadTopNavAfterSignIn(() => {
                this.switchAccountHandler(response.last_login_employ_id, true, () => {
                  // commonUtils.handleTrackMoengage(response)
                  this.checkRedirectPage()
                  hideLoading()
                })
              })
            )
          }
        }
      })
    }
    return true
  }

  renderSignUpForm(param) {
    if (param) {
      this.goToSignUp()
    }
  }

  renderSignInForm(param) {
    if (param) {
      this.goToSignIn()
    }
  }

  renderModalGoToStep1() {
    return (
      <div className="visible vertical-scroll Modal escape_popup_no_action">
        <div className="SelectAccountBox vertical-scroll ChangeAccountBox">
          <div className="Box">
            <p>
              {I18n.t('errors.customer.different_offers_and_pricing')}
            </p>
            <a
              className="green Button"
              onClick={() => this.goToStep1()}
            >
              {I18n.t('webapp.new_booking.ok')}
            </a>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const {
      currentModal,
      socialSignUpRedirect,
      paramsMergeSocial,
      paramSignUp,
      ableBackToSignUpForm,
      signInLogin,
      socialToken,
      isLoadingSignIn
    } = this.state
    const {
      authorizeLogin,
      currentCustomer,
      customerSignUpDirect,
      termsAndConditionsLink,
      privacyPolicyLink,
      extraInfos,
      customerSignInDirect,
      customerActions,
      currentStep
    } = this.props
    const isBatchEZ = ['/batches/ez_spread_sheet'].includes(window.location.pathname)
    const isMultiple = ['/bookings/multiple'].includes(window.location.pathname)
    const checkMultiple = (currentStep < 3 && isMultiple)
    const checkSingle = (currentStep > 1 && !isMultiple)
    return (
      <div>
        {currentModal === SIGN_IN_MODAL && (
          <ModalSignIn
            authorizeLogin={authorizeLogin}
            countryCode={extraInfos.country_code?.toLowerCase() || currentCustomer.country_code?.toLowerCase()}
            closeModal={this.closeModal}
            goToFogotPassword={this.goToFogotPassword}
            submitFormSignin={this.submitFormSignin}
            goToMergeAccount={this.goToMergeAccount}
            goToSignUp={this.goToSignUp}
            signInLogin={signInLogin}
            handleSocialLogin={this.handleSocialLogin}
            customerSignInDirect={customerSignInDirect}
            isLoadingSignIn={isLoadingSignIn}
          />
        )}
        {currentModal === MERGE_MODAL && (
          <ModalMerge
            paramMerge={paramsMergeSocial}
            socialToken={socialToken}
            checkRedirectPage={this.checkRedirectPage}
            reloadTopNavAfterSignIn={this.reloadTopNavAfterSignIn}
          />
        )}
        {currentModal === SIGN_UP_MODAL && (
          <ModalSignUp
            customerSignUpDirect={customerSignUpDirect}
            paramSignUp={paramSignUp}
            authorizeLogin={authorizeLogin}
            countryCode={extraInfos.country_code?.toLowerCase() || currentCustomer.country_code?.toLowerCase()}
            termsAndConditionsLink={termsAndConditionsLink}
            privacyPolicyLink={privacyPolicyLink}
            goToMergeAccount={this.goToMergeAccount}
            closeModal={this.closeModal}
            goToSignIn={this.goToSignIn}
            goToSignUp={this.goToSignUp}
            submitFormSignUp={this.submitFormSignUp}
            checkAfterSignedUp={this.checkAfterSignedUp}
            updateCurrentParamSignUp={this.updateCurrentParamSignUp}
            extraInfos={extraInfos}
            handleSocialLogin={this.handleSocialLogin}
            socialSignUpRedirect={socialSignUpRedirect}
          />
        )}
        {currentModal === RESET_PASSWORD_MODAL && (
          <ModalResetPassword
            closeModal={this.closeModal}
            goToSignIn={this.goToSignIn}
            submitFormResetPassword={this.submitFormResetPassword}
            customerSignInDirect={customerSignInDirect}
          />
        )}
        {currentModal === REVOLUTION_MODAL && (
          <ModalWelcomeRevolution
            goToVerificationCode={this.goToVerificationCode}
            editPhoneNumberForm={this.editPhoneNumberForm}
            currentCustomer={currentCustomer}
          />
        )}
        {currentModal === VERIFICATION_CODE_MODAL && (
          <ModalVerificationCode
            currentCustomer={currentCustomer}
            resendSmsTokenAfterSignUp={this.resendSmsTokenAfterSignUp}
            verifySmsTokenAfterSignUp={this.verifySmsTokenAfterSignUp}
            editPhoneNumberForm={this.editPhoneNumberForm}
          />
        )}
        {currentModal === EDIT_PHONE_NUMBER_MODAL && (
          <ModalEditPhoneNumber
            countryCode={extraInfos.country_code?.toLowerCase() || currentCustomer.country_code?.toLowerCase()}
            ableBackToSignUpForm={ableBackToSignUpForm}
            paramSignUp={paramSignUp}
            currentCustomer={currentCustomer}
            submitFormSignUp={this.submitFormSignUp}
            updateCurrentParamSignUp={this.updateCurrentParamSignUp}
            editPhoneNumberAndResendSms={this.editPhoneNumberAndResendSms}
          />
        )}
        {currentModal === SWITCH_ACCOUNT_MODAL && (
          <ModalChooseAccount
            currentCustomer={currentCustomer}
            switchAccountHandler={this.switchAccountHandler}
            closeModal={this.closeModal}
          />
        )}
        {
          currentModal === CONFIRM_PASSWORD_MODAL && (
            <ModalConfirmNewPassword
              handleUpdatePassword={this.handleUpdatePassword}
            />
          )}
        {currentModal === SUB_ACCOUNT_MODAL && (
          <ModalSubAccountTag
            currentCustomer={currentCustomer}
            chooseSubAccountTagHandler={this.chooseSubAccountTagHandler}
            closeModal={this.closeModal}
          />
        )}
        {(checkSingle || checkMultiple) && (currentModal === GO_TO_STEP1_MODAL)
          && !isBatchEZ && this.renderModalGoToStep1()}
      </div>
    )
  }
}

Modals.defaultProps = {
  customerSignUpDirect: false,
  customerSignInDirect: false,
  // extraInfos: false,
  currentStep: 0,
}

Modals.propTypes = {
  authorizeLogin: PropTypes.shape({}).isRequired,
  currentCustomer: PropTypes.shape({}).isRequired,
  termsAndConditionsLink: PropTypes.string.isRequired,
  privacyPolicyLink: PropTypes.string.isRequired,
  bookingActions: PropTypes.shape({}).isRequired,
  authenticationActions: PropTypes.shape({}),
  extraInfos: PropTypes.shape({}).isRequired,
  stepActions: PropTypes.PropTypes.shape({}).isRequired,
  extraServiceActions: PropTypes.PropTypes.shape({}).isRequired,
  dataChangeActions: PropTypes.PropTypes.shape({}).isRequired,
  socialLoginActions: PropTypes.shape({}).isRequired,
  customerActions: PropTypes.shape({}).isRequired,
  customerSignUpDirect: PropTypes.bool,
  customerSignInDirect: PropTypes.bool,
  currentStep: PropTypes.number,
  currentPopupIDActions: PropTypes.shape({}).isRequired,
}

export default connect(mapStateToProps, mapDispatchToProps)(Modals)
