import StorageKeys from 'constants/storage-keys';
import areaApi from 'api/Area'

export const syncLanguageToSession = async () => {
  const urlParams = new URLSearchParams(window.location.search)
  const langFromUrl = urlParams.get('lang') && urlParams.get('lang').toLowerCase()
  const lang = langFromUrl && langFromUrl.toLowerCase()
  if(lang) {
    setTimeout(() => {
      areaApi.setNewCurrentLangCodeSession(lang)
    }, 1000);
    
  } else {
    const result = await areaApi.getCurrentLangCodeSession()
    const newLang = result.data?.lang
    const localLang = localStorage.getItem(StorageKeys.LANGUAGE)

    if(newLang && localLang !== newLang) {
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set('lang', newLang);
      window.location.search = urlParams;
    }    
  }
}

export default syncLanguageToSession